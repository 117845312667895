import { useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { Loading } from './Loading';
import { NoNewEvents } from './NoNewEvents';
import { ButtonGoBack } from '@/components/ButtonGoBack';
import { HeadingOne } from '@/components/Heading';
import { QuickDetailsEvent } from '@/components/QuickDetailsEvent';
import { Pagination } from '@/components/Pagination';

import { useGetOpenedEvents } from '@/hooks/queries/get-opened-events';

export function OpenedEvents() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { isLoading, data } = useGetOpenedEvents({
    currentPage,
    setCurrentPage,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (data.events.length <= 0) {
    return <NoNewEvents />;
  }

  return (
    <Box
      my={{ base: '6', lg: '6' }}
      px={{ base: '4', md: '8', lg: '10' }}
      h="100%"
    >
      <Box mt={{ base: '2rem', lg: '0' }} mb="3rem">
        <ButtonGoBack />

        <HeadingOne text="Eventos em aberto" mb="2rem" />

        <Flex flexDirection="column" gap={{ base: '1.2rem', lg: '0.5rem' }}>
          {data.events.map((event) => (
            <QuickDetailsEvent data={event} isOpenedEvent key={event.id} />
          ))}
        </Flex>

        {data.pagination.total > 10 && (
          <Flex
            mt="0.75rem"
            px="1.375rem"
            bg="white"
            rounded="0.5rem"
            py="1.25rem"
            justify="space-between"
          >
            <Text display="flex" alignItems="center">
              Visualizando {data.pagination.from}-{data.pagination.to} de{' '}
              {data.pagination.total} eventos.
            </Text>

            <Pagination
              totalCountOfRegisters={data.pagination.total}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              registerPerPage={10}
            />
          </Flex>
        )}
      </Box>
    </Box>
  );
}
