import { useUpdateAboutEvent } from '@/hooks/mutations/update-about-event';
import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';
import { useUpdateEventStore } from '@/store/update-event';
import { Button } from '@chakra-ui/react';

import { isDisabledSubmitAboutEvent } from './validation-helpers';

export function SubmitButton() {
  const { queryData } = useGetEventDetailToUpdate();

  const store = useUpdateEventStore((state) => state);

  const { mutateAsync } = useUpdateAboutEvent();

  async function handleUpdateAboutEvent() {
    await mutateAsync();
  }

  const isDisabled = isDisabledSubmitAboutEvent(queryData.event, store);

  return (
    <Button
      type="submit"
      mt={{ base: '3rem', lg: '0' }}
      w={{ base: '100%', lg: '6.8125rem' }}
      bg="blue.500"
      color="white"
      fontWeight="400"
      isDisabled={isDisabled}
      _hover={{ filter: 'brightness(0.9)' }}
      onClick={handleUpdateAboutEvent}
    >
      Salvar
    </Button>
  );
}
