import { SelectableButton } from '@/components/SelectableButton';
import { useReopenEventStore } from '@/store/reopen-event';
import { Flex, Select, useBreakpointValue } from '@chakra-ui/react';
import { SelectHistoryStatus } from './types';

export function SelectHistoryStatus({ stories }: SelectHistoryStatus) {
  const isMobile = useBreakpointValue(
    {
      base: true,
      sm: false,
    },
    {
      ssr: false,
    },
  );

  const { selectedStatus, onSelectHistoryStatus, onChangeSelectHistoryStatus } =
    useReopenEventStore((state) => state);

  function onFilterUnresolvedStatus() {
    const filtered = stories.filter((state) => {
      return state.name !== 'Resolvido';
    });

    return filtered;
  }

  const filterUnresolvedStatus = onFilterUnresolvedStatus();

  if (isMobile) {
    return (
      <Flex w="100%" gap="1.125rem">
        <Select
          placeholder="Selecione  o novo status"
          onChange={onSelectHistoryStatus}
          defaultValue={selectedStatus.name}
        >
          {filterUnresolvedStatus.map((status) => {
            return (
              <option key={status.id} value={JSON.stringify(status)}>
                {status.name}
              </option>
            );
          })}
        </Select>
      </Flex>
    );
  }

  return (
    <Flex justify="space-between">
      {filterUnresolvedStatus.map((status) => {
        return (
          <SelectableButton
            key={status.id}
            name={status.name}
            comparisonData={status.id}
            selectedData={selectedStatus.id}
            onSelect={() => onChangeSelectHistoryStatus(status)}
          />
        );
      })}
    </Flex>
  );
}
