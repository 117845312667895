import { useDetailEventStore } from '@/store/detail-event';
import { Flex, Text } from '@chakra-ui/react';
import { AreaDevice } from './AreaDevice';

interface Items {
  id: string;
  description: string;
  name: string;
}

export function LocationAndEquipment() {
  const event = useDetailEventStore((state) => state.event);

  const renderAreaDevice = (name: string, items: Items[]) => {
    return items.length > 0 && <AreaDevice name={name} items={items} />;
  };

  return (
    <Flex
      w="100%"
      bg="white"
      flexDirection="column"
      borderRadius=".75rem"
      p={{ base: '1.5rem 1.625rem', lg: '1.375rem 2rem' }}
    >
      <Text
        as="span"
        color="gray.900"
        mb="1rem"
        fontWeight={{ base: '700', lg: '500' }}
      >
        Locais e Equipamentos
      </Text>

      {renderAreaDevice('Estado:', event.states)}
      {renderAreaDevice('Cidade:', event.cities)}
      {renderAreaDevice('Bairro:', event.neighborhoods)}
      {renderAreaDevice('ID Device CNT:', event.device_cnt)}
    </Flex>
  );
}
