import { usePublicEventStore } from '@store/public-event';
import {
  Box,
  Flex,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from '@chakra-ui/react';
import Carousel from 'react-multi-carousel';
import { CustomArrow } from '../CustomArrow';
import { responsive } from './responsive';

interface FullScreenModalProps {
  images: {
    url: string;
  }[];
}

export function FullScreenModal({ images }: FullScreenModalProps) {
  const { onToggleModal, modal, highlightImageIndex } = usePublicEventStore();
  const isCurrentRangeBreakpoint = useBreakpointValue({
    base: 300,
    sm: 400,
    lg: 500,
  });

  return (
    <Modal
      size="full"
      isOpen={modal.isOpen}
      onClose={() => onToggleModal(false)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent background="rgba(0, 0, 0, 0.5);">
        <ModalHeader>
          <ModalCloseButton
            bg="gray.100"
            rounded="full"
            color="gray.900"
            top={{ base: '2rem', sm: '5rem' }}
            right={{ base: '2rem', md: '5rem' }}
          />
        </ModalHeader>

        <Box
          w={{ base: '18.75rem', sm: '28.125rem', md: '31.25rem' }}
          margin="auto"
        >
          <Carousel
            customLeftArrow={
              <CustomArrow
                position="fixed"
                direction="left"
                left={{ base: '1rem', md: '5rem' }}
                minWidth={{ base: '1.625rem', md: '3.5rem' }}
                height={{ base: '1.625rem', md: '3.5rem' }}
              />
            }
            customRightArrow={
              <CustomArrow
                position="fixed"
                direction="right"
                right={{ base: '1rem', md: '5rem' }}
                minWidth={{ base: '1.625rem', md: '3.5rem' }}
                height={{ base: '1.625rem', md: '3.5rem' }}
              />
            }
            arrows
            infinite
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            responsive={responsive}
            rewindWithAnimation={false}
            slidesToSlide={1}
            swipeable
            additionalTransfrom={
              -`${highlightImageIndex}` * isCurrentRangeBreakpoint
            }
          >
            {images.map((image) => {
              return (
                <Flex
                  key={image.url}
                  h="31.25rem"
                  w={{ base: '18.75rem', sm: '25rem', lg: '31.25rem' }}
                  display="flex"
                  background="transparent"
                  justify="center"
                >
                  <Image src={image.url} alt="test" objectFit="contain" />
                </Flex>
              );
            })}
          </Carousel>
        </Box>
      </ModalContent>
    </Modal>
  );
}
