import { DesktopLayout } from './DesktopLayout';
import { MobileLayout } from './MobileLayout';

export function EventDetailsLayout() {
  return (
    <>
      <DesktopLayout />
      <MobileLayout />
    </>
  );
}
