import {
  Flex,
  Step,
  StepIndicator,
  StepSeparator,
  StepStatus,
  Stepper,
  Circle,
  Heading,
} from '@chakra-ui/react';

import { useDetailEventStore } from '@/store/detail-event';

import { StatusTag } from './StatusTag';

import { HistoryDetails } from './HistoryDetails';
import { DateTimeDisplay } from './DateTimeDisplay';

export function HistoricEvent() {
  const historyEvents = useDetailEventStore(
    (state) => state.event.history_events,
  );
  const shouldShowScrollbar = historyEvents.length >= 3;

  return (
    <Flex
      p={{ base: '1.5rem 1.625rem', lg: '3rem 2.5rem' }}
      bg="white"
      w="100%"
      borderRadius=".75rem"
      flexDirection="column"
      maxHeight="48.75rem"
    >
      <Heading
        fontSize={{ base: '1rem', lg: '1.5rem' }}
        fontWeight={{ base: '700', md: '500' }}
        lineHeight={{ base: 'initial', lg: '1.25rem' }}
        color="gray.900"
        mb={{ base: '2rem', md: '0' }}
      >
        Histórico de Evento
      </Heading>

      <Stepper
        index={historyEvents.length + 1}
        orientation="vertical"
        paddingLeft={{ md: '2rem', lg: '1.25rem', xl: '6rem' }}
        paddingRight={{ md: '2rem', lg: '1.25rem', xl: '3.125rem' }}
        paddingTop={{ md: '3rem', lg: '4rem' }}
        gap="0"
        size="xs"
        colorScheme="whiteAlpha"
        overflow={shouldShowScrollbar ? 'auto' : 'hidden'}
        css={{
          '&::-webkit-scrollbar': {
            width: '.25rem',
          },
          '&::-webkit-scrollbar-track': {
            width: '.375rem',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#F5f6f6',
            borderRadius: '1.5rem',
          },
        }}
      >
        {historyEvents.map((historyEvent, index) => {
          return (
            <Flex gap="1.5rem" key={index} w="100%">
              <Flex display={{ base: 'none', md: 'flex' }} alignItems="start">
                <DateTimeDisplay dateTime={historyEvent.created_time} />
              </Flex>
              <Step>
                <StepIndicator>
                  <StepStatus
                    complete={
                      <Circle bg={historyEvent.status.background} size="100%" />
                    }
                    incomplete={
                      <Circle bg={historyEvent.status.background} size="100%" />
                    }
                    active={
                      <Circle bg={historyEvent.status.background} size="100%" />
                    }
                  />
                </StepIndicator>

                <Flex
                  flexDir="column"
                  ml={{
                    sm: '.1875rem',
                    md: '.6875rem',
                    '2xl': '1rem',
                  }}
                  _notLast={{ marginBottom: '4.0625rem' }}
                >
                  <StatusTag historyEvent={historyEvent} />

                  <Flex display={{ base: 'flex', md: 'none' }}>
                    <DateTimeDisplay dateTime={historyEvent.created_time} />
                  </Flex>

                  <HistoryDetails history={historyEvent} />
                </Flex>

                <StepSeparator style={{ background: '#e5e7ea' }} />
              </Step>
            </Flex>
          );
        })}
      </Stepper>
    </Flex>
  );
}
