import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';

interface CircularProgressSlaProps {
  slaData: {
    sla: {
      hours: number;
      minutes: number;
    };
  };
  isMobile?: boolean;
}

const OneHourInMin = 60;
const slaInHours = 4;

export function CircularProgressSla({
  slaData,
  isMobile = false,
}: CircularProgressSlaProps) {
  const slaHours = slaData.sla.hours;
  const slaMinutes = slaData.sla.minutes;
  const currentSlaInMinutes = slaHours * OneHourInMin + slaMinutes;
  const totalSlaInMinutes = slaInHours * OneHourInMin;

  if (isMobile) {
    return (
      <CircularProgress
        display={{ base: 'flex', lg: 'none' }}
        position="absolute"
        right="0"
        value={currentSlaInMinutes}
        color={currentSlaInMinutes > totalSlaInMinutes ? '#EB5757' : 'blue.500'}
        max={totalSlaInMinutes}
        thickness=".25rem"
        size="2.5rem"
      >
        <CircularProgressLabel fontSize="10px">
          {slaData.sla.hours < 1
            ? `${slaData.sla.minutes} min`
            : `${
                slaData.sla.hours > 1
                  ? `${slaData.sla.hours}hrs`
                  : `${slaData.sla.hours}hr`
              } `}
        </CircularProgressLabel>
      </CircularProgress>
    );
  }

  return (
    <CircularProgress
      value={currentSlaInMinutes}
      color={currentSlaInMinutes > totalSlaInMinutes ? '#EB5757' : 'blue.500'}
      max={totalSlaInMinutes}
      thickness=".3rem"
      size="4rem"
    >
      <CircularProgressLabel fontSize=".875rem">
        {slaData.sla.hours < 1
          ? `${slaData.sla.minutes}m`
          : `${slaData.sla.hours}h ${slaData.sla.minutes}m `}
      </CircularProgressLabel>
    </CircularProgress>
  );
}
