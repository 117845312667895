/* eslint-disable prefer-const */
import { createContext, useEffect, useState } from 'react';
import { parseCookies } from 'nookies';

import { AuthContextData, AuthProviderProps, User } from './types';
import { apiSSO } from '@/lib/axios/apiSSO';
import { signOut } from '@/utils/signOut';
import { getCookie } from '@/utils/get-cookie';

export const AuthContext = createContext({} as AuthContextData);

function getUser() {
  const userData = getCookie('@event-monitor-web:user-1.0.1');
  return userData ? JSON.parse(userData) : null;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState(getUser);

  useEffect(() => {
    let { '@event-monitor-web:token-1.0.1': token } = parseCookies();

    if (token) {
      apiSSO
        .get('/user/@me')
        .then((response) => {
          setUser(response.data);
        })
        .catch(() => {
          signOut();
        });
    }
  }, []);

  function saveUser(data: User) {
    setUser(data);
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        saveUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
