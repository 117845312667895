import { Box, Flex, TabPanel, Text } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';

import { HeadingOne, HeadingTwo } from '@/components/Heading';
import { useFormCreateEvent } from '@/hooks/useFormCreateEvent';

import { DescriptionArea } from './DescriptionArea';
import { GroupStatus } from './GroupStatus';
import { Button } from '@/components/Button';
import { useCreateEventStore } from '@store/create-event';

export function Stories() {
  const onGoNextStep = useCreateEventStore((state) => state.onGoNextStep);
  const { newEventForm, isDisabledToGoLocationEquipment } =
    useFormCreateEvent();

  return (
    <TabPanel p="0" pb="2rem">
      <FormProvider {...newEventForm}>
        <Flex h="100%" flexDir={{ base: 'column-reverse', lg: 'column' }}>
          <Flex justify="space-between" w="100%" mb="2.5rem">
            <HeadingOne
              display={{ base: 'none', lg: 'flex' }}
              text="Criar novo evento"
            />

            <Button
              isDisabled={isDisabledToGoLocationEquipment}
              onClick={() => onGoNextStep(2)}
            >
              Próximo
            </Button>
          </Flex>

          <Box>
            <HeadingTwo text="História sobre o evento" isRequired />
            <Text mt="0.375rem" color="#959595" fontSize="1rem">
              Crie uma nova história sobre o que está acontecendo .
            </Text>

            <Box
              border="1px solid #E5E7EA"
              rounded="0.5rem"
              py="2rem"
              px="1.625rem"
              mt="1.062rem"
            >
              <GroupStatus />

              <DescriptionArea />
            </Box>
          </Box>
        </Flex>
      </FormProvider>
    </TabPanel>
  );
}
