import { HistoryState } from '../@types/history';

export const initialHistoryState: HistoryState = {
  updateDescription: '',
  storyDescription: '',
  selectedStatus: {
    id: '',
    name: '',
  },
};
