import {
  Box,
  Button,
  Flex,
  FormControl,
  ModalBody,
  ModalFooter,
  Text,
  Select,
  Modal as ChakraModal,
} from '@chakra-ui/react';
import { ChangeEvent, useState } from 'react';

import { FlexWrapTag } from '@/components/Modal/ModalLocationEquipment/FlexWrapTag';
import { TagButton } from '@/components/Modal/ModalLocationEquipment/TagButton';
import { useUpdateEventStore } from '@/store/update-event';
import { CreateOrEditModalButton } from '@/components/Modal/ModalLocationEquipment/CreateOrEditModalButton';
import { ModalContent } from '@/components/Modal/ModalLocationEquipment/ModalContent';
import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';
import {
  useSelectMultiple,
  useAddEventDetails,
  useDeleteEventDetails,
} from '@/hooks/queries/get-event-detail-to-update/query-updater';

interface SelectedCity {
  id: string;
  name: string;
  event_id: string;
  isSelected: boolean;
}

interface StateCity {
  city: string;
  id: string;
  name: string;
}

export function Modal() {
  const [stateCities, setStateCities] = useState<StateCity[]>([]);
  const [selectedCity, setSelectedCity] = useState({} as SelectedCity);

  const { queryData } = useGetEventDetailToUpdate();

  const hasCitySelected = queryData.event.cities.some((item) => {
    return item.isSelected === true;
  });

  const { onToggleModal, modals } = useUpdateEventStore((state) => state);

  const { onSelectMultipleCities } = useSelectMultiple();
  const { onAddNewCity } = useAddEventDetails();
  const { onDeleteCity } = useDeleteEventDetails();

  function handleSelectStore(event: ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === '') {
      setStateCities([]);
      return;
    }

    setStateCities(JSON.parse(event.target.value));
    setSelectedCity({} as null);
  }

  function handleGetCity(event: ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === '') {
      setSelectedCity({} as null);
      return;
    }

    setSelectedCity(JSON.parse(event.target.value));
  }

  const isExistsStateCity = stateCities.length > 0;
  const isDisabledAddCity = isExistsStateCity && !!selectedCity.id;

  return (
    <>
      <CreateOrEditModalButton
        data={queryData.event.cities}
        onOpenModalClick={() =>
          onToggleModal({
            modalName: 'city',
            isOpen: true,
          })
        }
        type="update"
      />

      <ChakraModal
        isOpen={modals.city.isOpen}
        onClose={() =>
          onToggleModal({
            modalName: 'city',
            isOpen: false,
          })
        }
        size="2xl"
        isCentered
      >
        <ModalContent name="Cidade">
          <ModalBody>
            <Flex flexDirection="column">
              <FormControl display="flex" alignItems="center">
                <Flex w="100%" gap="1.125rem">
                  <Select
                    placeholder="Estado"
                    onChange={handleSelectStore}
                    w="9rem"
                  >
                    {queryData.parameters.states.map((state) => {
                      return (
                        <option
                          key={state.id}
                          value={JSON.stringify(state.city)}
                        >
                          {state.name}
                        </option>
                      );
                    })}
                  </Select>

                  {stateCities.length <= 0 ? (
                    <Select
                      placeholder="Cidade"
                      w="13rem"
                      disabled={!isExistsStateCity}
                    />
                  ) : (
                    <Select
                      placeholder="Cidade"
                      onChange={handleGetCity}
                      w="13rem"
                      disabled={!isExistsStateCity}
                    >
                      {stateCities?.map((city) => {
                        return (
                          <option key={city.id} value={JSON.stringify(city)}>
                            {city.name}
                          </option>
                        );
                      })}
                    </Select>
                  )}

                  <Button
                    onClick={() =>
                      onAddNewCity({
                        city: selectedCity,
                        eventId: queryData.event.id,
                      })
                    }
                    bg="none"
                    color="blue.500"
                    fontWeight="400"
                    w="4rem"
                    _hover={{}}
                    isDisabled={!isDisabledAddCity}
                  >
                    Adicionar
                  </Button>
                </Flex>
              </FormControl>

              {queryData.event.cities.length > 0 && (
                <Box mt="2.5rem">
                  <Text fontSize="1rem">Cidades adicionadas</Text>

                  <FlexWrapTag>
                    {queryData.event.cities?.map((city) => {
                      return (
                        <TagButton
                          key={city.name}
                          name={city.name}
                          isSelected={city.isSelected}
                          onClick={() => onSelectMultipleCities(city)}
                        />
                      );
                    })}
                  </FlexWrapTag>
                </Box>
              )}
            </Flex>
          </ModalBody>

          <ModalFooter bg="#EBEFF2" alignContent="end" gap="1rem">
            {hasCitySelected && (
              <Button
                bg="red.500"
                w="6.18rem"
                h="2rem"
                rounded="0.625rem"
                color="white"
                fontWeight="400"
                onClick={() => onDeleteCity(queryData.event.id)}
              >
                Remover
              </Button>
            )}

            <Button
              colorScheme="blue"
              onClick={() =>
                onToggleModal({
                  modalName: 'city',
                  isOpen: false,
                })
              }
              w="6.18rem"
              h="2rem"
              rounded="0.625rem"
              color="white"
              bg="blue.500"
              fontWeight="400"
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
}
