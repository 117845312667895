import {
  Button,
  Divider,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import { useUpdateEventStore } from '@/store/update-event';

export function TimelineStep() {
  const { steps, onChangeTab, tabIndex, currentTabIndex } = useUpdateEventStore(
    (state) => state,
  );

  const isMobile = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    {
      ssr: false,
    },
  );

  if (isMobile) {
    return (
      <Flex flexDirection="column">
        <Flex
          mt="2rem"
          justifyContent="space-between"
          position="relative"
          h="1.5rem"
        >
          {steps.map((step, index) => {
            return (
              <Button
                key={step.name}
                rounded="full"
                p="0"
                h="100%"
                zIndex="1"
                fontWeight="300"
                minW="1.5rem"
                fontSize="1rem"
                onClick={() => onChangeTab(index)}
                _disabled={{ color: 'blue.500' }}
                isDisabled={currentTabIndex === index}
                _hover={{}}
                border={
                  currentTabIndex === index ? '1px solid #3F7EF8' : 'none'
                }
                bg={currentTabIndex === index ? 'white' : 'blue.500'}
                color={currentTabIndex === index ? 'blue.500' : 'white'}
                variant="solid"
              >
                <Icon as={step.icon} />
              </Button>
            );
          })}

          <Divider position="absolute" bottom="50%" zIndex="0" />
        </Flex>

        <Text mt="0.8125rem" fontSize="0.625rem">
          Atualizando Evento
        </Text>

        <Text fontSize="1rem" color="gray.700" fontWeight="500" mb="2rem">
          {steps[tabIndex].name}
        </Text>
      </Flex>
    );
  }
}
