import { Flex, Spinner } from '@chakra-ui/react';

export function LoadingSpinner() {
  return (
    <Flex align="center" justify="center" mt="-5" h="100vh">
      <Spinner
        color="orange.400"
        size="xl"
        emptyColor="gray.200"
        thickness="0.25rem"
      />
    </Flex>
  );
}
