interface CalculatePercentageProtocolOpeningParams {
  totalTickets: number;
  totalProtocols: number;
}

function roundToTwoDecimalPlaces(value: number) {
  return Math.round(value * 100) / 100;
}

export function calculatePercentageProtocolOpening({
  totalTickets,
  totalProtocols,
}: CalculatePercentageProtocolOpeningParams) {
  if (totalTickets === 0 && totalProtocols === 0) {
    return 0;
  }

  const percentageProtocolOpening = (totalProtocols / totalTickets) * 100;

  return roundToTwoDecimalPlaces(percentageProtocolOpening);
}
