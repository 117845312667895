import { apiReactQuery } from '@/lib/axios/apiReactQuery';
import { queryClient } from '@/services/queryClient';
import { useUpdateEventStore } from '@/store/update-event';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiResponses, Return } from './types';
import { addIsSelected } from './add-is-selected';

interface LocationState {
  idEvent: string;
}

export function useGetEventDetailToUpdate() {
  const { onSaveCurrentSelectionsAndDetails, onAddIsUploadedToImage } =
    useUpdateEventStore((state) => state);

  const location = useLocation() as { state: LocationState };

  const navigate = useNavigate();

  const query = useQuery({
    queryKey: [
      'event-detail-to-update',
      location.state,
      location.state.idEvent,
    ],
    queryFn: async (): Promise<Return> => {
      if (!location.state) {
        navigate('/opened/events');
      }

      const [eventResponse, parametersResponse]: ApiResponses =
        await Promise.all([
          apiReactQuery.post('event/detail', {
            event_id: location.state.idEvent,
          }),
          apiReactQuery.get('event/parameters'),
        ]);

      const { cities, states, neighborhoods, device_cnt } = addIsSelected(
        eventResponse.data.event,
      );

      const event = {
        ...eventResponse.data.event,
        states: states,
        cities: cities,
        neighborhoods: neighborhoods,
        device_cnt: device_cnt,
      };

      const parameters = parametersResponse.data;

      onSaveCurrentSelectionsAndDetails({
        event,
        parameters,
      });

      onAddIsUploadedToImage(eventResponse.data.event.images);

      return {
        event,
        parameters,
      };
    },
  });

  const queryData = queryClient.getQueryData([
    'event-detail-to-update',
    location.state,
    location.state.idEvent,
  ]) as Return;

  return { ...query, queryData };
}
