import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';
import { apiReactQuery } from '@/lib/axios/apiReactQuery';
import { queryClient } from '@/services/queryClient';
import { useUpdateEventStore } from '@/store/update-event';
import { useMutation } from '@tanstack/react-query';
import { parseCookies } from 'nookies';
import { useLocation } from 'react-router-dom';

interface LocationState {
  idEvent: string;
}

export function useUpdateAboutEvent() {
  const { queryData } = useGetEventDetailToUpdate();

  const location = useLocation() as { state: LocationState };

  const {
    // eventDetails,
    name,
    description,
    selectedCategory,
    selectedRepairTeam,
    selectedCompany,
  } = useUpdateEventStore((state) => state);

  const mutation = useMutation({
    mutationFn: async () => {
      const getRepairTeam =
        selectedCategory.name === 'Indisponibilidade' && selectedRepairTeam.id;

      const { '@event-monitor-web:token-1.0.1': token } = parseCookies();

      const response = await apiReactQuery.put(
        '/event',
        {
          event_id: queryData.event.id,
          name,
          description,
          category_id: selectedCategory.id,
          company_id: selectedCompany.id,
          repair_team_id: getRepairTeam || '',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['latest-event'],
        exact: true,
      });

      queryClient.invalidateQueries({
        queryKey: [
          'event-detail-to-update',
          location.state,
          location.state.idEvent,
        ],
        exact: true,
      });
    },
  });

  return mutation;
}
