import { Box, Flex } from '@chakra-ui/react';

import { HeadingOne, HeadingTwo } from '@/components/Heading';
import { CompanyStats } from './CompanyStats';

interface Stat {
  company: string;
  url_small_logo: string;
  total_tickets: number;
  total_protocols: number;
}

interface TotalByCompanyProps {
  stats: Stat[];
}

export function TotalByCompany({ stats }: TotalByCompanyProps) {
  return (
    <Box h="100%">
      <HeadingOne text="Tickets e Protocolos" />

      <HeadingTwo
        px="0"
        py="1.625rem"
        color="gray.700"
        text="Total de Tickets e Protocolos por empresa"
      />

      <Flex
        alignItems="center"
        overflowX={{ base: 'scroll', lg: 'unset' }}
        gap={{ base: '1rem' }}
      >
        {stats.map((stat) => (
          <CompanyStats key={stat.company} stat={stat} />
        ))}
      </Flex>
    </Box>
  );
}
