import { useBreakpointValue } from '@chakra-ui/react';

import { ListTicketsMobile } from '@/components/TicketCardMobile';
import { AlertTicketEvent } from './AlertTicketEvent';
import { TableTicket } from './TableTicket';
import { Ticket } from '../types';

interface ListTicketsProps {
  tickets: Ticket[];
  isExistsTickets: boolean;
}

export function ListTickets({ tickets, isExistsTickets }: ListTicketsProps) {
  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      ssr: false,
    },
  );

  if (!isExistsTickets) {
    return <AlertTicketEvent />;
  }

  if (isMobile) {
    return <ListTicketsMobile tickets={tickets} />;
  }

  return <TableTicket tickets={tickets} />;
}
