import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Select,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Fragment } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { useCreateEventStore } from '@store/create-event';

export interface Status {
  historyEvent: string;
}

export function GroupStatus() {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<Status>();

  const {
    statusIdIndex,
    onChangeStatusIndex,
    historyStatus,
    onChangeSelectedStatus,
    selectedStatus,
    onSelectHistoryStatus,
  } = useCreateEventStore((state) => state);

  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      ssr: false,
    },
  );

  return (
    <Box>
      <Text
        color="gray.700"
        fontSize="0.875rem"
        fontWeight="500"
        display={{ base: 'none', lg: 'flex' }}
      >
        Escolha o status
      </Text>

      <FormControl isInvalid={!!errors.historyEvent} display="flex">
        {isMobile ? (
          <Flex w="100%" gap="1.125rem">
            <Controller
              name="historyEvent"
              control={control}
              defaultValue={selectedStatus.name}
              render={({ field }) => (
                <Select
                  placeholder="Escolha o status"
                  onChange={(event) => {
                    field.onChange(event);
                    onSelectHistoryStatus(event);
                  }}
                  value={field.value}
                >
                  {historyStatus.map((status) => (
                    <option key={status.id} value={JSON.stringify(status)}>
                      {status.name}
                    </option>
                  ))}
                </Select>
              )}
            />
          </Flex>
        ) : (
          <Flex gap={{ base: '0', xl: '1rem' }} mt="0.1875rem">
            {historyStatus.map((status) => (
              <Fragment key={status.id}>
                <FormLabel
                  htmlFor={status.name}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="1.687rem"
                  w={{ base: '100%', sm: '7.25rem' }}
                  rounded="full"
                  color={status.id === statusIdIndex ? 'blue.500' : 'gray.700'}
                  bg={status.id === statusIdIndex ? 'blue.100' : 'gray.200'}
                  _hover={{ filter: 'brightness(0.9)', cursor: 'pointer' }}
                  transition="all 0.4s ease"
                  onClick={() => onChangeSelectedStatus(status)}
                >
                  <Text fontSize="0.875rem" fontWeight="500">
                    {status.name}
                  </Text>
                </FormLabel>

                <RadioGroup
                  onChange={onChangeStatusIndex}
                  value={statusIdIndex}
                  display="none"
                >
                  <Radio
                    value={JSON.stringify(status)}
                    id={status.name}
                    {...register('historyEvent')}
                  >
                    {status.name}
                  </Radio>
                </RadioGroup>
              </Fragment>
            ))}
          </Flex>
        )}

        {!!errors.historyEvent && (
          <FormErrorMessage mt="0">
            {errors.historyEvent?.message}
          </FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
}
