import { Box, Flex, FormControl, Stack, TabPanel } from '@chakra-ui/react';

import { HeadingOne } from '@/components/Heading';
import { SnapEventInfo } from '../SnapEventInfo';
import { Category } from './Category';
import { Company } from './Company';
import { EventDescriptionInput } from './EventDescriptionInput';
import { EventNameInput } from './EventNameInput';
import { RepairTeam } from './RepairTeam';
import { SubmitButton } from './SubmitButton';

export function AboutEvent() {
  return (
    <TabPanel p="0" pb="2rem">
      <Flex h="100%" flexDir={{ base: 'column-reverse', lg: 'column' }}>
        <Flex
          justifyContent="space-between"
          align="center"
          mb={{ base: '0', lg: '1rem' }}
        >
          <HeadingOne
            text="Atualizando Evento"
            display={{ base: 'none', lg: 'flex' }}
          />

          <SubmitButton />
        </Flex>

        <Box>
          <SnapEventInfo />

          <FormControl mt="1.937rem">
            <Stack spacing="1.625rem">
              <EventNameInput />

              <EventDescriptionInput />

              <Category />
              <Company />

              <RepairTeam />
            </Stack>
          </FormControl>
        </Box>
      </Flex>
    </TabPanel>
  );
}
