import { AddIcon } from '@chakra-ui/icons';
import { Button, Flex, Icon, Text } from '@chakra-ui/react';

interface CreateOrEditModalButtonProps {
  data: object[];
  onOpenModalClick: () => void;
  type: 'create' | 'update';
}

export function CreateOrEditModalButton({
  data,
  onOpenModalClick,
  type,
}: CreateOrEditModalButtonProps) {
  const isCreateType = type === 'create';
  const buttonText = isCreateType
    ? 'Adicionar'
    : data.length > 0
      ? 'Editar'
      : 'Adicionar';

  return (
    <Button
      color="gray.400"
      fontWeight="400"
      onClick={onOpenModalClick}
      p="0"
      bg="transparent"
      transition="0.6 ease"
      _hover={{
        color: 'blue.500',
        filter: 'brightness(0.8)',
      }}
      _active={{}}
    >
      <Text>{buttonText}</Text>

      <Flex
        bg="blue.500"
        color="white"
        rounded="full"
        ml="0.5rem"
        w="1.5625rem"
        h="1.5625rem"
        align="center"
        justify="center"
      >
        <Icon as={AddIcon} fontSize="0.6rem" />
      </Flex>
    </Button>
  );
}
