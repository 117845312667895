import { useLocation } from 'react-router-dom';
import { useGetEventDetailToUpdate } from '..';
import { toast } from 'react-toastify';
import { apiAxios } from '@/lib/axios/apiAxios';
import { queryClient } from '@/services/queryClient';
import { Return } from '../types';
import { formatFirstString } from '@/utils/formatFirstString';
import { createRangeDeviceName } from '@/utils/createRangeArray';
import { ChangeEvent } from 'react';

interface LocationState {
  idEvent: string;
}

export interface ErrorMessage extends Error {
  data: {
    msg: string;
  };
}

export interface ErrorMessage2 extends Error {
  response: {
    data: {
      msg: string;
    };
  };
}

interface CityParams {
  event_id: string;
  id: string;
  name: string;
}

const maximumNumberOfDeviceId = 63; // total 64

export function useAddEventDetails() {
  const { queryData } = useGetEventDetailToUpdate();
  const location = useLocation() as { state: LocationState };

  async function onAddNewState(event, eventId) {
    const newFederativeUnit = JSON.parse(event.target.value);
    const currentFederativeUnits = queryData.event.states;

    const isExistsFederativeUnit = currentFederativeUnits.find(
      (item) => item.id === newFederativeUnit.id,
    );

    if (isExistsFederativeUnit) {
      event.target.value = '';
      return toast.error(`${newFederativeUnit.name} já existe`);
    }

    try {
      const { data } = await apiAxios.post('/state', {
        event_id: eventId,
        state_id: newFederativeUnit.id,
      });

      currentFederativeUnits.push({
        state_event_id: data.id,
        id: data.id,
        name: data.state.name,
        isSelected: false,
      });

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              states: currentFederativeUnits,
            },
          };
        },
      ) as Return;

      toast.success(`${data.state.name} foi adicionado!`);
      event.target.value = '';
    } catch (error) {
      const { response } = error as ErrorMessage2;
      toast.error(response.data.msg);
    }
  }

  async function onAddNewCity({ city, eventId }) {
    const isExistsCity = queryData.event.cities.find(
      (item) => item.id === city.id,
    );

    if (isExistsCity) {
      return toast.error(`${city.name} já existe`);
    }

    try {
      const { data } = await apiAxios.post<CityParams>('/city', {
        event_id: eventId,
        city_id: city.id,
      });

      const currentCities = queryData.event.cities;

      currentCities.push({
        event_id: data.event_id,
        id: data.id,
        name: data.name,
        isSelected: false,
      });

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              cities: currentCities,
            },
          };
        },
      ) as Return;

      toast.success('Evento atualizado!');
    } catch (error) {
      const response = error as ErrorMessage;

      toast.error(response.data.msg);
    }
  }

  async function onAddNewNeighborhood({ city, neighborhood, eventId }) {
    const newNeighborhood = formatFirstString(neighborhood);

    try {
      const response = await apiAxios.post('/neighborhood', {
        event_id: eventId,
        name: newNeighborhood,
        city_id: city.id,
      });

      const currentNeighborhoods = queryData.event.neighborhoods;

      currentNeighborhoods.push({
        name: newNeighborhood,
        id: response.data.id,
        cities: {
          name: city.name,
        },
        isSelected: false,
      });

      toast.success('Bairro adicionado com sucesso!');

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              neighborhoods: currentNeighborhoods,
            },
          };
        },
      ) as Return;
    } catch (error) {
      const { response } = error as ErrorMessage2;

      toast.error(response.data.msg);
    }
  }

  async function onAddNewDeviceCnt({ event, storeId, eventId }) {
    const eventValue = event as unknown as ChangeEvent<HTMLInputElement>;

    if (event.key === 'Enter') {
      event.preventDefault();

      if (eventValue.target.value === '') {
        return;
      }

      const isExistsMoreThanOneDeviceCnt = eventValue.target.value
        .split('')
        .some((e) => e === '-');

      if (!isExistsMoreThanOneDeviceCnt) {
        const deviceCntFrom = eventValue.target.value.split('-');
        const deviceCntFromNumber = Number(deviceCntFrom[0]);

        if (Number.isNaN(deviceCntFromNumber)) {
          toast.error('Insira apenas números');
          return;
        }

        const dataExists = queryData.event.device_cnt.find(
          (item) => item.name === String(deviceCntFromNumber),
        );

        if (dataExists) {
          toast.error(`${String(deviceCntFromNumber)} já existe`);

          eventValue.target.value = '';
          return;
        }

        try {
          const { data } = await apiAxios.post('/device-cnt', {
            event_id: eventId,
            store_id: storeId,
            names: [`${String(deviceCntFromNumber)}`],
          });

          const currentDeviceCnt = queryData.event.device_cnt;

          currentDeviceCnt.push({
            id: data[0].id,
            isSelected: false,
            name: data[0].name,
            description: data[0].description,
          });

          toast.success('Device Cnt adicionado com sucesso!');

          queryClient.setQueryData(
            ['event-detail-to-update', location.state, location.state.idEvent],
            (oldData) => {
              return {
                ...oldData,
                event: {
                  ...oldData.event,
                  device_cnt: currentDeviceCnt,
                },
              };
            },
          ) as Return;

          eventValue.target.value = '';
        } catch (error) {
          const { message } = error as Error;

          toast.error(message);
        }
      } else {
        const deviceCntFromTo = eventValue.target.value.split('-');
        const deviceCntFromToNumber = {
          from: Number(deviceCntFromTo[0]),
          to: Number(deviceCntFromTo[1]),
        };

        if (
          Number.isNaN(deviceCntFromToNumber.from) ||
          Number.isNaN(deviceCntFromToNumber.to)
        ) {
          toast.error('Insira apenas números');

          return;
        }

        if (deviceCntFromToNumber.from >= deviceCntFromToNumber.to) {
          toast.error('O primeiro valor é maior ou igual ao segundo');

          return;
        }

        if (
          deviceCntFromToNumber.to - deviceCntFromToNumber.from >
          maximumNumberOfDeviceId
        ) {
          toast.error(
            `Você ultrapassou a quantidade de ID Devices permitidos, (${
              maximumNumberOfDeviceId + 1
            })`,
          );

          return;
        }

        const newDeviceCntArray = createRangeDeviceName(
          deviceCntFromToNumber.from,
          deviceCntFromToNumber.to,
          1,
        );

        const currentDeviceCnt = queryData.event.device_cnt;

        const isExistsDeviceCntId = currentDeviceCnt.some((data) =>
          newDeviceCntArray.some((idDevice) => idDevice === data.name),
        );

        if (isExistsDeviceCntId) {
          toast.error('Device Id já cadastrado');

          eventValue.target.value = '';
          return;
        }

        try {
          const { data } = await apiAxios.post('/device-cnt', {
            event_id: eventId,
            store_id: storeId,
            names: newDeviceCntArray,
          });

          const addParameterIsSelected = data.map((device) => {
            return {
              id: device.id,
              isSelected: false,
              name: device.name,
              description: device.description,
            };
          });

          const currentDeviceCnt = queryData.event.device_cnt;

          const concatPrevAndNewDeviceCntId = currentDeviceCnt.concat(
            addParameterIsSelected,
          );

          toast.success('Devices Cnt adicionados com sucesso!');

          queryClient.setQueryData(
            ['event-detail-to-update', location.state, location.state.idEvent],
            (oldData) => {
              return {
                ...oldData,
                event: {
                  ...oldData.event,
                  device_cnt: concatPrevAndNewDeviceCntId,
                },
              };
            },
          ) as Return;

          eventValue.target.value = '';
        } catch (error) {
          const { message } = error as Error;

          toast.error(message);
        }
      }
    }
  }

  return {
    onAddNewState,
    onAddNewCity,
    onAddNewNeighborhood,
    onAddNewDeviceCnt,
  };
}
