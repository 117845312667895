import { useBreakpointValue } from '@chakra-ui/react';
import { DesktopFilter } from './DesktopFilter';
import { MobileFilter } from './MobileFilter';

export function Filter() {
  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      ssr: false,
    },
  );

  if (isMobile) {
    return <MobileFilter />;
  }

  return <DesktopFilter />;
}
