import {
  Button,
  Flex,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { Event } from '../types';

type PickModalAreaList = Pick<Event, 'id' | 'name' | 'description'>;

export interface ModalAreaListProps {
  title: string;
  dataToRender: PickModalAreaList[];
}

export function ModalAreaList({ title, dataToRender }: ModalAreaListProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        py="0.5rem"
        px="0"
        color="blue.500"
        bg="transparent"
        fontWeight="400"
        _hover={{ textDecoration: 'underline' }}
        onClick={onOpen}
      >
        Ver mais
      </Button>

      <ChakraModal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: 'xs', lg: 'xl' }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent h="28.875rem">
          <ModalHeader
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              color="gray.700"
              fontSize={{ base: '1rem', lg: 'xl' }}
              fontWeight="600"
            >
              {title}
            </Text>
            <Text fontWeight="400" fontSize={{ base: '0.875rem', lg: '1rem' }}>
              Locais e Equipamentos
            </Text>
          </ModalHeader>

          <ModalBody mt="-1rem">
            <Flex
              display="flex"
              flexWrap="wrap"
              gap="0.8rem"
              borderTop="0.0625rem solid #E5E7EA"
              pt="1rem"
            >
              {dataToRender.map((item) => (
                <Tag
                  key={item.name}
                  size="lg"
                  rounded="full"
                  bg="white"
                  color="blue.500"
                  border="0.0625rem solid #3F7EF8"
                >
                  <TagLabel>{item.description || item.name}</TagLabel>
                </Tag>
              ))}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
}
