import { Box, Center, Grid, Spinner } from '@chakra-ui/react';

import { InputFileAddImage } from '@/components/InputFileAddImage';
import { CardImageWithName } from '@/components/CardImageWithName';
import { useUpdateEventStore } from '@/store/update-event';

interface OnDeleteImageParams {
  id: string;
  imageUrl: string;
  isUploaded: boolean;
  name: string;
  lastModified?: number;
}

export function NewImagesEvent() {
  const {
    isLoading,
    allImages,
    onDeleteUploadedImage,
    onRemoveSelectedImage,
    onSelectImages,
  } = useUpdateEventStore((state) => state);

  function onDeleteImage(item: OnDeleteImageParams) {
    if (item.isUploaded) {
      return onDeleteUploadedImage(item);
    }

    onRemoveSelectedImage(item.lastModified);
  }

  return (
    <Box mt={{ base: '2rem', lg: '3.875rem' }}>
      <InputFileAddImage onChange={onSelectImages} />

      {isLoading ? (
        <Center mt="1.25rem">
          <Spinner color="blue.500" />
        </Center>
      ) : (
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            '2xl': 'repeat(3, 1fr)',
          }}
          gap={6}
          mt="1.25rem"
        >
          {allImages.map((image) => {
            return (
              <CardImageWithName
                key={image.id}
                data={image}
                isShowSize
                opacityCard={image.isUploaded ? 1 : 0.5}
                onClick={() => onDeleteImage(image)}
              />
            );
          })}
        </Grid>
      )}
    </Box>
  );
}
