import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { HeadingTwo } from '@/components/Heading';
import { SubmitNewEvent } from './types';

type SubmitDescription = Pick<SubmitNewEvent, 'description'>;

export function DescriptionTextArea() {
  const {
    register,
    formState: { errors },
  } = useFormContext<SubmitDescription>();

  return (
    <FormControl isInvalid={!!errors.description}>
      <FormLabel color="gray.700" htmlFor="description" display="flex">
        <HeadingTwo text="Descrição do evento" isRequired />
      </FormLabel>

      <Textarea
        placeholder="Faça uma breve descrição do que se trata o evento."
        bg="white"
        borderColor="gray.200"
        _placeholder={{ color: 'gray.200' }}
        focusBorderColor="none"
        id="description"
        {...register('description')}
      />
      {!!errors.description && (
        <FormErrorMessage mt="0">
          {errors.description?.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
