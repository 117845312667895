import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { KeyboardEvent } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

import { usePortalEventStore } from '@store/portal-event';
import { useGetPortalEvents } from '@/hooks/queries/get-portal-events';

export function SearchName() {
  const { nameEvent, changeNameEvent } = usePortalEventStore((state) => {
    const { nameEvent, changeNameEvent } = state;

    return { nameEvent, changeNameEvent };
  });

  const { refetch } = useGetPortalEvents();

  async function handleFetchPressEnter(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      refetch();
    }
  }

  return (
    <InputGroup w="18.75rem" border="transparent" bg="white" rounded="0.5rem">
      <InputLeftElement pointerEvents="none" w="1.875rem">
        <AiOutlineSearch color="gray.300" />
      </InputLeftElement>
      <Input
        enterKeyHint="search"
        pl="1.875rem"
        pr="0"
        type="text"
        name="search"
        placeholder="Pesquisar por nome do evento"
        _placeholder={{ color: 'gray.400' }}
        value={nameEvent}
        onChange={(e) => changeNameEvent(e.target.value)}
        onKeyDown={(e) => handleFetchPressEnter(e)}
      />
    </InputGroup>
  );
}
