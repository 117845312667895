import { Flex, Tag, Text } from '@chakra-ui/react';
import { ModalAreaList } from './ModalAreaList';

interface Item {
  id: string;
  description: string;
  name: string;
}

interface AreaDeviceProps {
  name: string;
  items: Item[];
}

export function AreaDevice({ name, items }: AreaDeviceProps) {
  const hasItems = items.length > 0;
  const firstTwoDatas = items.slice(0, 2);

  if (!hasItems) return null;

  return (
    <Flex
      flexWrap="wrap"
      marginBottom="0.5rem"
      alignItems="center"
      gap="0.5rem"
    >
      <Text>{name}</Text>

      {firstTwoDatas.map((item) => (
        <Tag
          key={item.id}
          variant="solid"
          colorScheme="blue"
          borderRadius="full"
        >
          {item.description || item.name}
        </Tag>
      ))}

      {items.length > 2 && <ModalAreaList title={name} dataToRender={items} />}
    </Flex>
  );
}
