import { DetailEventState } from '@/store/detail-event/types';

export const initialDetailEventState: DetailEventState = {
  event: {
    categories: {
      name: '',
    },
    cities: [
      {
        id: '',
        name: '',
        description: '',
      },
    ],
    companies: {
      name: '',
      url_logo: '',
    },
    created_at: '',
    created_in: '',
    create_date: '',
    description: '',
    device_cnt: [
      {
        description: '',
        id: '',
        name: '',
      },
    ],
    history_events: [
      {
        created_at: '',
        created_in: '',
        created_time: {
          date: '',
          time: '',
        },
        description: '',
        id: '',
        status: {
          name: '',
          background: '',
          color: '',
        },
        users: {
          name: '',
          avatar_url: '',
        },
      },
    ],
    HtmlPage: {
      html_url: '',
    },
    id: '',
    images: [
      {
        id: '',
        name: '',
        url: '',
      },
    ],
    name: '',
    neighborhoods: [
      {
        description: '',
        id: '',
        name: '',
      },
    ],
    repair_team: {
      name: '',
    },
    states: [
      {
        description: '',
        id: '',
        name: '',
      },
    ],
    stats: {
      protocol_count: 0,
      ticket_count: 0,
    },
    users: {
      name: '',
    },
    sla: {
      hours: 0,
      minutes: 0,
    },
  },
  isExistsEquipmentLocation: false,
};
