import { useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Divider,
  Flex,
  Icon,
  Link as LinkChakra,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { BsFillCheckCircleFill } from 'react-icons/bs';

import { useSubmitNewEvent } from '@/hooks/useSubmitNewEvent';
import { useFormCreateEvent } from '@/hooks/useFormCreateEvent';

import { useCreateEventStore } from '@store/create-event';
import { formatElapsedTime } from '@/utils/formatDate';

export function SuccessCard() {
  const navigate = useNavigate();

  const { newEventForm, reset } = useFormCreateEvent();

  const { resetStore } = useSubmitNewEvent();
  const { newEvent, onResetStatus, steps, onResetSteps } = useCreateEventStore(
    (state) => state,
  );

  function handleNavigateToDashboard() {
    resetStore();
    onResetSteps();
    onResetStatus();
    reset();
    navigate('/dashboard');
  }

  const isMobile = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    {
      ssr: false,
    },
  );

  return (
    <FormProvider {...newEventForm}>
      <Flex h="100vh" px={{ base: '2rem', sm: '4rem' }}>
        <Flex
          bg="white"
          w={{ base: '100%', lg: '62.8125rem' }}
          minH={{ base: '25rem', lg: '25.5625rem' }}
          m="auto"
          rounded="1.25rem"
        >
          <Flex
            py="2.5rem"
            px={{ base: '1.5rem', md: '4.3125rem' }}
            w="100%"
            flexDirection={{ base: 'column', lg: 'row' }}
          >
            <Stack
              flexDir="column"
              spacing={{ base: '0.5rem', lg: '0.75rem' }}
              justifyContent="center"
            >
              {steps.map((step) => {
                return (
                  <Flex key={step.id} align="center" gap="0.3125rem">
                    {step.isFinished && (
                      <>
                        <Icon as={BsFillCheckCircleFill} color="blue.500" />
                        <Text
                          textAlign="center"
                          fontSize={{ base: '0.8rem', lg: '1.125rem' }}
                        >
                          {step.name}
                        </Text>
                      </>
                    )}
                  </Flex>
                );
              })}
            </Stack>

            {!isMobile ? (
              <Divider orientation="vertical" mx="4.0625rem" />
            ) : (
              <Divider
                orientation="horizontal"
                my={{ base: '1rem', lg: '0' }}
              />
            )}

            <Flex flexDir="column" justifyContent="center">
              <Text
                color="blue.500"
                fontSize={{ base: '1.2rem', lg: '1.625rem' }}
                fontWeight="600"
              >
                Evento criado com sucesso
              </Text>

              <Text
                color="gray.700"
                fontWeight="500"
                fontSize={{ base: '0.9rem', lg: '1.125rem' }}
                mt={{ base: '1.5rem', lg: '3.25rem' }}
              >
                {newEvent.name}
              </Text>

              <Text mt="1.25rem" fontSize={{ base: '0.9rem', lg: '1.125rem' }}>
                Categoria: {newEvent.categories.name}
              </Text>
              <Text fontSize={{ base: '0.9rem', lg: '1.125rem' }}>
                Criado por {newEvent.users.name}{' '}
                {formatElapsedTime(newEvent.created_at)}
              </Text>

              <Stack
                direction={{ base: 'column', sm: 'row' }}
                mt={{ base: '2rem', lg: '2.875rem' }}
                spacing={{ base: '1rem', sm: '2.0625rem' }}
              >
                <Button
                  bg="blue.500"
                  color="white"
                  py="14px"
                  px="1.375rem"
                  h={{ base: '2.5rem', lg: '3rem' }}
                  rounded="0.5rem"
                  onClick={handleNavigateToDashboard}
                  w={{ base: '100%', lg: '10.25rem' }}
                  fontWeight="400"
                  fontSize={{ base: '0.9rem', lg: '1rem' }}
                >
                  Ir para Dashboard
                </Button>

                <LinkChakra
                  as={Link}
                  to={`/evento/${newEvent.HtmlPage.name}`}
                  isExternal
                  background="white"
                  color="blue.500"
                  rounded="0.5rem"
                  alignItems="center"
                  justifyContent="center"
                  display="flex"
                  h={{ base: '2.5rem', lg: '3rem' }}
                  w={{ base: '100%', lg: '10.25rem' }}
                  fontWeight="400"
                  fontSize={{ base: '0.9rem', lg: '1rem' }}
                >
                  Ver página do evento
                </LinkChakra>
              </Stack>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </FormProvider>
  );
}
