import { apiReactQuery } from '@/lib/axios/apiReactQuery';
import { formatElapsedTime } from '@/utils/formatDate';
import { useQuery } from '@tanstack/react-query';
import { Request, Response } from './types';

const ONE_MINUTE = 1000 * 60;
const TEN_MINUTES = ONE_MINUTE * 10;

export function useGetLatestEvent() {
  const query = useQuery({
    queryKey: ['latest-event'],
    queryFn: async (): Promise<Response | null> => {
      const { data } = await apiReactQuery.get<Request>('/event/last');

      const openedEvents = data.events.openedEvents;

      if (openedEvents.length <= 0) {
        return null;
      }

      const latestOpenedEvent = {
        ...openedEvents[0],
        elapsedTime: formatElapsedTime(openedEvents[0].created_at),
      };

      return latestOpenedEvent;
    },
    staleTime: TEN_MINUTES,
  });

  return query;
}
