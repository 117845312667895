import { Button } from '@chakra-ui/react';

export interface PaginationItemProps {
  number: number;
  isCurrent?: boolean;
  onPageChange: (page: number) => void;
}

export function PaginationItem({
  isCurrent = false,
  number,
  onPageChange,
}: PaginationItemProps) {
  if (isCurrent) {
    return (
      <Button
        size="sm"
        aria-disabled
        fontSize="1rem"
        w="4"
        color="blue.500"
        _hover={{
          bg: 'gray.100',
        }}
        disabled
        _disabled={{
          background: 'transparent',
          cursor: 'default',
        }}
      >
        {number}
      </Button>
    );
  }

  return (
    <Button
      size="sm"
      fontSize="1rem"
      w="4"
      bg="white"
      color="gray-800"
      fontWeight="500"
      _hover={{
        bg: 'gray.100',
      }}
      onClick={() => onPageChange(number)}
    >
      {number}
    </Button>
  );
}
