import { Box, Button, Center, Flex, Spinner, TabPanel } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { HeadingOne } from '@/components/Heading';
import { SnapEventInfo } from '../SnapEventInfo';
import { CreateHistory } from './CreateHistory';
import { useUpdateEventStore } from '@/store/update-event';
import { PastPublications } from './PastPublications';
import { useCreateHistory } from '@/hooks/mutations/create-history';

export function EventHistory() {
  const navigate = useNavigate();

  const { isLoading, selectedStatus, storyDescription } = useUpdateEventStore(
    (state) => state,
  );

  const isDataChangeFromApi = !storyDescription || !selectedStatus.id;

  const { mutateAsync } = useCreateHistory();

  async function onSubmitHistory() {
    await mutateAsync();

    if (selectedStatus.name === 'Resolvido') {
      return navigate('/previous/events');
    }
  }

  return (
    <TabPanel p="0" pb="2rem">
      <Flex h="100%" flexDir={{ base: 'column-reverse', md: 'column' }}>
        <Flex
          justifyContent="space-between"
          align="center"
          mb={{ base: '0', md: '1rem' }}
        >
          <HeadingOne
            text="Atualizando Evento"
            display={{ base: 'none', md: 'flex' }}
          />

          <Button
            mt={{ base: '3rem', md: '0' }}
            w={{ base: '100%', md: '6.8125rem' }}
            bg="blue.500"
            color="white"
            fontWeight="400"
            _hover={{ filter: 'brightness(0.9)' }}
            isDisabled={isDataChangeFromApi}
            onClick={onSubmitHistory}
          >
            Salvar
          </Button>
        </Flex>

        <Box>
          <SnapEventInfo />
          <CreateHistory />
        </Box>
      </Flex>

      {isLoading ? (
        <Center mt="3.9375rem">
          <Spinner />
        </Center>
      ) : (
        <PastPublications />
      )}
    </TabPanel>
  );
}
