import { ReactNode } from 'react';

import { AuthProvider } from './auth';
import { PaginationProvider } from './pagination';

export default function AppProvider({ children }: { children: ReactNode }) {
  return (
    <AuthProvider>
      <PaginationProvider>{children}</PaginationProvider>
    </AuthProvider>
  );
}
