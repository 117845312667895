import { NewEventState } from '@store/create-event/@types/save-new-event';

export const initialNewEventState: NewEventState = {
  newEvent: {
    company_id: '',
    description: '',
    id: '',
    name: '',
    user_id: '',
    created_at: '',
    categories: {
      name: '',
    },
    users: {
      name: '',
    },
    HtmlPage: {
      html_url: '',
      name: '',
    },
  },
};
