import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { HeadingTwo } from '@/components/Heading';
import { SubmitNewEvent } from './types';

type SubmitName = Pick<SubmitNewEvent, 'name'>;

export function NameInput() {
  const {
    register,
    formState: { errors },
  } = useFormContext<SubmitName>();

  return (
    <FormControl isInvalid={!!errors.name}>
      <FormLabel color="gray.700" htmlFor="name" display="flex">
        <HeadingTwo text="Nome do Evento" isRequired />
      </FormLabel>

      <Input
        placeholder="Nome do evento"
        bg="white"
        focusBorderColor="none"
        borderColor="gray.200"
        _placeholder={{ color: 'gray.200' }}
        id="name"
        {...register('name')}
      />

      {!!errors.name && (
        <FormErrorMessage mt="0">{errors.name?.message}</FormErrorMessage>
      )}
    </FormControl>
  );
}
