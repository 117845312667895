import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

interface AsideNavigationProps {
  device: 'desktop' | 'mobile';
}

export function AsideNavigation({ device }: AsideNavigationProps) {
  if (device === 'mobile') {
    return <Mobile />;
  }

  return <Desktop />;
}
