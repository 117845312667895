import { useReopenEventStore } from '@/store/reopen-event';
import { Flex, FormLabel, Text, Textarea } from '@chakra-ui/react';

export function TextareaDescription() {
  const { historyDescription, onChangeHistoryDescription } =
    useReopenEventStore((state) => state);

  return (
    <Flex
      as="form"
      mt={{ base: '1.5rem', sm: '2rem' }}
      display="flex"
      flexDir="column"
    >
      <FormLabel
        htmlFor="textarea-description"
        display="flex"
        color="gray.700"
        fontSize=".875rem"
        lineHeight="1.25rem"
      >
        Atualizar descrição <Text color="#FF0000">*</Text>
      </FormLabel>

      <Textarea
        id="textarea-description"
        minHeight="6.3125rem"
        maxH="6.3125rem"
        borderRadius=".25rem"
        _placeholder={{
          color: '#D2D2D2',
        }}
        placeholder="Faça uma breve descrição."
        value={historyDescription}
        onChange={(e) => onChangeHistoryDescription(e.target.value)}
      />
    </Flex>
  );
}
