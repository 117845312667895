import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react';
import { Fragment } from 'react';

import { HeadingTwo } from '../Heading';
import { GroupRadioProps } from './types';

export function GroupRadio({
  title,
  description,
  items,
  error,
  value,
  onSelect,
  type,
  onChangeValue,
  register,
  registerName,
  isRequired,
}: GroupRadioProps) {
  const countItems = items.length;

  const twoItemsColumns = {
    base: 'repeat(2, 1fr)',
    sm: 'repeat(4, 7.875rem)',
    md: 'repeat(4,8rem)',
  };

  const fourItemsColumns = {
    base: 'repeat(2, 1fr)',
    sm: 'repeat(2, 1fr)',
    md: 'repeat(4, 6.875rem)',
  };

  return (
    <Box
      border="1px solid #E5E7EA"
      rounded="0.5rem"
      py="2rem"
      px="1.625rem"
      minH={
        countItems > 2
          ? { base: '13rem', sm: '9.8125rem', md: '9.8125rem' }
          : { base: '10rem', md: '9.8125rem' }
      }
    >
      <HeadingTwo text={title} isRequired={isRequired} />

      {type === 'create' && (
        <Text color="gray.400" fontSize="1rem">
          {description}
        </Text>
      )}

      <FormControl isInvalid={!!error}>
        <Grid
          templateColumns={countItems > 2 ? fourItemsColumns : twoItemsColumns}
          gap={{ base: 2, md: 4 }}
          mt="1.25rem"
          h="100%"
        >
          {items.map((item) => (
            <Fragment key={item.id}>
              {type === 'create' ? (
                <>
                  <FormLabel
                    htmlFor={item.id}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    h="100%"
                    w={{ base: '100%' }}
                    rounded="full"
                    color={item.id === value ? 'blue.500' : 'gray.700'}
                    bg={item.id === value ? 'blue.100' : 'gray.200'}
                    _hover={{ filter: 'brightness(0.9)', cursor: 'pointer' }}
                    onClick={() => onSelect(item)}
                  >
                    <Text fontSize="0.875rem" fontWeight="500">
                      {item.name}
                    </Text>
                  </FormLabel>

                  <RadioGroup
                    onChange={onChangeValue}
                    value={value}
                    display="none"
                  >
                    <Radio
                      value={item.id}
                      id={item.id}
                      {...register(`${registerName}`)}
                      display="none"
                    >
                      {item.name}
                    </Radio>
                  </RadioGroup>
                </>
              ) : (
                <FormLabel
                  htmlFor={item.id}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100%"
                  w={{ base: '100%' }}
                  rounded="full"
                  color={item.id === value ? 'blue.500' : 'gray.700'}
                  bg={item.id === value ? 'blue.100' : 'gray.200'}
                  _hover={{ filter: 'brightness(0.9)', cursor: 'pointer' }}
                  onClick={() => onSelect(item)}
                >
                  <Text fontSize="0.875rem" fontWeight="500">
                    {item.name}
                  </Text>
                </FormLabel>
              )}
            </Fragment>
          ))}
        </Grid>

        {!!error && (
          <FormErrorMessage mt="0">{error?.message}</FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
}
