import { Th } from '@/components/Form/Th';
import { Table, TableContainer, Tbody, Td, Thead, Tr } from '@chakra-ui/react';
import { Ticket } from '../types';

interface TableTicketProps {
  tickets: Ticket[];
}

export function TableTicket({ tickets }: TableTicketProps) {
  return (
    <TableContainer>
      <Table bg="white" mt="0.75rem" rounded="0.5rem" variant="unstyled">
        <Thead>
          <Tr>
            <Th>Cód. Cliente</Th>
            <Th>Nome</Th>
            <Th>Plataforma</Th>
            <Th>Regra</Th>
            <Th>Horário do atendimento</Th>
            <Th>Protocolo</Th>
          </Tr>
        </Thead>

        <Tbody>
          {tickets.map((ticket) => {
            return (
              <Tr
                key={ticket.id}
                _notFirst={{
                  borderTop: '0.0625rem solid #DFE7F2',
                }}
              >
                <Td py="1.687rem" color="gray.700" fontWeight="500">
                  {ticket.customer.codigo_cliente}
                </Td>
                <Td py="1.687rem" w="30%">
                  {ticket.customer.personal_info.name}
                </Td>
                <Td py="1.687rem">{ticket.platform}</Td>
                <Td py="1.687rem">{ticket.hit_rule}</Td>
                <Td py="1.687rem">{ticket.service_hours}</Td>
                <Td py="1.687rem">{ticket.protocol_id}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
