import { Flex, Stack } from '@chakra-ui/react';
import { Company } from './Company';
import { QuickEventStatus } from './QuickEventStatus';
import { Tickets } from './Tickets';

import { LocationAndEquipment } from './LocationAndEquipment';
import { Images } from './Images';
import { useDetailEventStore } from '@/store/detail-event';
import { ButtonGoBack } from '@/components/ButtonGoBack';
import { HistoricEvent } from './HistoricEvent';

export function MobileLayout() {
  const { hasImages, isExistsEquipmentLocation } = useDetailEventStore(
    (state) => {
      const hasImages = state.event.images.length > 0;

      return { ...state, hasImages };
    },
  );
  return (
    <Flex flexDir="column" display={{ base: 'flex', md: 'none' }}>
      <ButtonGoBack />
      <Stack display={{ base: 'flex', md: 'none' }} spacing="2rem">
        <Company />
        <QuickEventStatus />
        <Tickets />
        <HistoricEvent />
        {isExistsEquipmentLocation && <LocationAndEquipment />}
        {hasImages && <Images />}
      </Stack>
    </Flex>
  );
}
