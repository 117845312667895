import { useBreakpointValue } from '@chakra-ui/react';

import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

export function Filter() {
  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      ssr: false,
    },
  );

  if (isMobile) {
    return <Mobile />;
  }

  return <Desktop />;
}
