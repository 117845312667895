import { StateCreator } from 'zustand';

import { formatBytes } from '@/utils/formatBytes';
import { ImageState, ImageActions } from '@store/create-event/@types/image';
import { initialImageState } from '@store/create-event/initial-state/image';

export const imageSlice: StateCreator<ImageState & ImageActions> = (
  set,
  get,
) => ({
  ...initialImageState,
  onAddNewImage: (event) => {
    if (!event.target.files) return;
    const selectedImages = Array.from(event.target.files);
    const currentImages = get().newImages;

    const sizeArrayImages = selectedImages.length;
    let index = 0;

    while (index < sizeArrayImages) {
      currentImages.push(selectedImages[index]);
      index++;
    }

    const preview = currentImages.map((image) => {
      return {
        imageUrl: URL.createObjectURL(image),
        name: image.name,
        size: formatBytes(image.size),
        lastModified: image.lastModified,
      };
    });

    set({ newImages: currentImages, previewImages: preview });
  },
  onRemoveImage: (lastModified) => {
    const currentImages = get().newImages;

    const indexImageToRemove = currentImages.findIndex(
      (item) => item.lastModified === lastModified,
    );

    currentImages.splice(indexImageToRemove, 1);

    const preview = currentImages.map((image) => {
      return {
        imageUrl: URL.createObjectURL(image),
        name: image.name,
        size: formatBytes(image.size),
        lastModified: image.lastModified,
      };
    });

    set({ newImages: currentImages, previewImages: preview });
  },
  onResetImages: () => {
    set({ newImages: [], previewImages: [] });
  },
});
