import {
  Box,
  Center,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Table,
  TableContainer,
  TabPanel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';

import { HeadingOne } from '@/components/Heading';
import { Pagination } from '@/components/Pagination';
import { usePagination } from '@/hooks/usePagination';
import { formatDateWithHour } from '@/utils/formatDate';
import { StackCardsStatsUpdateEvent } from './StackCardsStatsUpdateEvent';
import { AlertTicketEvent } from './AlertTicketEvent';
import { EventTicketCardMobile } from './EventTicketCardMobile';

import {
  Stats,
  Event,
  Ticket as TicketProps,
  PaginationData,
  RequestTickets,
} from './types';
import { SnapEventInfo } from '../SnapEventInfo';
import { apiAxios } from '@/lib/axios/apiAxios';

interface LocationState {
  idEvent: string;
}

export function Ticket() {
  const location = useLocation() as { state: LocationState };
  const navigate = useNavigate();

  const [stats, setStats] = useState({} as Stats);
  const [event, setEvent] = useState({} as Event);
  const [tickets, setTickets] = useState<TicketProps[]>([]);
  const [isExistsTickets, setIsExistsTickets] = useState(false);
  const [listTickets, setListTickets] = useState([]);
  const [searchNameEvent, setSearchNameEvent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [pagination, setPagination] = useState({} as PaginationData);
  const { limit } = usePagination();
  const [currentNumberPage, setCurrentNumberPage] = useState<number>(1);

  const toast = useToast();

  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      ssr: false,
    },
  );

  useEffect(() => {
    async function getAllEventsTickets() {
      if (!location.state) {
        navigate('/opened/events');
      }

      setIsLoading(true);
      try {
        const response = await apiAxios.get<RequestTickets>(
          `/event/detail/${location.state.idEvent}/tickets?search&limit=${limit}&page=${currentNumberPage}&filter`,
        );

        const { stats, event, pagination, tickets } = response.data;

        const parsedStats = {
          ticket_count: stats.total_tickets,
          protocol_count: stats.total_protocol,
        };

        const isExistsTicketData = tickets.length > 0;

        const parsedTickets = tickets.map((ticket) => {
          return {
            ...ticket,
            created_at: formatDateWithHour(ticket.created_at),
          };
        });

        setStats(parsedStats);
        setEvent(event);
        setPagination(pagination);
        setTickets(parsedTickets);
        setListTickets(parsedTickets);
        setIsExistsTickets(isExistsTicketData);

        setIsLoading(false);
      } catch (error) {
        const { message } = error as Error;

        toast({
          title: message,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
    getAllEventsTickets();
  }, [
    location.state.idEvent,
    currentNumberPage,
    limit,
    toast,
    location.state,
    navigate,
  ]);

  async function handleSearchNameOrPE(
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>,
  ) {
    const inputValue = event.currentTarget.value.trim();

    if (!inputValue) {
      setListTickets(tickets);
      return;
    }

    const isEnterKey = (event as KeyboardEvent).key === 'Enter';
    const isClick = event.type === 'click';

    if (isEnterKey || isClick) {
      try {
        setIsLoading(true);
        const response = await apiAxios.get<RequestTickets>(
          `/event/detail/${location.state.idEvent}/tickets?search=${inputValue}&limit=10&page=1&filter`,
        );

        setListTickets(response.data.tickets);
        setIsLoading(false);
      } catch (error) {
        const { message } = error as Error;

        toast({
          title: message,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  }

  return (
    <TabPanel p="0" pb="2rem">
      <Flex
        justifyContent="space-between"
        align="center"
        mb={{ base: '0', lg: '1rem' }}
      >
        <HeadingOne
          text="Atualizando Evento"
          display={{ base: 'none', lg: 'flex' }}
        />
      </Flex>

      <SnapEventInfo />

      <Box mt="3rem">
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <Box>
            {!isExistsTickets ? (
              <AlertTicketEvent />
            ) : (
              <Flex flexDir="column">
                <HeadingOne text={event.name} />

                <StackCardsStatsUpdateEvent statsData={stats} />

                <InputGroup
                  mt="1.375rem"
                  bg="white"
                  rounded="0.5rem"
                  border="0px solid #E5E7EA"
                >
                  <InputLeftElement pointerEvents="none" w="1.875rem">
                    <AiOutlineSearch color="gray.300" />
                  </InputLeftElement>
                  <Input
                    enterKeyHint="search"
                    pl="1.875rem"
                    pr="0"
                    type="search"
                    name="search"
                    placeholder="Pesquisar por nome do cliente ou código do cliente"
                    _placeholder={{ color: 'gray.400 ' }}
                    onChange={(e) => setSearchNameEvent(e.target.value)}
                    onKeyDown={(event) => handleSearchNameOrPE(event)}
                    value={searchNameEvent}
                  />
                </InputGroup>

                {isMobile ? (
                  <Flex mt="1.25rem" flexDirection="column" gap="1.187rem">
                    {listTickets.map((ticket) => {
                      return (
                        <EventTicketCardMobile key={ticket.id} data={ticket} />
                      );
                    })}
                  </Flex>
                ) : (
                  <TableContainer
                    border="1px solid #E5E7EA"
                    mt="0.75rem"
                    style={{ borderRadius: '0.625rem' }}
                  >
                    <Table bg="white" rounded="0.5rem">
                      <Thead>
                        <Tr>
                          <Th
                            color="blue.500"
                            textTransform="capitalize"
                            fontSize="1rem"
                            fontWeight="600"
                            py="1rem"
                            rounded="20px"
                            overflow="hidden"
                          >
                            Cód Cliente
                          </Th>
                          <Th
                            color="blue.500"
                            textTransform="capitalize"
                            fontSize="1rem"
                            fontWeight="600"
                          >
                            Nome
                          </Th>
                          <Th
                            color="blue.500"
                            textTransform="capitalize"
                            fontSize="1rem"
                            fontWeight="600"
                          >
                            Plataforma
                          </Th>
                          <Th
                            color="blue.500"
                            textTransform="capitalize"
                            fontSize="1rem"
                            fontWeight="600"
                          >
                            Regra
                          </Th>
                          <Th
                            color="blue.500"
                            textTransform="capitalize"
                            fontSize="1rem"
                            fontWeight="600"
                          >
                            Horário do atendimento
                          </Th>

                          <Th
                            color="blue.500"
                            textTransform="capitalize"
                            fontSize="1rem"
                            fontWeight="600"
                          >
                            Protocolo
                          </Th>
                        </Tr>
                      </Thead>

                      <Tbody>
                        {listTickets.map((ticket) => {
                          return (
                            <Tr
                              key={ticket.id}
                              _notFirst={{
                                borderTop: '0.0625rem solid #DFE7F2',
                              }}
                            >
                              <Td
                                py="1.687rem"
                                color="gray.700"
                                fontWeight="500"
                              >
                                {ticket.customer.codigo_cliente}
                              </Td>
                              <Td py="1.687rem" w="30%">
                                {ticket.customer.personal_info.name}
                              </Td>
                              <Td py="1.687rem">{ticket.platform}</Td>
                              <Td py="1.687rem">{ticket.hit_rule}</Td>
                              <Td py="1.687rem">{ticket.created_at}</Td>
                              <Td py="1.687rem">
                                {ticket.protocols.length <= 0
                                  ? 'Sem Protocolo'
                                  : ticket.protocols[0].protocol_cnt_id}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                )}

                {pagination.total > 10 && (
                  <Flex
                    mt="0.75rem"
                    px="1.375rem"
                    bg="white"
                    rounded="0.5rem"
                    py="0.6875rem "
                    justify="space-between"
                    border="1px solid #E5E7EA"
                  >
                    {!isMobile && (
                      <Text display="flex" alignItems="center">
                        Visualizando {pagination.from}-{pagination.to} de{' '}
                        {pagination.total} eventos.
                      </Text>
                    )}

                    <Pagination
                      totalCountOfRegisters={pagination.total}
                      currentPage={currentNumberPage}
                      setCurrentPage={setCurrentNumberPage}
                      registerPerPage={limit}
                    />
                  </Flex>
                )}
              </Flex>
            )}
          </Box>
        )}
      </Box>
    </TabPanel>
  );
}
