import { FormControl, FormLabel, Text, Textarea } from '@chakra-ui/react';

import { useUpdateEventStore } from '@/store/update-event';

export function DescriptionArea() {
  const { storyDescription, onInputDescription } = useUpdateEventStore(
    (state) => state,
  );

  return (
    <FormControl mt={{ base: '1.5rem', md: '2rem' }}>
      <FormLabel
        color="gray.700"
        htmlFor="historyDescription"
        fontSize="0.875rem"
        lineHeight="1.25rem"
        display="flex"
        mb="0"
      >
        Atualizar descrição <Text color="#FF0000">*</Text>
      </FormLabel>

      <Textarea
        mt="0.25rem"
        value={storyDescription}
        color="gray.700"
        placeholder="Faça uma breve descrição."
        bg="gray.50"
        _placeholder={{ color: 'gray.400' }}
        focusBorderColor="none"
        id="historyDescription"
        borderColor="#E5E7EA"
        minHeight={{ base: '6.3125rem', md: '3.5625rem' }}
        maxHeight={{ base: '6.3125rem', md: '3.5625rem' }}
        onChange={(e) => onInputDescription(e.target.value)}
      />
    </FormControl>
  );
}
