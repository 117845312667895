import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { MutableRefObject } from 'react';
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from 'react-icons/md';

interface CustomArrowProps extends ButtonProps {
  direction: 'left' | 'right';
  openRef?: MutableRefObject<unknown>;
}

export function CustomArrow({ direction, openRef, ...rest }: CustomArrowProps) {
  return (
    <Button
      ref={openRef}
      minWidth={{ base: '1.625rem', md: '2rem' }}
      rounded="full"
      padding="0"
      background="blue.100"
      color="blue.500"
      height={{ base: '1.625rem', md: '2rem' }}
      {...rest}
    >
      {direction === 'left' ? (
        <Icon as={MdOutlineArrowBackIos} aria-label="left" />
      ) : (
        <Icon as={MdOutlineArrowForwardIos} aria-label="right" />
      )}
    </Button>
  );
}
