import { AvatarLogo } from '@/components/AvatarLogo';
import { Divider, Flex, Text } from '@chakra-ui/react';

export interface CompanyStat {
  company: string;
  url_small_logo: string;
  total_tickets: number;
  total_protocols: number;
}

interface CompanyStatsProps {
  stat: CompanyStat;
}

export function CompanyStats({ stat }: CompanyStatsProps) {
  return (
    <Flex
      key={stat.company}
      flex={{ base: 0, lg: 1 }}
      minW={{ base: '15rem', lg: 'unset' }}
      h="100%"
      bg="white"
      rounded="1.25rem"
      px="1.2rem"
      py="0.9375rem"
      flexDirection="column"
    >
      <Flex alignItems="center" gap="0.5rem">
        <AvatarLogo
          url={stat.url_small_logo}
          circleSize="sm"
          name={stat.company}
        />

        <Text color="gray.700" w="94%">
          {stat.company}
        </Text>
      </Flex>

      <Flex mt="0.5rem" alignItems="center">
        <Text as="span" color="blue.500" fontSize="1.75rem" fontWeight="500">
          {stat.total_tickets}
        </Text>
        <Text ml="0.875rem">Tickets</Text>
      </Flex>

      <Divider mt="1.25rem" mb="1.25rem" />

      <Flex alignItems="center">
        <Text as="span" color="blue.500" fontSize="1.75rem" fontWeight="500">
          {stat.total_protocols}
        </Text>
        <Text ml="0.875rem">Protocolos</Text>
      </Flex>
    </Flex>
  );
}
