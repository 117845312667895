import { format, parseISO, formatDistanceToNowStrict, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';

export const formatDateDayMonthYear = (date: string) =>
  format(parseISO(date), 'd MMM, y', {
    locale: ptBR,
  });

export const formatDateWithHour = (date: string) =>
  format(parseISO(date), "dd MMM, y' às ' HH'h':mm", {
    locale: ptBR,
  });

export const formatElapsedTime = (date: string) => {
  const convertInElapseTime = formatDistanceToNowStrict(parseISO(date), {
    locale: ptBR,
    addSuffix: true,
  });

  return convertInElapseTime;
};

export const formatDateHour = (date: string) =>
  format(parseISO(date), 'HH:mm', {
    locale: ptBR,
  });

export const subtractDaysFromToday = (days: number) =>
  format(subDays(new Date(), days), `yyyy-MM-dd'T00:00`, {
    locale: ptBR,
  });

export const getTodayWithHourAndMinutes = () =>
  format(new Date(), "yyyy-MM-dd'T'HH:mm");

export const getEventStepperDate = (date: string) => {
  const parsedDate = parseISO(date);

  return {
    date: format(parsedDate, 'dd MMM, yyyy', { locale: ptBR }),
    time: format(parsedDate, "'às' HH:mm", { locale: ptBR }),
  };
};
