import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';

import smallLogoImg from '@/assets/images/small_logo.svg';

interface LogoProps {
  collapse: boolean;
}

export function Logo({ collapse }: LogoProps) {
  return (
    <Flex
      w="full"
      alignItems="center"
      justifyContent="space-between"
      flexDirection={collapse ? 'row' : 'column'}
      gap="1.5rem"
    >
      <Box display="flex" alignItems="center" gap="1.5rem">
        <Image src={smallLogoImg} alt="logo reduzida da click ip" />

        {collapse && (
          <Heading color="gray.400" fontSize="lg" fontWeight="normal">
            Monitoramento
            <Text color="orange.500" fontWeight="bold" fontSize="1rem">
              de Eventos
            </Text>
          </Heading>
        )}
      </Box>
    </Flex>
  );
}
