import { Th as ChakraTh, TableColumnHeaderProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface ThProps extends TableColumnHeaderProps {
  children: ReactNode;
}

export function Th({ children, ...rest }: ThProps) {
  return (
    <ChakraTh
      color="blue.500"
      textTransform="capitalize"
      fontSize="1rem"
      fontWeight="600"
      py="1rem"
      {...rest}
    >
      {children}
    </ChakraTh>
  );
}
