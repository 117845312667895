import { z } from 'zod';

export const newEventValidationSchema = z.object({
  name: z.string().min(1, { message: 'Nome do evento é obrigatório' }),
  description: z.string().min(1, { message: 'Descrição é obrigatória' }),
  historyDescription: z.string().min(6, {
    message:
      'A Descrição da História do Evento precisa ter pelo menos 6 letras',
  }),
  categoryId: z
    .string({
      description: 'Categoria é obrigatória',
      invalid_type_error: 'Categoria é obrigatória',
    })
    .min(1, { message: 'Categoria é obrigatória' }),
  companyId: z
    .string({
      description: 'Empresa é obrigatória',
      invalid_type_error: 'Empresa é obrigatória',
    })
    .min(1, { message: 'Empresa é obrigatória' }),
  repairTeamId: z.optional(z.nullable(z.string())),
  historyEvent: z
    .string({
      description: 'Status é obrigatório',
      invalid_type_error: 'Status é obrigatório',
    })
    .min(1, { message: 'Status é obrigatório' }),
});
