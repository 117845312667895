import {
  Box,
  Button,
  Flex,
  FormControl,
  ModalBody,
  ModalFooter,
  Text,
  Select,
  Input,
  Modal as ChakraModal,
} from '@chakra-ui/react';
import { ChangeEvent, useState } from 'react';

import { useUpdateEventStore } from '@/store/update-event';
import { FlexWrapTag } from '@/components/Modal/ModalLocationEquipment/FlexWrapTag';
import { TagButton } from '@/components/Modal/ModalLocationEquipment/TagButton';
import { CreateOrEditModalButton } from '@/components/Modal/ModalLocationEquipment/CreateOrEditModalButton';
import { ModalContent } from '@/components/Modal/ModalLocationEquipment/ModalContent';
import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';

import {
  useSelectMultiple,
  useAddEventDetails,
  useDeleteEventDetails,
} from '@/hooks/queries/get-event-detail-to-update/query-updater';

interface City {
  id: string;
  name: string;
}

interface State {
  id: string;
  cities: City[];
  name: string;
  city: [
    {
      id: string;
      name: string;
    },
  ];
}

export interface SelectedCity {
  id: string;
  name: string;
  event_id: string;
  isSelected: boolean;
}

export function Modal() {
  const [state, setState] = useState({} as State);
  const [selectedCity, setSelectedCity] = useState({} as SelectedCity);
  const [neighborhood, setNeighborhood] = useState<string>('');

  const { queryData } = useGetEventDetailToUpdate();

  const { onSelectMultipleNeighborhoods } = useSelectMultiple();

  const hasNeighborhoodSelected = queryData.event.neighborhoods.some((item) => {
    return item.isSelected === true;
  });

  const { onToggleModal, modals } = useUpdateEventStore((state) => state);

  const { onAddNewNeighborhood } = useAddEventDetails();
  const { onDeleteNeighborhood } = useDeleteEventDetails();

  function handleSelectState(event: ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === '') {
      setState({} as null);
      return;
    }

    setState(JSON.parse(event.target.value));
  }

  function handleGetCity(event: ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === '') {
      setSelectedCity({} as null);
      return;
    }

    setSelectedCity(JSON.parse(event.target.value));
  }

  function handleInsertNeighborhood(event: ChangeEvent<HTMLInputElement>) {
    setNeighborhood(event.target.value);
  }

  const hasState = !!state.id;
  const hasCity = !!selectedCity.id;

  const hasCityStateAndNeighborhood =
    hasState && hasCity && neighborhood.length > 0;

  return (
    <>
      <CreateOrEditModalButton
        data={queryData.event.neighborhoods}
        onOpenModalClick={() =>
          onToggleModal({
            modalName: 'neighborhood',
            isOpen: true,
          })
        }
        type="update"
      />

      <ChakraModal
        isOpen={modals.neighborhood.isOpen}
        onClose={() =>
          onToggleModal({
            modalName: 'neighborhood',
            isOpen: false,
          })
        }
        size="2xl"
        isCentered
      >
        <ModalContent name="Bairro">
          <ModalBody>
            <Flex flexDirection="column">
              <FormControl display="flex" alignItems="center">
                <Flex w="100%" gap="1.125rem">
                  <Select
                    placeholder="Estado"
                    onChange={handleSelectState}
                    w="9rem"
                  >
                    {queryData.parameters.states?.map((state) => {
                      return (
                        <option key={state.id} value={JSON.stringify(state)}>
                          {state.name}
                        </option>
                      );
                    })}
                  </Select>

                  <Select
                    placeholder="Cidade"
                    onChange={handleGetCity}
                    w="12rem"
                    disabled={!hasState}
                  >
                    {state.city?.map((city) => {
                      return (
                        <option key={city.id} value={JSON.stringify(city)}>
                          {city.name}
                        </option>
                      );
                    })}
                  </Select>

                  <Flex align="center" gap="0.5rem">
                    <Text>Nome</Text>

                    <Input
                      placeholder="Bairro"
                      bg="white"
                      onChange={handleInsertNeighborhood}
                      disabled={!hasState || !hasCity}
                    />
                  </Flex>

                  <Button
                    onClick={() =>
                      onAddNewNeighborhood({
                        city: selectedCity,
                        neighborhood,
                        eventId: queryData.event.id,
                      })
                    }
                    bg="none"
                    color="blue.500"
                    fontWeight="400"
                    w="4rem"
                    _hover={{}}
                    isDisabled={!hasCityStateAndNeighborhood}
                  >
                    Adicionar
                  </Button>
                </Flex>
              </FormControl>

              {queryData.event.neighborhoods.length > 0 && (
                <Box mt="2.5rem">
                  <Text fontSize="1rem">Bairros adicionados</Text>

                  <FlexWrapTag>
                    {queryData.event.neighborhoods.map((neighborhood) => {
                      return (
                        <TagButton
                          key={neighborhood.id}
                          name={`${neighborhood.name} - ${neighborhood.cities.name} `}
                          isSelected={neighborhood.isSelected}
                          onClick={() =>
                            onSelectMultipleNeighborhoods(neighborhood)
                          }
                        />
                      );
                    })}
                  </FlexWrapTag>
                </Box>
              )}
            </Flex>
          </ModalBody>

          <ModalFooter bg="#EBEFF2" alignContent="end" gap="1rem">
            {hasNeighborhoodSelected && (
              <Button
                bg="red.500"
                w="6.18rem"
                h="2rem"
                rounded="0.625rem"
                color="white"
                fontWeight="400"
                onClick={onDeleteNeighborhood}
              >
                Remover
              </Button>
            )}

            <Button
              colorScheme="blue"
              onClick={() =>
                onToggleModal({
                  modalName: 'neighborhood',
                  isOpen: false,
                })
              }
              w="6.18rem"
              h="2rem"
              rounded="0.625rem"
              color="white"
              bg="blue.500"
              fontWeight="400"
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
}
