import { AboutEventState } from '@store/create-event/@types/about-event';

export const initialAboutEvent: AboutEventState = {
  selectedCompany: {
    id: '',
    name: '',
    company_stores: [],
  },
  selectedCategory: {
    id: '',
    name: '',
  },
  selectedRepairTeam: {
    id: '',
    name: '',
  },
  categoryIndex: '0',
  companyIdIndex: '0',
  repairTeamIndex: '0',
};
