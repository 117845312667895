import { useFormContext } from 'react-hook-form';

import { GroupRadio } from '@/components/GroupRadio';
import { SubmitNewEvent } from './types';
import { useCreateEventStore } from '@store/create-event';

type SubmitCompany = Pick<SubmitNewEvent, 'repairTeamId'>;

export function RepairTeamsRadio() {
  const {
    register,
    formState: { errors },
  } = useFormContext<SubmitCompany>();

  const {
    repairTeams,
    repairTeamIndex,
    selectedCategory,
    onChangeRepairTeamIndex,
    onChangeSelectedRepairTeam,
  } = useCreateEventStore((state) => state);

  if (selectedCategory.name === 'Indisponibilidade') {
    return (
      <GroupRadio
        title="Parceiro"
        description="Escolha a equipe parceira responsável por fazer o reparo."
        value={repairTeamIndex}
        items={repairTeams}
        type="create"
        isRequired
        error={errors.repairTeamId}
        onChangeValue={onChangeRepairTeamIndex}
        onSelect={onChangeSelectedRepairTeam}
        register={register}
        registerName="repairTeamId"
      />
    );
  }
}
