import {
  Flex,
  Box,
  Heading,
  HStack,
  Image as ChakraImage,
  Button,
} from '@chakra-ui/react';
import Carousel from 'react-multi-carousel';

import { CustomArrow } from './CustomArrow';
import { responsive } from './responsive';
import { useButtonNavigation } from '@/hooks/useButtonNavigation';
import { FullScreenModal } from './FullScreenModal';

import { usePublicEventStore } from '@store/public-event';

interface Image {
  url: string;
}

interface ImagesProps {
  images: Image[];
}

export function Images({ images }: ImagesProps) {
  const { handleAction, buttonRefs } = useButtonNavigation();

  const handleOpenFocusImage = usePublicEventStore(
    (state) => state.handleOpenFocusImage,
  );

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading fontSize={{ base: '1rem', md: '1.625rem' }} color="gray.700">
          Imagens
        </Heading>

        <HStack>
          <CustomArrow
            direction="left"
            openRef={buttonRefs.prev.visible}
            onClick={() => handleAction('prev')}
          />
          <CustomArrow
            openRef={buttonRefs.next.visible}
            onClick={() => handleAction('next')}
            direction="right"
          />
        </HStack>
      </Flex>

      <FullScreenModal images={images} />

      <Box mt="1.375rem">
        <Carousel
          customLeftArrow={
            <CustomArrow
              openRef={buttonRefs.prev.hidden}
              position="absolute"
              direction="left"
              display="none"
            />
          }
          customRightArrow={
            <CustomArrow
              openRef={buttonRefs.next.hidden}
              position="absolute"
              direction="right"
              display="none"
            />
          }
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          responsive={responsive}
          slidesToSlide={1}
        >
          {images.map((image, index) => {
            return (
              <Button
                key={image.url}
                h="14.375rem"
                w={{ base: '100%', md: '14.375rem' }}
                borderWidth="0.0625rem"
                borderColor="#E5E7EA"
                display="flex"
                p="0"
                background="white"
                onClick={() => handleOpenFocusImage(index)}
              >
                <Flex height="100%">
                  <ChakraImage
                    src={image.url}
                    alt={image.url}
                    objectFit="contain"
                  />
                </Flex>
              </Button>
            );
          })}
        </Carousel>
      </Box>
    </Box>
  );
}
