import { StateCreator } from 'zustand';
import { initialModalStateControlState } from '@store/update-event/initial-state/modal-state-control';
import {
  ModalStateControlState,
  ModalStateControlActions,
} from '@store/update-event/@types/modal-state-control';

export const createModalStateControlSlice: StateCreator<
  ModalStateControlState & ModalStateControlActions
> = (set) => ({
  ...initialModalStateControlState,
  onToggleModal: ({ modalName, isOpen }) => {
    set((state) => {
      if (state.modals[modalName] !== undefined) {
        state.modals[modalName].isOpen = isOpen;
      }

      return state;
    });

    set((changedState) => ({ modals: changedState.modals }));
  },
});
