import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';

const PATH_HOME = '/';
const PATH_LOGIN_REDIRECT = '/login/redirect/';
const PATH_DASHBOARD = '/dashboard';

export function RedirectOnAuth({ children: page }: { children: JSX.Element }) {
  const { user } = useAuth();
  const { pathname } = useLocation();

  if (!user && pathname !== PATH_HOME && pathname !== PATH_LOGIN_REDIRECT) {
    return <Navigate to={PATH_HOME} />;
  }

  if (!user && pathname === PATH_HOME) {
    return page;
  }

  if (pathname === PATH_HOME && user) {
    return <Navigate to={PATH_DASHBOARD} />;
  }

  return page;
}
