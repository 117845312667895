import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { AsideMenu } from './AsideMenu';

import logoImg from '@/assets/images/logo.svg';
import { AsideMenuMobile } from './AsideMenu/AsideMenuMobile';

export function Welcome() {
  return (
    <Flex h="100%">
      <AsideMenu />
      <AsideMenuMobile />

      <Flex
        w="100%"
        flexDir="column"
        ml={{ base: '0', lg: '17rem', xl: '20rem' }}
      >
        <Flex h="100vh">
          <Flex
            px={{ base: '1rem', lg: '4.875rem' }}
            w="100%"
            flexDirection="column"
            my="auto"
          >
            <Flex gap="2.875rem" align="center" mx="auto ">
              <Box w={{ base: '7rem', md: '9.687rem' }} h="4.312rem">
                <Image boxSize="100%" src={logoImg} alt="logo da click ip" />
              </Box>
              <Text
                as="span"
                fontSize={{ base: '1rem', md: '1.125rem' }}
                color="gray.400"
              >
                Monitoramento
                <Text color="blue.800" fontWeight="700">
                  de Eventos
                </Text>
              </Text>
            </Flex>
            <Flex
              bg="white"
              align="center"
              py="3.75rem"
              px="2rem"
              rounded="0.75rem"
              mt="2rem"
              maxW="600px"
              mx="auto"
            >
              <Flex
                flexDirection="column"
                align="center"
                textAlign="center"
                gap="0.6rem"
              >
                <Heading fontSize="1rem">Ooops...</Heading>

                <Text>
                  Este conteúdo não está disponível para visualização.
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
