import React, { createContext, ReactNode } from 'react';
import { PaginationContextData } from './types';

export const PaginationContext = createContext({} as PaginationContextData);

export function PaginationProvider({ children }: { children: ReactNode }) {
  const limit = 10;

  function handleChangeCurrentPage(
    page: number,
    setPage: React.Dispatch<React.SetStateAction<number>>,
  ) {
    setPage(page);
  }

  return (
    <PaginationContext.Provider value={{ handleChangeCurrentPage, limit }}>
      {children}
    </PaginationContext.Provider>
  );
}
