import { DrawerBody, DrawerHeader } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface SectionProps {
  title: string;
  children: ReactNode;
}

export function Section({ title, children }: SectionProps) {
  return (
    <>
      <DrawerHeader color="gray.700">{title}</DrawerHeader>
      <DrawerBody>{children}</DrawerBody>
    </>
  );
}
