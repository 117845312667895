import { usePublicEventStore } from '@store/public-event';
import {
  Box,
  Flex,
  Image as ChakraImage,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { CustomArrow } from '../CustomArrow';

import { useDetailEventStore } from '@/store/detail-event';

import { useKeenSlider } from 'keen-slider/react';
import { MutationPlugin, ResizePlugin } from '@/lib/keen-slider';

export function FullScreenModal() {
  const images = useDetailEventStore((state) => state.event.images);

  const {
    highlightImageIndex,
    onChangeCurrentSlide,
    onChangeLoaded,
    isLoaded,
    modal,
    onToggleModal,
  } = usePublicEventStore((state) => state);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: highlightImageIndex,
      slides: {
        perView: 1,
      },
      loop: true,
      slideChanged(slider) {
        onChangeCurrentSlide(slider.track.details.rel);
      },
      created() {
        onChangeLoaded(true);
      },
      updated(s) {
        s.moveToIdx(highlightImageIndex);
      },
    },
    [ResizePlugin, MutationPlugin],
  );

  return (
    <Modal
      size="full"
      isOpen={modal.isOpen}
      onClose={() => onToggleModal(false)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent background="rgba(0, 0, 0, 0.1);">
        <ModalHeader>
          <ModalCloseButton
            bg="gray.100"
            rounded="full"
            w="2.25rem"
            height="2.25rem"
            color="gray.900"
            top={{ base: '2rem', sm: '5rem' }}
            right={{ base: '2rem', md: '5rem' }}
          />
        </ModalHeader>

        <Flex
          w={{ base: '100%', sm: '28.125rem', md: '31.25rem' }}
          alignItems="center"
          margin="auto"
        >
          {isLoaded && instanceRef.current && (
            <CustomArrow
              size="xl"
              direction="left"
              onClick={() => instanceRef.current?.prev()}
              position="absolute"
              left={{ base: '1.5rem', md: '100px' }}
            />
          )}

          <Box ref={sliderRef} className="keen-slider" h="100%">
            {images.map((image, index) => {
              return (
                <Box
                  key={image.url}
                  className={`keen-slider__slide number-slide${
                    (index % 5) + 1
                  } `}
                  px="3.375rem"
                  width="31.25rem"
                  height="31.25rem"
                >
                  <ChakraImage
                    w="100%"
                    h="100%"
                    src={image.url}
                    alt={image.url}
                    objectFit="contain"
                  />
                </Box>
              );
            })}
          </Box>
          {isLoaded && instanceRef.current && (
            <CustomArrow
              size="xl"
              direction="right"
              onClick={() => instanceRef.current?.next()}
              position="absolute"
              right={{ base: '1.5rem', md: '100px' }}
            />
          )}
        </Flex>
      </ModalContent>
    </Modal>
  );
}
