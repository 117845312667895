import { Flex, Text } from '@chakra-ui/react';

interface DateTimeDisplayProps {
  dateTime: {
    date: string;
    time: string;
  };
}

export function DateTimeDisplay({ dateTime }: DateTimeDisplayProps) {
  const { date, time } = dateTime;

  return (
    <Flex
      direction={{ base: 'row', md: 'column' }}
      minW="6.25rem"
      align={{ base: 'start', md: 'end' }}
      gap={{ base: '0.25rem', lg: 0 }}
      mt={{ base: '0.75rem', md: 0 }}
    >
      <Text
        textTransform="capitalize"
        color="gray.900"
        fontWeight="500"
        fontSize={{
          base: '0.875rem',
          '2xl': '1rem',
        }}
      >
        {date}
      </Text>
      <Text
        color="gray.700"
        fontWeight="400"
        fontSize={{
          base: '0.875rem',
          '2xl': '1rem',
        }}
      >
        {time}
      </Text>
    </Flex>
  );
}
