import { createIcon } from '@chakra-ui/icons';

export const Calendar = createIcon({
  displayName: 'Calendar',
  viewBox: '0 0 24 27',
  path: (
    <path
      d="M15.3333 21.3817C14.4 21.3817 13.6111 21.0595 12.9667 20.4151C12.3222 19.7706 12 18.9817 12 18.0484C12 17.1151 12.3222 16.3262 12.9667 15.6817C13.6111 15.0373 14.4 14.7151 15.3333 14.7151C16.2667 14.7151 17.0556 15.0373 17.7 15.6817C18.3444 16.3262 18.6667 17.1151 18.6667 18.0484C18.6667 18.9817 18.3444 19.7706 17.7 20.4151C17.0556 21.0595 16.2667 21.3817 15.3333 21.3817ZM2.66667 26.7151C1.93333 26.7151 1.30533 26.4542 0.782667 25.9324C0.260889 25.4097 0 24.7817 0 24.0484V5.38173C0 4.6484 0.260889 4.02084 0.782667 3.49907C1.30533 2.9764 1.93333 2.71507 2.66667 2.71507H4V0.0484009H6.66667V2.71507H17.3333V0.0484009H20V2.71507H21.3333C22.0667 2.71507 22.6947 2.9764 23.2173 3.49907C23.7391 4.02084 24 4.6484 24 5.38173V24.0484C24 24.7817 23.7391 25.4097 23.2173 25.9324C22.6947 26.4542 22.0667 26.7151 21.3333 26.7151H2.66667ZM2.66667 24.0484H21.3333V10.7151H2.66667V24.0484Z"
      fill="#3F7EF8"
    />
  ),
});
