import { useContext } from 'react';
import { PaginationContext } from '@/contexts/pagination';

export function usePagination() {
  const context = useContext(PaginationContext);

  if (!context) {
    throw new Error('usePagination must be used within an PaginationProvider');
  }

  return context;
}
