import { StateCreator } from 'zustand';

import {
  AboutEventState,
  AboutEventActions,
} from '@store/create-event/@types/about-event';
import { initialAboutEvent } from '@store/create-event/initial-state/about-event';

export const aboutEventSlice: StateCreator<
  AboutEventState & AboutEventActions
> = (set) => ({
  ...initialAboutEvent,
  onChangeSelectedCompany: (company) => {
    set({ selectedCompany: company });
  },
  onChangeSelectedCategory: (category) => {
    set({ selectedCategory: category });
  },
  onChangeSelectedRepairTeam: (team) => {
    set({ selectedRepairTeam: team });
  },
  onChangeCategoryIndex: (index) => {
    set({ categoryIndex: index });
  },
  onChangeCompanyIndex: (index) => {
    set({ companyIdIndex: index });
  },
  onChangeRepairTeamIndex: (index) => {
    set({ repairTeamIndex: index });
  },
  onResetAboutEvent: () => {
    set(initialAboutEvent);
  },
});
