import { useFormContext } from 'react-hook-form';

import { GroupRadio } from '@/components/GroupRadio';
import { SubmitNewEvent } from './types';
import { useCreateEventStore } from '@store/create-event';

type SubmitCategory = Pick<SubmitNewEvent, 'categoryId'>;

export function CategoriesRadio() {
  const {
    register,
    formState: { errors },
  } = useFormContext<SubmitCategory>();

  const {
    categories,
    categoryIndex,
    onChangeSelectedCategory,
    onChangeCategoryIndex,
  } = useCreateEventStore((state) => state);

  return (
    <GroupRadio
      title="Categoria"
      description="Escolha a categoria em que este incidente se encontra"
      value={categoryIndex}
      items={categories}
      type="create"
      isRequired
      error={errors.categoryId}
      onChangeValue={onChangeCategoryIndex}
      onSelect={onChangeSelectedCategory}
      register={register}
      registerName="categoryId"
    />
  );
}
