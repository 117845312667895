import { Icon, Link, Text } from '@chakra-ui/react';
import { AiFillEye } from 'react-icons/ai';

import { Link as ReachLink } from 'react-router-dom';

interface LinkShowEventData {
  statusEvent: 'opened' | 'resolved';
  eventData: {
    id: string;
  };
}

export function LinkShowEvent({ statusEvent, eventData }: LinkShowEventData) {
  if (statusEvent === 'opened') {
    return (
      <Link
        as={ReachLink}
        w="9.07rem"
        h="2.32rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="blue.500"
        color="white"
        rounded=".25rem"
        _hover={{ textDecoration: 'none' }}
        to={`/update/event/${eventData.id}`}
        state={{ idEvent: eventData.id }}
        mt={{ base: '2.6875rem', lg: 'initial' }}
      >
        Atualizar evento
      </Link>
    );
  }

  return (
    <Link
      as={ReachLink}
      display="flex"
      alignItems="center"
      justifyContent="center"
      rounded=".25rem"
      _hover={{ textDecoration: 'none' }}
      to={`/event/details/${eventData.id}`}
      state={{ idEvent: eventData.id }}
      w={{ base: '9.07rem', lg: 'initial' }}
      h={{ base: '2.32rem', lg: 'initial' }}
      bg={{ base: 'blue.500', lg: 'initial' }}
      color={{ base: 'white', lg: '#5F636A' }}
      mt={{ base: '2.6875rem', lg: 'initial' }}
    >
      <Icon
        as={AiFillEye}
        fontSize="1.5rem"
        display={{ base: 'none', lg: 'flex' }}
      />
      <Text display={{ base: 'flex', lg: 'none' }}>Ver Detalhes</Text>
    </Link>
  );
}
