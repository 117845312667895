import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

export interface HistoryDescription {
  historyDescription: string;
}

export function DescriptionArea() {
  const {
    register,
    formState: { errors },
  } = useFormContext<HistoryDescription>();

  return (
    <FormControl isInvalid={!!errors.historyDescription} mt="1.9375rem">
      <FormLabel
        color="gray.700"
        fontSize="0.875rem"
        fontWeight="500"
        htmlFor="historyDescription"
      >
        Descrição
      </FormLabel>
      <Textarea
        placeholder="Faça uma breve descrição."
        bg="#FAFAFA"
        _placeholder={{ color: '#D2D2D2' }}
        focusBorderColor="none"
        id="historyDescription"
        {...register('historyDescription')}
      />
      {!!errors.historyDescription && (
        <FormErrorMessage mt="0">
          {errors.historyDescription?.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
