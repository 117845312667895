import { Flex, Stack, TabPanel } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';

import { HeadingOne } from '@/components/Heading';
import { Button } from '@/components/Button';
import { useFormCreateEvent } from '@/hooks/useFormCreateEvent';

import { CategoriesRadio } from './CategoriesRadio';
import { CompaniesRadio } from './CompaniesRadio';
import { DescriptionTextArea } from './DescriptionTextArea';
import { NameInput } from './NameInput';
import { RepairTeamsRadio } from './RepairTeamsRadio';
import { useCreateEventStore } from '@store/create-event';

export function AboutEvent() {
  const { newEventForm, isDisabledToGoHistoric } = useFormCreateEvent();
  const onGoNextStep = useCreateEventStore((state) => state.onGoNextStep);

  return (
    <TabPanel p="0" pb="2rem">
      <FormProvider {...newEventForm}>
        <Flex h="100%" flexDir={{ base: 'column-reverse', lg: 'column' }}>
          <Flex justify="space-between" w="100%" mb="2.5rem">
            <HeadingOne
              display={{ base: 'none', lg: 'flex' }}
              text="Criar novo evento"
            />

            <Button
              isDisabled={isDisabledToGoHistoric}
              onClick={() => onGoNextStep(1)}
            >
              Próximo
            </Button>
          </Flex>

          <Stack spacing="1.625rem">
            <NameInput />

            <DescriptionTextArea />

            <CategoriesRadio />

            <CompaniesRadio />

            <RepairTeamsRadio />
          </Stack>
        </Flex>
      </FormProvider>
    </TabPanel>
  );
}
