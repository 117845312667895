import { Heading, HeadingProps } from '@chakra-ui/react';

interface HeadingOneProps extends HeadingProps {
  text: string;
}

export function HeadingOne({ text, ...rest }: HeadingOneProps) {
  return (
    <Heading
      as="h1"
      color="gray.700"
      fontWeight="medium"
      fontSize={{ base: '1.3rem', md: '3xl' }}
      {...rest}
    >
      {text}
    </Heading>
  );
}
