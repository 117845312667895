import { destroyCookie } from 'nookies';

export function signOut() {
  destroyCookie(undefined, '@event-monitor-web:token-1.0.1', {
    path: '/',
  });
  destroyCookie(undefined, '@event-monitor-web:refreshToken-1.0.1', {
    path: '/',
  });
  destroyCookie(undefined, '@event-monitor-web:user-1.0.1', {
    path: '/',
  });

  window.location.href = '/';
}
