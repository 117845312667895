import { Box, Flex, Heading, Image, Link, Stack, Text } from '@chakra-ui/react';

import logoImg from '@/assets/images/logo.svg';

const urlSSO = `${import.meta.env.VITE_BASE_URL_SSO}/session?origin=${
  import.meta.env.VITE_DOMAIN
}&client_id=${import.meta.env.VITE_CLIENT_ID}`;

export function SignIn() {
  return (
    <Flex
      align="center"
      bg="blue.900"
      h="100vh"
      justify="center"
      px={{ base: '1rem', lg: '0' }}
    >
      <Box
        bg="white"
        w={{ base: '34.33rem' }}
        rounded="0.625rem"
        py="4rem"
        px={{ base: '2rem', sm: '4rem' }}
      >
        <Flex justify="space-between" w="100%" align="center">
          <Image
            src={logoImg}
            alt="Logo Image"
            w={{ base: '7rem', sm: '9.6rem' }}
            h="4.31rem"
          />

          <Heading fontWeight="normal" fontSize={{ base: 'md', sm: 'xl' }}>
            Monitoramento
            <Text color="blue.800" fontWeight="bold">
              de Eventos
            </Text>
          </Heading>
        </Flex>

        <Box w="100%" mt={{ base: '2rem', sm: '4rem' }}>
          <Stack spacing="1.375rem" align="center" mt="2.5rem">
            <Link
              href={urlSSO}
              display="flex"
              alignItems="center"
              justifyContent="center"
              h="4.07rem"
              w="100%"
              bg="blue.800"
              color="white"
              border="1px solid #E5E7EA"
              fontWeight="normal"
              fontSize="xl"
              gap="0.4rem"
              rounded="0.625rem"
              transition="0.5s"
              _hover={{ backgroundColor: 'blue.700' }}
              style={{ textDecoration: 'none' }}
            >
              Fazer login
            </Link>
          </Stack>
        </Box>
      </Box>
    </Flex>
  );
}
