import {
  Divider,
  Flex,
  Link,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import { Link as ReachLink } from 'react-router-dom';
import { useDetailEventStore } from '@/store/detail-event';

export function Tickets() {
  const event = useDetailEventStore((state) => state.event);

  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
      lg: false,
    },
    {
      ssr: false,
    },
  );

  return (
    <Flex
      w={{ md: '100%', xl: '100%' }}
      borderRadius="0.75rem"
      bg="white"
      h={{
        sm: 'unset',
        lg: 'unset',
      }}
      p={{ base: '1.5rem 1.625rem', lg: '1.375rem 2rem' }}
      flexDirection="column"
      mb={{ base: 'unset', md: '2rem', lg: '2rem' }}
    >
      <Flex w="100%" justifyContent="space-between">
        <Text
          as="span"
          color="gray.900"
          fontWeight={{ base: '700', lg: '500' }}
        >
          Tickets
        </Text>

        <Link
          as={ReachLink}
          to={`/ticket/${event.id}`}
          state={{ idEvent: event.id }}
          color="blue.500"
        >
          Ver mais
        </Link>
      </Flex>

      {isMobile ? (
        <Text as="span" mt="0.9375rem">
          Para ver os detalhes e a quantidade de tickets e protocolos clique em
          <Text as="b" ml="0.2rem">
            Ver mais.
          </Text>
        </Text>
      ) : (
        <>
          <Flex mt="1rem" mb="1.875rem" direction="column">
            <Text fontSize="2.5rem" color="blue.500" fontWeight="600">
              {event.stats.ticket_count}
            </Text>
            {event.stats.ticket_count === 1 ? (
              <Text>atendimento solicitado pelo Blip</Text>
            ) : (
              <Text>atendimentos solicitados pelo Blip</Text>
            )}
          </Flex>
          <Divider />
          <Flex mt="1rem" direction="column">
            <Text fontSize="2.5rem" color="blue.500" fontWeight="600">
              {event.stats.protocol_count}
            </Text>
            {event.stats.protocol_count === 1 ? (
              <Text>solicitou protocolo</Text>
            ) : (
              <Text>solicitaram protocolo</Text>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
}
