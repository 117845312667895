import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { memo } from 'react';

interface Protocol {
  protocol_cnt_id: string;
}

interface EventTicketCardMobileData {
  data: {
    created_at: string;
    hit_rule: string;
    id: string;
    customer: {
      codigo_cliente: string;
      personal_info: {
        name: string;
      };
      pessoa_id: string;
    };
    platform: string;
    protocols: Protocol[];
  };
}

export const EventTicketCardMobile = memo(function EventTicketCardMobile({
  data,
}: EventTicketCardMobileData) {
  return (
    <Box
      bg="white"
      rounded="0.5rem"
      w="100%"
      overflow="hidden"
      boxShadow="0rem 0.125rem 0.625rem rgba(0, 0, 0, 0.08)"
    >
      <Flex
        flexDirection="column"
        p="1.06rem 1.18rem 1.375rem"
        color="gray.400"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Heading fontSize="1rem" fontWeight="500" color="gray.700">
            {data.customer.personal_info.name}
          </Heading>

          <Text fontSize="0.875rem">{data.customer.codigo_cliente}</Text>
        </Flex>

        <Text mt="1.125rem" fontSize="0.875rem" color="gray.700">
          Plataforma:{' '}
          <Text as="span" color="gray.400">
            {data.platform}
          </Text>
        </Text>

        <Text mt="1.125rem" fontSize="0.875rem" color="gray.700">
          Regra:{' '}
          <Text as="span" color="gray.400">
            {data.hit_rule}
          </Text>
        </Text>

        <Text mt="1.125rem" fontSize="0.875rem" color="gray.700">
          Protocolo:{' '}
          <Text as="span" color="gray.400">
            {data.protocols.length <= 0
              ? 'Sem Protocolo'
              : data.protocols[0].protocol_cnt_id}
          </Text>
        </Text>
      </Flex>

      <Box bg="#F3F7F9" p="1rem 1.18rem">
        <Text fontSize="0.875rem">Ticket criado em {data.created_at}</Text>
      </Box>
    </Box>
  );
});
