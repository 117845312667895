import { StateCreator } from 'zustand';

import {
  NewEventState,
  NewEventActions,
} from '@store/create-event/@types/save-new-event';
import { initialNewEventState } from '@store/create-event/initial-state/save-new-event';

export const saveNewEventSlice: StateCreator<
  NewEventState & NewEventActions
> = (set) => ({
  ...initialNewEventState,
  onSaveNewEvent: (event) => {
    set({ newEvent: event });
  },
});
