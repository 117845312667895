import { Flex } from '@chakra-ui/react';
import { NameEventSearch } from '../Inputs/NameEventSearch';
import { DrawerFilter } from './DrawerFilter';

interface FilterMobileProps {
  refetch: () => void;
}

export function FilterMobile({ refetch }: FilterMobileProps) {
  return (
    <Flex alignItems="center" mb="1.25rem" gap="1rem" justify="space-between">
      <NameEventSearch refetch={refetch} />
      <DrawerFilter />
    </Flex>
  );
}
