import { FormLabel, Text, Textarea } from '@chakra-ui/react';

import { useUpdateEventStore } from '@/store/update-event';

export function EventDescriptionInput() {
  const { description, onWriteDescription } = useUpdateEventStore(
    (state) => state,
  );

  return (
    <FormLabel w="100%">
      <Text color="gray.700" fontWeight="500">
        Descrição
      </Text>

      <Textarea
        value={description}
        fontSize="1rem"
        placeholder={description}
        _placeholder={{ color: 'gray.700' }}
        bg="white"
        borderColor="gray.200"
        focusBorderColor="none"
        p="1rem"
        _focusVisible={{ padding: '1rem', border: '0.0625rem solid #E5E7EA' }}
        onChange={(e) => onWriteDescription(e.target.value)}
      />
    </FormLabel>
  );
}
