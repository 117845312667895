import { FormLabel, Input, Text, InputProps } from '@chakra-ui/react';

export function InputFileAddImage({ ...rest }: InputProps) {
  return (
    <>
      <FormLabel
        aria-label="label of input file"
        htmlFor="image[]"
        color="gray.700"
        bg="#DDE9FF"
        w="9.937rem"
        h="2.875rem"
        _hover={{ cursor: 'pointer' }}
        borderStyle="dashed"
        borderWidth="0.0625rem"
        borderColor="blue.500"
        alignItems="center"
        justifyContent="center"
        display="flex"
        rounded="0.25rem"
      >
        <Text color="blue.500">Adicionar imagem</Text>
      </FormLabel>
      <Input
        aria-label="input add image"
        multiple
        type="file"
        id="image[]"
        display="none"
        accept="image/jpeg, image/pjpeg, image/png, image/gif"
        {...rest}
      />
    </>
  );
}
