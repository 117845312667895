import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import { IoArrowBackOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

export function ButtonGoBack() {
  const back = useNavigate();
  const returnPreviousPage = -1;

  return (
    <Flex align="center" mb="2.25rem">
      <Button
        p="0"
        h="100%"
        onClick={() => back(returnPreviousPage)}
        bg="none"
        _hover={{}}
      >
        <Icon as={IoArrowBackOutline} />
        <Text ml="0.75rem">Voltar</Text>
      </Button>
    </Flex>
  );
}
