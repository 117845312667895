import { Circle, Image, SquareProps } from '@chakra-ui/react';

interface AvatarLogoProps extends SquareProps {
  url: string;
  name: string;
  circleSize?: 'sm' | 'md';
}

export function AvatarLogo({
  url,
  name,
  circleSize,
  ...rest
}: AvatarLogoProps) {
  return (
    <Circle
      size={circleSize === 'sm' ? '2rem' : '2.8125rem'}
      bg="white"
      boxShadow="0px 1px 3px 1px rgba(0, 0, 0, 0.2)"
      {...rest}
    >
      <Image
        src={url}
        alt={`${name} logo image`}
        boxSize={circleSize === 'sm' ? '0.9rem' : '1.4375rem'}
        aria-details={`image with size ${circleSize}`}
      />
    </Circle>
  );
}
