import { create } from 'zustand';

import { CombineSlices } from './@types';

import * as slices from './slices';

export const useUpdateEventStore = create<CombineSlices>((...state) => ({
  ...slices.createAboutEventSlice(...state),
  ...slices.createHistorySlice(...state),
  ...slices.createImageSlice(...state),
  ...slices.createStepSlice(...state),
  ...slices.createModalStateControlSlice(...state),
}));
