import { create } from 'zustand';
import {
  ReopenEventState,
  ReopenEventActions,
} from '@/store/reopen-event/types';
import { initialReopenEventState } from '@/store/reopen-event/initial-state';

export const useReopenEventStore = create<
  ReopenEventState & ReopenEventActions
>((set) => ({
  ...initialReopenEventState,
  onToggleModal: (isOpen) => {
    set({
      modal: {
        isOpen: isOpen,
      },
    });
  },
  onSelectHistoryStatus: (event) => {
    if (event.target.value === '') {
      set({
        selectedStatus: {
          id: '',
          name: '',
        },
      });
      return;
    }

    const currentHistoryStatus = JSON.parse(event.target.value);

    set({ selectedStatus: currentHistoryStatus });
  },
  onChangeSelectHistoryStatus: (status) => {
    set({ selectedStatus: status });
  },
  onChangeHistoryDescription: (description) => {
    set(() => ({
      historyDescription: description,
    }));
  },
  onChangeEventReopenedSuccessfully: (isOpen) => {
    set({
      isEventReopenedSuccessfully: isOpen,
    });
  },
  onResetStates: () => {
    set(initialReopenEventState);
  },
}));
