import { Box, Button, Flex, TabPanel, Text } from '@chakra-ui/react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import { City } from './City';
import { Neighborhood } from './Neighborhood';
import { State } from './State';
import { HeadingOne, HeadingTwo } from '@/components/Heading';
import { useFormCreateEvent } from '@/hooks/useFormCreateEvent';

import { useSubmitNewEvent } from '@/hooks/useSubmitNewEvent';
import { useCreateEventStore } from '@store/create-event';
import { useCanSubmitNewEvent } from '@/hooks/useCanSubmitNewEvent';
import { DeviceCnt } from './DeviceCnt';

export interface SubmitDataProps {
  name: string;
  description: string;
  historyDescription: string;
  companyId: string;
  categoryId: string;
  historyEvent: string;
  repairTeamId: string;
}

export function LocationEquipment() {
  const { isDisabledToSubmit } = useCanSubmitNewEvent();

  const onFinishedStepsExceptImageStep = useCreateEventStore(
    (state) => state.onFinishedStepsExceptImageStep,
  );
  const { newEventForm, handleSubmit } = useFormCreateEvent();

  const { onSubmitNewEvent } = useSubmitNewEvent();

  const onSubmitEventData: SubmitHandler<SubmitDataProps> = (data) => {
    onSubmitNewEvent(data);
    onFinishedStepsExceptImageStep();
  };

  const onError = () => {
    toast.info(
      'Verifique se você não esqueceu de preencher algum campo nas seções anteriores',
    );
  };

  return (
    <TabPanel p="0" pb="2rem">
      <FormProvider {...newEventForm}>
        <form onSubmit={handleSubmit(onSubmitEventData, onError)}>
          <Flex h="100%" flexDir={{ base: 'column-reverse', lg: 'column' }}>
            <Flex justify="space-between" w="100%" mb="2.5rem">
              <HeadingOne
                display={{ base: 'none', lg: 'flex' }}
                text="Criar novo evento"
              />

              <Button
                bg="blue.500"
                color="white"
                fontSize="1rem"
                fontWeight="400"
                mt={{ base: '3rem', lg: '0' }}
                w={{ base: '100%', lg: '6.8125rem' }}
                type="submit"
                _hover={{ filter: 'brightness(0.9)' }}
                isDisabled={isDisabledToSubmit}
              >
                Criar
              </Button>
            </Flex>

            <Box>
              <HeadingTwo text="Locais e equipamentos" isRequired />
              <Text mt="0.375rem" color="#959595" fontSize="1rem">
                Informe quais os locais ou equipamentos estão sendo afetados por
                este evento.
              </Text>

              <Flex
                bg="white"
                mt="1.9375rem"
                px="1.125rem"
                flexDirection="column"
                border="1px solid #E5E7EA"
                rounded="0.5rem"
              >
                <State />

                <City />

                <Neighborhood />

                <DeviceCnt />
              </Flex>
            </Box>
          </Flex>
        </form>
      </FormProvider>
    </TabPanel>
  );
}
