import {
  getTodayWithHourAndMinutes,
  subtractDaysFromToday,
} from '@/utils/formatDate';

import { TicketEventState } from '@store/ticket-event/types';

const today = getTodayWithHourAndMinutes();
const getSevenDaysAgoDate = subtractDaysFromToday(7);

export const initialTicketEventState: TicketEventState = {
  selectCompany: '',
  inputSearch: '',
  intervalDates: {
    initialDate: getSevenDaysAgoDate,
    endDate: today,
  },
  currentPage: 1,
  filterParams: {
    filterCompany: '',
    filterSearch: '',
    filterIntervalDates: {
      initialDate: getSevenDaysAgoDate,
      endDate: today,
    },
  },
  tickets: [],
};
