import { StateCreator } from 'zustand';
import { StepState, StepActions } from '@store/create-event/@types/step';
import { initialStepState } from '@store/create-event/initial-state/step';

export const stepSlice: StateCreator<StepState & StepActions> = (set, get) => ({
  ...initialStepState,
  onChangeTab: (index) => {
    set({ tabIndex: index });
  },
  onGoNextStep: (index) => {
    const currentSteps = get().steps;
    const previousIndex = index - 1;

    const previousStep = currentSteps.find((item) => {
      return previousIndex === item.id;
    });

    currentSteps.splice(previousIndex, 1, {
      id: previousStep.id,
      name: previousStep.name,
      isCompleted: true,
      isFinished: false,
    });

    set({
      steps: currentSteps,
      tabIndex: index,
      currentTabIndex: index,
    });
  },
  onFinishedStepsExceptImageStep: () => {
    const currentSteps = get().steps;
    const stepImage = 3;

    const completedStepsExceptImages = currentSteps.map((step) => {
      if (step.name !== 'Imagens') {
        return {
          id: step.id,
          name: step.name,
          isCompleted: true,
          isFinished: true,
        };
      }

      return {
        id: step.id,
        name: step.name,
        isCompleted: false,
        isFinished: false,
      };
    });

    set({
      steps: completedStepsExceptImages,
      currentTabIndex: stepImage,
      tabIndex: stepImage,
    });
  },
  onFinalizeEventCreation: () => {
    set({ hasEventWasCreated: true });
  },
  onFinalizeSteps: () => {
    const currentSteps = get().steps;

    const allStepsFinished = currentSteps.map((step) => {
      return {
        id: step.id,
        name: step.name,
        isCompleted: true,
        isFinished: true,
      };
    });

    set({
      steps: allStepsFinished,
      hasEventWasCreated: true,
    });
  },
  onResetSteps: () => {
    set({
      tabIndex: 0,
      currentTabIndex: 0,
      hasEventWasCreated: false,
    });
  },
});
