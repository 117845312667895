import { ForCompanyParams, ForDayParams } from './types';

export function calculateTotalEventsForDay({ day, company }: ForDayParams) {
  return day.events.reduce((sum, event) => {
    return event.company === company ? sum + event.total : sum;
  }, 0);
}

export function calculateTotalEventsForCompany({
  lastThirtyDays,
  company,
}: ForCompanyParams) {
  return {
    name: company,
    data: lastThirtyDays.map((day) =>
      calculateTotalEventsForDay({ day, company }),
    ),
  };
}
