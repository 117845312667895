import { useGetStats } from '@/hooks/queries/get-stats';
import { GraphicByDay } from './GraphicByDay';
import { Skeleton } from './Skeleton';
import { TotalEventsPerCompany } from './TotalEventsPerCompany';

export function Stats() {
  const { isLoading } = useGetStats();

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <>
      <TotalEventsPerCompany />

      <GraphicByDay />
    </>
  );
}
