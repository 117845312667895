import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { parseCookies } from 'nookies';

import { apiReactQuery } from '@/lib/axios/apiReactQuery';
import { queryClient } from '@/services/queryClient';
import { useAuth } from './useAuth';
import { apiAxios } from '@/lib/axios/apiAxios';
import { useCreateEventStore } from '@store/create-event';

export interface OnSubmitNewEventParams {
  name: string;
  description: string;
  historyDescription: string;
  categoryId: string;
  companyId: string;
  historyEvent: string;
  repairTeamId: string;
}

export function useSubmitNewEvent() {
  const { user } = useAuth();

  const {
    newEvent,
    states,
    cities,
    newImages,
    onSaveNewEvent,
    onResetAboutEvent,
    selectedCategory,
    onResetState,
    onResetCity,
    neighborhoods,
    onResetNeighborhood,
    onResetImages,
    deviceCnt,
    onResetDeviceCnt,
  } = useCreateEventStore((state) => state);

  const createNewEvent = useMutation({
    mutationFn: async (values: OnSubmitNewEventParams) => {
      const parsedStates = states.map((state) => {
        return {
          id: state.id,
        };
      });

      const parsedCities = cities.map((city) => {
        return {
          id: city.id,
        };
      });

      const parsedNeighborhoods = neighborhoods.map((neighborhood) => {
        return {
          name: neighborhood.name,
          city_id: neighborhood.city_id,
        };
      });

      const getValueRepairTeamIfCategoryDegradation =
        selectedCategory.name === 'Indisponibilidade'
          ? values.repairTeamId
          : '';

      const parsedHistory = JSON.parse(values.historyEvent);

      const { '@event-monitor-web:token-1.0.1': token } = parseCookies();

      const response = await apiReactQuery.post(
        '/event',
        {
          name: values.name,
          description: values.description,
          email: user.email,
          company_id: values.companyId,
          states: parsedStates,
          cities: parsedCities,
          neighborhoods: parsedNeighborhoods,
          device_cnt: deviceCnt,
          devices: [],
          device_groups: [],
          history_events: {
            description: values.historyDescription,
            status_id: parsedHistory.id,
          },
          repair_team_id: getValueRepairTeamIfCategoryDegradation,
          category_id: values.categoryId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['latest-event'],
        exact: true,
      });

      queryClient.invalidateQueries({
        queryKey: ['event-stats'],
        exact: true,
      });
    },
  });

  async function onSubmitNewEvent(values: OnSubmitNewEventParams) {
    try {
      const newEventCreatedResponse = await createNewEvent.mutateAsync(values);

      onSaveNewEvent(newEventCreatedResponse.event);

      toast.success('Evento criado com sucesso!');
    } catch (error) {
      const { message } = error as Error;

      toast.error(message);
    }
  }

  async function onSubmitImages() {
    try {
      const dataNewForm = new FormData();

      newImages.forEach((image) => {
        dataNewForm.append('media', image);
      });

      const { '@event-monitor-web:token-1.0.1': token } = parseCookies();

      const response = await apiAxios.post(
        `event/media/upload?event_id=${newEvent.id}&user_id=${newEvent.user_id}`,
        dataNewForm,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      toast.success(`${response.data.msg}`);
    } catch (error) {
      const { message } = error as Error;

      toast.error(message);
    }
  }

  function resetStore() {
    onResetState();
    onResetCity();
    onResetNeighborhood();
    onResetImages();
    onResetAboutEvent();
    onResetDeviceCnt();
  }

  return {
    onSubmitNewEvent,
    onSubmitImages,
    resetStore,
  };
}
