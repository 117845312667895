import {
  Box,
  Center,
  Flex,
  Spinner,
  Skeleton as ChakraSkeleton,
  Image,
  Grid,
} from '@chakra-ui/react';

export function Skeleton() {
  return (
    <>
      <Flex
        h={{ base: '29rem', md: '32rem', lg: '17.0625rem' }}
        bg="white"
        rounded="1.25rem"
        justifyContent="space-between"
        alignItems="center"
        p={{
          base: '1.5rem',
          md: '1rem',
          lg: '0 2.0625rem',
        }}
        flexDirection={{ base: 'column', lg: 'row' }}
        data-testid="skeleton-stats"
      >
        <Flex direction="column" justifyContent="center">
          <ChakraSkeleton w="5rem" h="1rem" mb="0.9375rem" />

          <ChakraSkeleton w="12rem" h="0.7rem" />

          <ChakraSkeleton w="12rem" h="0.7rem" mt="0.5rem" />
        </Flex>
        <Grid
          templateColumns={{
            base: 'repeat(2, 8rem)',
            md: 'repeat(2, 9rem)',
            lg: 'repeat(4, 9rem)',
          }}
          templateRows={{
            base: 'repeat(2, 8rem)',
            md: 'repeat(2, 9rem)',
            lg: 'repeat(1, 9rem)',
          }}
          gap={8}
          alignItems="center"
        >
          <ChakraSkeleton rounded="1.25rem" position="relative" w="" h="100%">
            <Flex
              w={{ base: '2rem', md: '2.3rem' }}
              h={{ base: '2rem', md: '2.3rem' }}
              bg="white"
              rounded="100%"
              position="absolute"
              top="-1.25rem"
              left={{ base: '3rem', md: '3.3rem', lg: '3.4375rem' }}
              boxShadow="0rem 0.0625rem 0.375rem rgba(0, 0, 0, 0.2)"
              justifyContent="center"
              alignItems="center"
            >
              <Image w="1.4rem" alt="Logo da Fiber" />
            </Flex>
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
            ></Flex>
          </ChakraSkeleton>
          <ChakraSkeleton rounded="1.25rem" position="relative" w="" h="100%">
            <Flex
              w={{ base: '2rem', md: '2.3rem' }}
              h={{ base: '2rem', md: '2.3rem' }}
              bg="white"
              rounded="100%"
              position="absolute"
              top="-1.25rem"
              left={{ base: '3rem', md: '3.3rem', lg: '3.4375rem' }}
              boxShadow="0rem 0.0625rem 0.375rem rgba(0, 0, 0, 0.2)"
              justifyContent="center"
              alignItems="center"
            ></Flex>
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
            ></Flex>
          </ChakraSkeleton>
          <ChakraSkeleton rounded="1.25rem" position="relative" w="" h="100%">
            <Flex
              w={{ base: '2rem', md: '2.3rem' }}
              h={{ base: '2rem', md: '2.3rem' }}
              bg="white"
              rounded="100%"
              position="absolute"
              top="-1.25rem"
              left={{ base: '3rem', md: '3.3rem', lg: '3.4375rem' }}
              boxShadow="0rem 0.0625rem 0.375rem rgba(0, 0, 0, 0.2)"
              justifyContent="center"
              alignItems="center"
            >
              <Image w="1.4rem" alt="Logo da Fiber" />
            </Flex>
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
            ></Flex>
          </ChakraSkeleton>
          <ChakraSkeleton rounded="1.25rem" position="relative" w="" h="100%">
            <Flex
              w={{ base: '2rem', md: '2.3rem' }}
              h={{ base: '2rem', md: '2.3rem' }}
              bg="white"
              rounded="100%"
              position="absolute"
              top="-1.25rem"
              left={{ base: '3rem', md: '3.3rem', lg: '3.4375rem' }}
              boxShadow="0rem 0.0625rem 0.375rem rgba(0, 0, 0, 0.2)"
              justifyContent="center"
              alignItems="center"
            ></Flex>
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
            ></Flex>
          </ChakraSkeleton>
        </Grid>
      </Flex>

      <Box mt="5rem" mb="12">
        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="orange.500"
            size="xl"
            my="2rem"
          />
        </Center>
      </Box>
    </>
  );
}
