import { StepState } from '@store/create-event/@types/step';

const listSteps = [
  {
    id: 0,
    name: 'Sobre o evento',
    isCompleted: false,
    isFinished: false,
  },
  {
    id: 1,
    name: 'Histórias do Evento',
    isCompleted: false,
    isFinished: false,
  },
  {
    id: 2,
    name: 'Locais/Equipamentos',
    isCompleted: false,
    isFinished: false,
  },
  {
    id: 3,
    name: 'Imagens',
    isFinished: false,
    isCompleted: false,
  },
];

export const initialStepState: StepState = {
  steps: listSteps,
  tabIndex: 0,
  currentTabIndex: 0,
  hasEventWasCreated: false,
};
