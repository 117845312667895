import { CgUserList } from 'react-icons/cg';
import { MdEventNote, MdLocationOn, MdImage } from 'react-icons/md';
import { AiOutlineComment } from 'react-icons/ai';
import { StepState } from '../@types/step';

const listSteps = [
  {
    id: 0,
    name: 'Sobre o evento',
    icon: MdEventNote,
  },
  {
    id: 1,
    name: 'Histórias do Evento',
    icon: AiOutlineComment,
  },
  {
    id: 2,
    name: 'Locais/Equipamentos',
    icon: MdLocationOn,
  },
  {
    id: 3,
    name: 'Imagens',
    icon: MdImage,
  },
  {
    id: 4,
    name: 'Tickets',
    icon: CgUserList,
  },
];

export const initialStepState: StepState = {
  steps: listSteps,
  tabIndex: 0,
  currentTabIndex: 0,
};
