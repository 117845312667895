import { usePortalEventStore } from '@store/portal-event';
import { Flex, Input, Stack, Text } from '@chakra-ui/react';

export function SelectIntervalDates() {
  const { intervalDates, saveIntervalDates } = usePortalEventStore((state) => {
    const { intervalDates, saveIntervalDates } = state;

    return { intervalDates, saveIntervalDates };
  });

  return (
    <Stack direction="column" spacing={2}>
      <Flex align="center" gap="0.625rem">
        <Text>de</Text>

        <Input
          type="datetime-local"
          name="date"
          value={intervalDates.initialDate}
          onChange={(e) => saveIntervalDates(e.target.value, 'initial')}
        />
      </Flex>

      <Flex align="center" gap="0.625rem">
        <Text>até</Text>
        <Input
          type="datetime-local"
          value={intervalDates.endDate}
          onChange={(e) => saveIntervalDates(e.target.value, 'end')}
        />
      </Flex>
    </Stack>
  );
}
