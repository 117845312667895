import {
  Box,
  Divider,
  Flex,
  Heading,
  Select,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import { HeadingTwo } from '@/components/Heading';
import { DescriptionArea } from './DescriptionArea';
import { useUpdateEventStore } from '@/store/update-event';
import { SelectableButton } from '@/components/SelectableButton';
import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';

export function CreateHistory() {
  const { selectedStatus, onSelectStatus, onSelectHistoryStatus } =
    useUpdateEventStore((state) => state);

  const { queryData } = useGetEventDetailToUpdate();

  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      ssr: false,
    },
  );

  return (
    <Box as="form">
      <Box mt={{ base: '3rem', md: '2.65rem' }}>
        <HeadingTwo text="Histórico de Evento" />

        <Text mt="0.375rem" color="gray.400" fontSize="1rem">
          Crie uma nova história caso o evento tenha alguma nova informação e
          você precise comunicar o seus usuários.
        </Text>

        <Flex
          border="1px"
          mt="2rem"
          rounded="0.5rem"
          color="gray.200"
          py={{ base: '1rem', md: '1.5rem' }}
          px={{ base: '1rem', md: '2rem' }}
          flexDirection="column"
          borderColor="#E5E7EA"
        >
          <Heading
            color="gray.700"
            fontSize="1rem"
            lineHeight="1.21rem"
            fontWeight="500"
          >
            Criar uma nova história
          </Heading>

          <Divider mt="1rem" mb="1.5rem" />

          <Text
            color="gray.700"
            fontWeight="400"
            fontSize="0.875rem"
            lineHeight="1.25rem"
            mb="0.5rem"
            display={{ base: 'none', md: 'flex' }}
          >
            Selecione o novo status
          </Text>

          {isMobile ? (
            <Flex w="100%" gap="1.125rem">
              <Select
                placeholder="Selecione  o novo status"
                onChange={onSelectHistoryStatus}
                defaultValue={selectedStatus.name}
                color="gray.400"
              >
                {queryData.parameters.history_status.map((status) => {
                  return (
                    <option key={status.id} value={JSON.stringify(status)}>
                      {status.name}
                    </option>
                  );
                })}
              </Select>
            </Flex>
          ) : (
            <Flex gap="1rem">
              {queryData.parameters.history_status.map((status) => {
                return (
                  <SelectableButton
                    key={status.id}
                    name={status.name}
                    comparisonData={status.id}
                    selectedData={selectedStatus.id}
                    onSelect={() => onSelectStatus(status)}
                  />
                );
              })}
            </Flex>
          )}

          <DescriptionArea />
        </Flex>
      </Box>
    </Box>
  );
}
