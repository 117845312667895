import { PortalEventState } from '@store/portal-event/types';
import {
  getTodayWithHourAndMinutes,
  subtractDaysFromToday,
} from '@/utils/formatDate';

const today = getTodayWithHourAndMinutes();
const ninetyDaysAgo = subtractDaysFromToday(90);

export const initialPortalEventState: PortalEventState = {
  nameEvent: '',
  intervalDates: {
    initialDate: ninetyDaysAgo,
    endDate: today,
  },
  selectedCompany: '',
  selectedCategory: '',
  pagination: {
    currentPage: 0,
    from: 0,
    pageCount: 0,
    perPage: 0,
    to: 0,
    total: 0,
  },
  currentPage: 1,
};
