import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface ButtonProps extends ChakraButtonProps {
  children: ReactNode;
}

export function Button({ children, ...props }: ButtonProps) {
  return (
    <ChakraButton
      bg="blue.500"
      color="white"
      fontSize="1rem"
      fontWeight="400"
      mt={{ base: '3rem', lg: '0' }}
      w={{ base: '100%', lg: '6.8125rem' }}
      _hover={{ filter: 'brightness(0.9)' }}
      {...props}
    >
      {children}
    </ChakraButton>
  );
}
