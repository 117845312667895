import {
  Flex,
  Box,
  Heading,
  Image as ChakraImage,
  Button,
} from '@chakra-ui/react';

import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';

import { usePublicEventStore } from '@store/public-event';
import { useState } from 'react';
import { useDetailEventStore } from '@/store/detail-event';
import { MutationPlugin, ResizePlugin } from '@/lib/keen-slider';
import { Arrows } from './Arrow';

export function Images() {
  const images = useDetailEventStore((state) => state.event.images);
  const displayStyles = {
    sm: images.length > 1 ? 'flex' : 'none',
    lg: images.length > 2 ? 'flex' : 'none',
  };

  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
      breakpoints: {
        '(max-width: 1400px )': {
          slides: { perView: 2.2, spacing: 35 },
        },
        '(max-width: 1150px )': {
          slides: { perView: 1.5, spacing: 35 },
        },

        '(max-width: 920px )': {
          slides: { perView: 1, spacing: 35 },
        },
        '(max-width: 500px )': {
          slides: { perView: 1 },
        },
      },
      slides: { perView: 3.2 },
    },
    [ResizePlugin, MutationPlugin],
  );

  const handleOpenFocusImage = usePublicEventStore(
    (state) => state.handleOpenFocusImage,
  );

  return (
    <Flex
      bg="white"
      p={{ base: '35px 26px', lg: '3.125rem 2.5rem' }}
      rounded=".75rem"
    >
      <Box flex="1" w="0">
        <Flex justifyContent="space-between" alignItems="center">
          <Heading
            mt="13.46px"
            fontSize={{ base: '1rem', lg: '1.375rem' }}
            fontWeight="500"
            color="gray.900"
          >
            Imagens
          </Heading>

          <Flex display={displayStyles}>
            <Arrows
              instanceRef={instanceRef}
              isLoaded={loaded}
              currentSlide={currentSlide}
            />
          </Flex>
        </Flex>

        <Box mt="1.375rem" ref={sliderRef} className="keen-slider">
          {images.map((image, index) => {
            return (
              <Box
                key={image.id}
                className={`keen-slider__slide number-slide${(index % 5) + 1} `}
              >
                <Button
                  h={{ base: '18.5625rem', md: '100%', lg: '14.375rem' }}
                  w={{ base: '100%', md: '100%', lg: '14.375rem' }}
                  borderWidth="0.0625rem"
                  borderColor="#E5E7EA"
                  p="0"
                  background="transparent"
                  onClick={() => handleOpenFocusImage(index)}
                >
                  <ChakraImage
                    w="100%"
                    h="100%"
                    src={image.url}
                    alt={image.url}
                    objectFit="contain"
                  />
                </Button>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Flex>
  );
}
