import { Heading, Text } from '@chakra-ui/react';
import { InfoProps } from './types';

export function Info({ name, data }: InfoProps) {
  return (
    <Heading
      as="h3"
      mt="1.125rem"
      fontSize="0.875rem"
      color="gray.700"
      fontWeight={400}
    >
      {name}:{' '}
      <Text as="span" color="gray.400">
        {data}
      </Text>
    </Heading>
  );
}
