import {
  Flex,
  List,
  ListItem,
  Tab,
  TabList as ChakraTabList,
} from '@chakra-ui/react';

import { useUpdateEventStore } from '@/store/update-event';

export function ListStep() {
  const steps = useUpdateEventStore((state) => state.steps);

  return (
    <ChakraTabList display="flex" flexDirection="column" w="15.8125rem">
      <Flex
        w="15.8125rem"
        direction="column"
        pos="fixed"
        h="100%"
        borderRight="1px solid #D9D9D9"
      >
        <List spacing="0.75rem" pt="5.625rem" px="3rem">
          {steps.map((step) => {
            return (
              <ListItem key={step.name}>
                <Tab p="0" _selected={{ color: 'blue.500' }}>
                  {step.name}
                </Tab>
              </ListItem>
            );
          })}
        </List>
      </Flex>
    </ChakraTabList>
  );
}
