import { useRef, RefObject } from 'react';

interface ButtonRefs {
  prev: {
    visible: RefObject<HTMLButtonElement> | null;
    hidden: RefObject<HTMLButtonElement> | null;
  };
  next: {
    visible: RefObject<HTMLButtonElement> | null;
    hidden: RefObject<HTMLButtonElement> | null;
  };
}

export function useButtonNavigation() {
  const buttonRefs: ButtonRefs = {
    prev: {
      visible: useRef<HTMLButtonElement>(null),
      hidden: useRef<HTMLButtonElement>(null),
    },
    next: {
      visible: useRef<HTMLButtonElement>(null),
      hidden: useRef<HTMLButtonElement>(null),
    },
  };

  const handleAction = (direction: 'prev' | 'next'): void => {
    const { hidden } = buttonRefs[direction];
    if (hidden && hidden.current) {
      hidden.current.click();
    }
  };

  return {
    handleAction,
    buttonRefs,
  };
}
