import { Flex, TabPanels, Tabs } from '@chakra-ui/react';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { ListStep } from './Step/ListStep';
import { TimelineStep } from './Step/TimelineStep';
import { AboutEvent } from './AboutEvent';
import { EventHistory } from './EventHistory';
import { LocationEquipment } from './LocationEquipment';
import { ImageEvent } from './ImageEvent';
import { Ticket } from './Ticket';
import { useUpdateEventStore } from '@/store/update-event';
import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';

export function UpdateEvent() {
  const { tabIndex, onChangeTab } = useUpdateEventStore((state) => state);

  const { isLoading } = useGetEventDetailToUpdate();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Flex>
      <Tabs
        index={tabIndex}
        onChange={onChangeTab}
        display={{ base: 'none', lg: 'flex' }}
        pos="relative"
        variant="unstyled"
      >
        <ListStep />
      </Tabs>
      <Flex>
        <Tabs
          index={tabIndex}
          bg="white"
          w={{ base: '100%', lg: 'calc(100% - 15.8125rem - 6.5rem)' }}
          display="flex"
          position="fixed"
          overflowY="scroll"
          h="100%"
          py={{ base: '0', lg: '5.625rem' }}
          px={{ base: '2rem', xl: '4rem' }}
          flexDir="column"
        >
          <TimelineStep />

          <TabPanels>
            <AboutEvent />
            <EventHistory />
            <LocationEquipment />
            <ImageEvent />
            <Ticket />
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  );
}
