import React from 'react';
import { Box, Text } from '@chakra-ui/react';

interface TotalStatsProps {
  stats: number | string;
  children: React.ReactNode;
}

export function TotalStats({ stats, children }: TotalStatsProps) {
  const isPorcentageStats = typeof stats === 'string';

  return (
    <Box>
      <Text
        as="span"
        fontSize="3.25rem"
        color={isPorcentageStats ? 'blue.100' : 'green.500'}
        fontWeight="300"
      >
        {stats}
      </Text>
      <Text color="blue.100" w={{ base: '100%', lg: '80%' }} lineHeight="1rem">
        {children}
      </Text>
    </Box>
  );
}
