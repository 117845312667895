import {
  getTodayWithHourAndMinutes,
  subtractDaysFromToday,
} from '@/utils/formatDate';
import { create } from 'zustand';
import {
  TicketEventState,
  TicketEventActions,
} from '@store/ticket-event/types';
import { initialTicketEventState } from '@store/ticket-event/initial-state';

const today = getTodayWithHourAndMinutes();
const getSevenDaysAgoDate = subtractDaysFromToday(7);

export const useTicketEventStore = create<
  TicketEventState & TicketEventActions
>((set, get) => ({
  ...initialTicketEventState,
  changeCurrentPage: (page) => {
    set(() => ({
      currentPage: page,
    }));
  },
  changeCompany: (name) => {
    set(() => ({
      selectCompany: name,
    }));
  },
  changeInputSearch: (text) => {
    set(() => ({
      inputSearch: text,
    }));
  },
  saveIntervalDates: (date, variant) => {
    const currentInitialDate = get().intervalDates.initialDate;
    const currentEndDate = get().intervalDates.endDate;

    set(() => ({
      intervalDates: {
        initialDate: variant === 'initial' ? date : currentInitialDate,
        endDate: variant === 'initial' ? currentEndDate : date,
      },
    }));
  },
  saveFilterParameters: () => {
    set(() => ({
      filterParams: {
        filterIntervalDates: {
          initialDate: get().intervalDates.initialDate,
          endDate: get().intervalDates.endDate,
        },
        filterCompany: get().selectCompany,
        filterSearch: get().inputSearch,
      },
    }));
  },
  saveTickets: (data) => {
    set(() => ({
      tickets: data,
    }));
  },
  resetIntervalDates: () => {
    set(() => ({
      intervalDates: {
        initialDate: getSevenDaysAgoDate,
        endDate: today,
      },
    }));
  },
}));
