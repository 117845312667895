import { Center, Spinner } from '@chakra-ui/react';

import { TotalByCompany } from './TotalByCompany';
import { OverallTotal } from './OverallTotal';
import { useGetTotalTicketsProtocols } from '@/hooks/queries/get-total-tickets-protocols';

export function TicketsProtocols() {
  const { data, isLoading } = useGetTotalTicketsProtocols();

  if (isLoading) {
    return (
      <Center w="100%" bg="white" h="100vh">
        <Spinner color="blue.500" size="lg" />
      </Center>
    );
  }

  return (
    <>
      <TotalByCompany stats={data.stats} />
      <OverallTotal total={data.total} />
    </>
  );
}
