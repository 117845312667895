import { Button, Flex, HStack } from '@chakra-ui/react';

import { SearchName } from './SearchName';
import { SelectCategory } from './SelectCategory';
import { SelectCompany } from './SelectCompany';
import { SelectIntervalDates } from './SelectIntervalDates';

import { useGetPortalEvents } from '@/hooks/queries/get-portal-events';

export function Desktop() {
  const { refetch } = useGetPortalEvents();

  return (
    <Flex
      p="1.625rem"
      bg="white"
      rounded="0.625rem"
      gap="2.5rem"
      mt="1rem"
      justify="space-between"
    >
      <Flex flexDirection="column" gap="1rem">
        <HStack
          spacing="1rem"
          overflowX="scroll"
          css={{
            '&::-webkit-scrollbar': {
              height: '0.3125rem',
            },
            '&::-webkit-scrollbar-track': {
              width: '0.375rem',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'var(--chakra-colors-gray-300);',
              borderRadius: '0.625rem',
            },
          }}
        >
          <SelectCompany />

          <SearchName />

          <SelectCategory />
        </HStack>

        <Flex>
          <SelectIntervalDates />
        </Flex>
      </Flex>

      <Button
        type="button"
        bg="blue.500"
        color="white"
        _hover={{
          filter: 'brightness(0.9)',
        }}
        onClick={() => refetch()}
      >
        Aplicar
      </Button>
    </Flex>
  );
}
