import { Button, Modal, ModalOverlay } from '@chakra-ui/react';

import { ModalUpdateContent } from './ModalUpdateContent';
import { useReopenEventStore } from '@/store/reopen-event';
import { ModalSuccessContent } from './ModalSuccessContent';
import { useNavigate } from 'react-router-dom';

export function ReopenModal() {
  const { modal, onToggleModal, isEventReopenedSuccessfully, onResetStates } =
    useReopenEventStore((state) => state);

  const navigate = useNavigate();

  function onRedirectToEvent() {
    onResetStates();
    navigate('/opened/events');
  }

  return (
    <>
      <Button
        _hover={{
          background: 'unset',
          textDecoration: 'underline',
          color: '#224B9B',
        }}
        bg="none"
        color="blue.500"
        fontWeight="0.875rem"
        p="0"
        h="auto"
        onClick={() => onToggleModal(true)}
        fontSize={{ base: '.875rem', md: '1rem' }}
      >
        Reabrir evento
      </Button>

      <Modal
        onClose={() =>
          isEventReopenedSuccessfully
            ? onRedirectToEvent()
            : onToggleModal(false)
        }
        isOpen={modal.isOpen}
        size="2xl"
        isCentered
      >
        <ModalOverlay />

        {isEventReopenedSuccessfully ? (
          <ModalSuccessContent />
        ) : (
          <ModalUpdateContent />
        )}
      </Modal>
    </>
  );
}
