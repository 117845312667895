import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { queryClient } from './services/queryClient';
import AppProvider from './contexts';
import { MainRoutes } from './routes';

import 'react-multi-carousel/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppProvider>
          <MainRoutes />
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
            draggable
          />
        </AppProvider>
      </Router>

      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
