import { Flex } from '@chakra-ui/react';

import { ShowTotalEventsByCompany } from './ShowTotalEventsByCompany';
import { ShowTotalEvents } from './ShowTotalEvents';

export function TotalEventsPerCompany() {
  return (
    <Flex
      h={{ base: '29rem', md: '32rem', lg: '17.0625rem' }}
      bg="white"
      rounded="1.25rem"
      justifyContent="space-between"
      alignItems="center"
      p={{
        base: '1.5rem',
        md: '1rem',
        lg: '0 2.0625rem',
      }}
      flexDirection={{ base: 'column', lg: 'row' }}
    >
      <ShowTotalEvents />

      <ShowTotalEventsByCompany />
    </Flex>
  );
}
