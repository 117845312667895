import { Avatar, Box, Flex, Text } from '@chakra-ui/react';

interface HistoryDetailsProps {
  history: {
    description: string;
    users: {
      name: string;
      avatar_url: string;
    };
  };
}

export function HistoryDetails({ history }: HistoryDetailsProps) {
  return (
    <>
      <Box mt="1rem" mb="2rem" maxW="41.875rem">
        <Text
          lineHeight="1.25rem"
          color="gray.400"
          w="100%"
          fontSize={{ base: '.875rem', md: '1rem' }}
        >
          {history.description}
        </Text>
      </Box>

      <Flex gap="0.5rem">
        <Avatar
          size="xs"
          name={history.users.name}
          src={history.users.avatar_url}
        />

        <Text
          fontSize={{
            base: '.875rem',
            '2xl': '1rem',
          }}
          color="gray.700"
        >
          {history.users.name}
        </Text>
      </Flex>
    </>
  );
}
