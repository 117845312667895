import { KeyboardEvent } from 'react';
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai';
import { BiBuilding } from 'react-icons/bi';

import { useTicketEventStore } from '@store/ticket-event';

export function DesktopFilter() {
  const {
    intervalDates,
    saveIntervalDates,
    inputSearch,
    selectCompany,
    changeCompany,
    changeInputSearch,
    saveFilterParameters,
  } = useTicketEventStore((state) => state);

  function onSubmitByKeyPressed(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      saveFilterParameters();
    }
  }

  return (
    <Flex
      p="1.625rem"
      bg="white"
      rounded="0.625rem"
      mt="1rem"
      justify="space-between"
      gap={{ base: '1rem', lg: '2.5rem' }}
    >
      <Flex
        gap={{ base: '1rem', xl: '2.5rem' }}
        w="100%"
        overflowX={{ base: 'scroll', xl: 'unset' }}
      >
        <InputGroup w="8.75rem" border="transparent">
          <InputLeftElement pointerEvents="none">
            <BiBuilding color="gray.300" />
          </InputLeftElement>

          <Select
            value={selectCompany}
            onChange={(e) => changeCompany(e.target.value)}
            placeholder="Todas"
            style={{ paddingLeft: '2rem', marginTop: '0.0625rem' }}
            iconColor="blue.500"
          >
            <option value="Wire">Wire</option>
            <option value="Fiber Network">Fiber Network</option>
            <option value="Intlink">Intlink</option>
            <option value="Icom">Icom</option>
          </Select>
        </InputGroup>

        <InputGroup w={{ base: '100%', xl: '23rem' }}>
          <InputLeftElement pointerEvents="none">
            <AiOutlineSearch color="gray.300" />
          </InputLeftElement>
          <Input
            type="search"
            name="search"
            border="1px solid #E5E7EA"
            placeholder="Pesquisar por nome do cliente ou código do cliente"
            _placeholder={{ color: 'gray.400' }}
            onChange={(e) => changeInputSearch(e.target.value)}
            onKeyDown={(e) => onSubmitByKeyPressed(e)}
            value={inputSearch}
          />
        </InputGroup>

        <Stack direction="row" spacing="1.0625rem">
          <Flex align="center" gap="0.625rem">
            <Text>de</Text>

            <Input
              type="datetime-local"
              name="date"
              w="11.8rem"
              value={intervalDates.initialDate}
              onChange={(e) => saveIntervalDates(e.target.value, 'initial')}
            />
          </Flex>

          <Flex align="center" gap="0.625rem">
            <Text>até</Text>
            <Input
              type="datetime-local"
              w="11.8rem"
              value={intervalDates.endDate}
              onChange={(e) => saveIntervalDates(e.target.value, 'end')}
            />
          </Flex>
        </Stack>
      </Flex>

      <Button
        bg="blue.500"
        color="white"
        type="button"
        onClick={() => saveFilterParameters()}
      >
        Aplicar
      </Button>
    </Flex>
  );
}
