import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  TabPanel,
  Text,
} from '@chakra-ui/react';

import { CardImageWithName } from '@/components/CardImageWithName';
import { HeadingOne } from '@/components/Heading';
import { InputFileAddImage } from '@/components/InputFileAddImage';
import { useFormCreateEvent } from '@/hooks/useFormCreateEvent';
import { useSubmitNewEvent } from '@/hooks/useSubmitNewEvent';

import { useCreateEventStore } from '@store/create-event';

export function ImageEvent() {
  const {
    newEvent,
    newImages,
    previewImages,
    onAddNewImage,
    onRemoveImage,
    onFinalizeEventCreation,
    onFinalizeSteps,
  } = useCreateEventStore((state) => state);

  const { handleSubmit } = useFormCreateEvent();
  const { onSubmitImages } = useSubmitNewEvent();

  function handleNavigateToCardNewEvent() {
    onFinalizeEventCreation();
  }

  const onSubmitEventImages = () => {
    onSubmitImages();
    onFinalizeSteps();
  };

  return (
    <TabPanel p="0" pb="2rem">
      <form onSubmit={handleSubmit(onSubmitEventImages)}>
        <Box>
          <Flex
            justify="space-between"
            w="100%"
            mb="2.5rem"
            alignItems="center"
          >
            <HeadingOne
              text="Adicionar imagens"
              fontSize={{ base: '1rem', md: '3xl' }}
            />

            {newImages.length <= 0 && (
              <Button
                fontWeight="400"
                bg="transparent"
                _hover={{
                  textDecoration: 'underline',
                  filter: 'brightness(0.9)',
                }}
                _active={{}}
                color="blue.500"
                onClick={handleNavigateToCardNewEvent}
              >
                Adicionar mais tarde
              </Button>
            )}
          </Flex>

          <Flex alignItems="center" justify="space-between">
            <Heading fontWeight="500" color="gray.700" fontSize="1.625rem">
              Imagens
            </Heading>

            <Text>Opcional</Text>
          </Flex>

          <Text mt="0.375rem" color="#959595" fontSize="1rem">
            Adicione imagens sobre o evento. Estas imagens ficarão públicas para
            os clientes visualizarem.
          </Text>

          <Flex
            bg="white"
            border="1px solid #E5E7EA"
            rounded="0.5rem"
            py="2rem"
            px="1.625rem"
            mt="2.875rem"
            align={{ base: 'flex-start', md: 'center' }}
            justify="space-between"
            flexDirection={{ base: 'column', md: 'row' }}
            gap="1rem"
          >
            <Text
              color="gray.700"
              w={{ base: '100%', md: '60%' }}
              display="flex"
            >
              Você gostaria de adicionar imagens para o evento {newEvent.name}?
            </Text>

            <InputFileAddImage onChange={onAddNewImage} />
          </Flex>

          {newImages.length >= 1 && (
            <Flex justifyContent="space-between" mt="3.125rem">
              <Text color="gray.700" fontWeight="600 ">
                Imagens adicionadas
              </Text>

              <Button type="submit" bg="green.500" color="white">
                Salvar
              </Button>
            </Flex>
          )}

          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
              '2xl': 'repeat(3, 1fr)',
            }}
            gap={6}
            mt="1.25rem"
          >
            {previewImages.map((image) => {
              return (
                <CardImageWithName
                  key={image.lastModified}
                  data={image}
                  isShowSize
                  onClick={() => onRemoveImage(image.lastModified)}
                />
              );
            })}
          </Grid>
        </Box>
      </form>
    </TabPanel>
  );
}
