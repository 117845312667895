import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { CardEventProps } from './types';
import { Status } from '../../Status';

function CardEventComponent({ data }: CardEventProps) {
  return (
    <Box
      bg="white"
      rounded="0.5rem"
      w="100%"
      overflow="hidden"
      boxShadow="0rem 0.125rem 0.625rem rgba(0, 0, 0, 0.08)"
    >
      <Flex
        flexDirection="column"
        p="1.06rem 1.18rem 1.375rem"
        color="gray.400"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Link to={`/portal/details/${data.id}`} state={{ idEvent: data.id }}>
            <Heading fontSize="1rem" fontWeight="500" color="gray.700">
              {data.name}
            </Heading>
          </Link>

          <Text fontSize="0.875rem">{data.companies.name}</Text>
        </Flex>

        <Text mt="1.125rem" fontSize="0.875rem">
          {data.description}
        </Text>

        <Flex alignItems="center" color="blue.500" fontSize="0.875rem" gap={1}>
          Status: <Status status={data.status} />
        </Flex>
      </Flex>

      <Box bg="#F3F7F9" p="1rem 1.18rem">
        <Text fontSize="0.875rem">
          {data.status.currentStatus === 'Resolvido'
            ? `Resolvido ${data.elapsedTime}`
            : `Última atualização ${data.elapsedTime}`}
        </Text>
      </Box>
    </Box>
  );
}

export const CardEvent = memo(CardEventComponent);
