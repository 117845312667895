import axios, { AxiosError } from 'axios';
import { parseCookies, setCookie } from 'nookies';
import { apiSSO } from './apiSSO';

interface AxiosErrorResponse {
  code?: string;
}

const cookies = parseCookies();

export const apiReactQuery = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: {
    Authorization: `Bearer ${cookies['@event-monitor-web:token-1.0.1']}`,
  },
});

apiReactQuery.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError<AxiosErrorResponse>) => {
    if (error.response?.status === 401) {
      if (
        error.response.data?.code === 'token.expired' ||
        error.response.data?.code === 'token.invalid'
      ) {
        const cookies = parseCookies();

        const { '@event-monitor-web:token-1.0.1': token } = cookies;
        const { '@event-monitor-web:refreshToken-1.0.1': refreshToken } =
          cookies;

        apiReactQuery.defaults.headers.Authorization = `Bearer ${token}`;

        apiSSO
          .post('/session/refresh', {
            refresh_token: refreshToken,
          })
          .then((response) => {
            const { token, refresh_token } = response.data;

            setCookie(undefined, '@event-monitor-web:token-1.0.1', token, {
              maxAge: 60 * 60 * 24 * 30,
              path: '/',
            });

            setCookie(
              undefined,
              '@event-monitor-web:refreshToken-1.0.1',
              refresh_token,
              {
                maxAge: 60 * 60 * 24 * 30,
                path: '/',
              },
            );

            apiReactQuery.defaults.headers.Authorization = `Bearer ${token}`;
          });
      }
    }
  },
);
