import { ReopenEventState } from '@/store/reopen-event/types';

export const initialReopenEventState: ReopenEventState = {
  modal: {
    isOpen: false,
  },
  selectedStatus: {
    id: '',
    name: '',
  },
  historyDescription: '',
  isEventReopenedSuccessfully: false,
};
