import { Link } from 'react-router-dom';
import {
  ListIcon,
  Link as LinkChakra,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Icon,
  ComponentWithAs,
  IconProps,
} from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';

interface NavItemProps {
  item: {
    icon: ComponentWithAs<'svg', IconProps> | IconType;
    type: string;
    label: string;
    path: string;
  };
  collapse: boolean;
}

export function NavItem({ item, collapse }: NavItemProps) {
  if (item.type === 'accordion') {
    return (
      <Accordion allowToggle border="transparent">
        <AccordionItem>
          <AccordionButton flex="1" p="0" _hover={{}}>
            <Flex
              align="center"
              w="100%"
              justifyContent={!collapse ? 'center' : ''}
              gap="0.75rem"
            >
              <Flex justifyContent="center" align="center" w="2.5rem">
                <Icon as={item.icon} color="blue.500" fontSize={22} />
              </Flex>
              {collapse && (
                <Text color="gray.700" fontSize="0.9rem" fontWeight="500">
                  Eventos
                </Text>
              )}
            </Flex>

            <AccordionIcon
              display={collapse ? 'flex' : 'none'}
              color="blue.500"
            />
          </AccordionButton>

          {collapse && (
            <AccordionPanel pl="3.3rem" pt="0.75rem" pb="0" w="13.75rem">
              <LinkChakra as={Link} to="/opened/events">
                <Text color="gray.400" fontSize="0.9rem">
                  Eventos em aberto
                </Text>
              </LinkChakra>

              <LinkChakra as={Link} to="/previous/events">
                <Text color="gray.400" fontSize="0.9rem">
                  Eventos finalizados
                </Text>
              </LinkChakra>
            </AccordionPanel>
          )}
        </AccordionItem>
      </Accordion>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      w={!collapse ? '' : '220px'}
    >
      <LinkChakra
        to={item.path}
        as={Link}
        gap="0.75rem"
        display="flex"
        alignItems="center"
        _hover={{ textDecoration: 'none', color: 'black' }}
        fontWeight="medium"
        color="gray.700"
        w="full"
      >
        <Flex justifyContent="center" align="center" w="2.5rem">
          <ListIcon as={item.icon} fontSize={24} m="0" color="blue.500" />
        </Flex>
        {collapse && (
          <Text color="gray.700" fontSize="0.9rem" fontWeight="500">
            {item.label}
          </Text>
        )}
      </LinkChakra>
    </Box>
  );
}
