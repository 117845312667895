import { Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface FlexWrapTagProps {
  children: ReactNode;
}

const customScrollBar = {
  '&::-webkit-scrollbar': {
    width: '0.3125rem',
  },
  '&::-webkit-scrollbar-track': {
    width: '0.375rem',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'var(--chakra-colors-gray-300);',
    borderRadius: '0.625rem',
  },
};

export function FlexWrapTag({ children }: FlexWrapTagProps) {
  return (
    <Flex
      flexWrap="wrap"
      maxH="15.625rem"
      mt="0.75rem"
      rowGap="1rem"
      columnGap="0.25rem"
      overflowY="scroll"
      css={customScrollBar}
    >
      {children}
    </Flex>
  );
}
