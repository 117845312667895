import { useBreakpointValue } from '@chakra-ui/react';

import { ListEvents } from './ListEvents';
import { NoEventFound } from './NoEventFound';
import { TableEvent } from './TableEvent';
import { useGetPortalEvents } from '@/hooks/queries/get-portal-events';

export function Events() {
  const { queryData } = useGetPortalEvents();

  const hasEvent = queryData.events.length >= 1;

  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      ssr: false,
    },
  );

  if (!hasEvent) {
    return <NoEventFound />;
  }

  if (isMobile) {
    return <ListEvents />;
  }

  return <TableEvent />;
}
