import { StateCreator } from 'zustand';

import { initialHistoryState } from '@/store/update-event/initial-state/history';
import {
  HistoryState,
  HistoryActions,
} from '@/store/update-event/@types/history';

export const createHistorySlice: StateCreator<HistoryState & HistoryActions> = (
  set,
) => ({
  ...initialHistoryState,
  onInputDescription: (description) => {
    set(() => ({
      storyDescription: description,
    }));
  },
  onSelectStatus: (status) => {
    set(() => ({
      selectedStatus: status,
    }));
  },
  onSelectHistoryStatus: (event) => {
    if (event.target.value === '') {
      set({
        selectedStatus: {
          id: '',
          name: '',
        },
      });
      return;
    }

    const currentHistoryStatus = JSON.parse(event.target.value);

    set({ selectedStatus: currentHistoryStatus });
  },
  onResetHistorySlice: () => {
    set({
      storyDescription: '',
      selectedStatus: {
        id: '',
        name: '',
      },
    });
  },
});
