import { Center, Heading, Icon, Text } from '@chakra-ui/react';
import { TbDeviceDesktopAnalytics } from 'react-icons/tb';

export function AlertTicketEvent() {
  return (
    <Center
      h={{ base: '100%', sm: '17.8125rem' }}
      bg="white"
      rounded="1.25rem"
      flexDirection="column"
      py={{ base: '4rem', lg: '0rem' }}
      px={{ base: '2rem', lg: '4rem' }}
      textAlign="center"
      border="1px solid #CDDEFF"
    >
      <Icon as={TbDeviceDesktopAnalytics} color="blue.500" fontSize="2rem" />

      <Heading
        as="h2"
        fontSize={{ base: '0.8rem', lg: '1rem' }}
        fontWeight="600"
        color="gray.700"
        mt="0.125rem"
      >
        Nenhum cliente solicitou atendimento no momento
      </Heading>

      <Text
        fontWeight="400"
        fontSize={{ base: '0.8rem', lg: '1rem' }}
        mt="1rem"
      >
        Quando um ticket for criado ele irá aparecer aqui.
      </Text>
    </Center>
  );
}
