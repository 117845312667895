import { PublicEventState } from '@store/public-event/types';

export const initialPublicEventState: PublicEventState = {
  modal: {
    isOpen: false,
  },
  highlightImageIndex: 0,
  currentSlide: 0,
  isLoaded: false,
};
