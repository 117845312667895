import { Flex, Text } from '@chakra-ui/react';

interface CardStatProps {
  countStats: number;
  description: string;
}

export function CardStats({ countStats, description }: CardStatProps) {
  return (
    <Flex
      bg="white"
      w="15.6875rem"
      h="6.375rem"
      rounded="0.5rem"
      p="1rem"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Text
        fontSize={{ base: '1.875rem', lg: '2.5rem' }}
        color="blue.500"
        lineHeight={{ base: '1.875rem', lg: '2.5rem' }}
        fontWeight="600"
      >
        {countStats}
      </Text>
      <Text fontSize="0.875rem" lineHeight="18px">
        {description}
      </Text>
    </Flex>
  );
}
