import { FormControl, FormLabel, Select } from '@chakra-ui/react';

import { usePortalEventStore } from '@store/portal-event';

export function SelectCategory() {
  const { changeCategory, selectedCategory } = usePortalEventStore((state) => {
    const { selectedCategory, changeCategory } = state;

    return { selectedCategory, changeCategory };
  });

  return (
    <FormControl display="flex" alignItems="center" w="16rem">
      <FormLabel my="0">Categoria</FormLabel>

      <Select
        value={selectedCategory}
        _hover={{
          cursor: 'pointer',
        }}
        onChange={(e) => changeCategory(e.target.value)}
        placeholder="Todos"
        style={{ marginTop: '0.0625rem' }}
        iconColor="blue.500"
        w="12rem"
      >
        <option value="degradação">Degradação</option>
        <option value="indisponibilidade">Indisponibilidade</option>
      </Select>
    </FormControl>
  );
}
