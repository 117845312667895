export function getStatusTheme(status: string) {
  switch (status) {
    case 'Resolvido':
      return {
        bg: 'green.100',
        color: 'green.500',
      };

    case 'Monitorando':
      return {
        bg: 'blue.50',
        color: 'blue.500',
      };

    default:
      return {
        bg: 'orange.50',
        color: 'orange.500',
      };
  }
}
