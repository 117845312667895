import { Box, Stack } from '@chakra-ui/react';

import { ButtonGoBack } from '@/components/ButtonGoBack';
import { TicketList } from './TicketList';

import { TicketsProtocols } from './TicketsProtocols';

export function Tickets() {
  return (
    <Box
      as="section"
      h="100%"
      my={{ base: '6', lg: '6' }}
      px={{ base: '4', md: '8', lg: '10' }}
    >
      <ButtonGoBack />

      <Stack spacing="4.56rem">
        <TicketsProtocols />
        <TicketList />
      </Stack>
    </Box>
  );
}
