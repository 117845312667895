import { usePreviousEventStore } from '@store/previous-event';
import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { RiArrowDownSLine } from 'react-icons/ri';

export function SlaSelect() {
  const { selectedSla, changeSelectSla } = usePreviousEventStore(
    (state) => state,
  );

  return (
    <FormControl display="flex" alignItems="center" w="10.75rem">
      <FormLabel my="0">SLA</FormLabel>

      <Select
        value={selectedSla}
        _hover={{
          cursor: 'pointer',
        }}
        icon={<RiArrowDownSLine color="blue.500" />}
        onChange={(e) => changeSelectSla(e.target.value)}
        placeholder="Todos"
        style={{ paddingLeft: '1rem', marginTop: '0.0625rem' }}
        iconColor="blue.500"
        h="2.3rem"
      >
        <option value="on_time">No prazo</option>
        <option value="lated">Expirado</option>
      </Select>
    </FormControl>
  );
}
