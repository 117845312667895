import { useCreateEventStore } from '@store/create-event';
import { Button, Flex, Tag, Text, useDisclosure } from '@chakra-ui/react';
import { Fragment, memo } from 'react';

import { Modal } from './Modal';

export const Neighborhood = memo(function Neighborhood() {
  const modalState = useDisclosure();
  const neighborhoods = useCreateEventStore((state) => state.neighborhoods);

  return (
    <Flex
      flexDirection="column"
      w="100%"
      _notLast={{ borderBottom: '0.0625rem solid #E5E7EA' }}
    >
      <Flex align="center" my="1.875rem" justifyContent="space-between">
        <Text fontSize="1rem" fontWeight="400" color="gray.700">
          Bairro
        </Text>

        <Modal disclosure={modalState} />
      </Flex>

      {neighborhoods.length > 0 && (
        <Flex
          mt="-1.875rem"
          mb="1.875rem"
          rowGap="1rem"
          columnGap="0.25rem"
          flexWrap="wrap"
          align="center"
        >
          {neighborhoods.map((data, index) => (
            <Fragment key={data.name}>
              {index < 5 && (
                <Tag
                  key={data.name}
                  bg="blue.500"
                  color="white"
                  rounded="full"
                  fontSize="1rem"
                >
                  {`${data.name} - ${data.city_name}`}
                </Tag>
              )}
            </Fragment>
          ))}

          {neighborhoods.length >= 5 && (
            <Button
              color="blue.500"
              fontWeight="400"
              bg="transparent"
              h="100%"
              _hover={{ textDecoration: 'underline' }}
              onClick={modalState.onOpen}
            >
              Ver mais
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  );
});
