import { usePreviousEventStore } from '@store/previous-event';
import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { RiArrowDownSLine } from 'react-icons/ri';

export function CategorySelect() {
  const { selectedCategory, changeSelectedCategory } = usePreviousEventStore(
    (state) => state,
  );

  return (
    <FormControl
      display="flex"
      alignItems="center"
      w={selectedCategory !== '' ? '17rem' : '12.5rem'}
    >
      <FormLabel my="0">Categoria</FormLabel>

      <Select
        value={selectedCategory}
        _hover={{
          cursor: 'pointer',
        }}
        icon={<RiArrowDownSLine color="blue.500" />}
        onChange={(e) => changeSelectedCategory(e.target.value)}
        placeholder="Todas"
        style={{ paddingLeft: '1rem', marginTop: '0.0625rem' }}
        iconColor="blue.500"
        h="2.3rem"
      >
        <option value="Degradação">Degradação</option>
        <option value="Indisponibilidade">Indisponibilidade</option>
      </Select>
    </FormControl>
  );
}
