import { useState } from 'react';
import { Box, Flex, HStack, keyframes } from '@chakra-ui/react';

import { Sidebar } from './Sidebar';

const openAnimation = keyframes`
  0% {
    width: 6.5rem;
  }

  100% {
    width: 17rem;
  }
`;

const closeAnimation = keyframes`
  0% {
    width: 17rem;
  }

  100% {
    width: 6.5rem;
  }
`;

export function Desktop() {
  const [collapse, setCollapse] = useState(false);
  const [currentAnimation, setCurrentAnimation] = useState('');

  const animationOpenSidebar = `${openAnimation} 0.3s linear`;
  const animationCloseSidebar = `${closeAnimation} 0.3s linear`;

  function openSidebar() {
    setCollapse(true);
    setCurrentAnimation(animationOpenSidebar);
  }

  function closeSidebar() {
    setCollapse(false);
    setCurrentAnimation(animationCloseSidebar);
  }

  return (
    <Box as="aside" zIndex="5" display={{ base: 'none', lg: 'block' }}>
      <Flex w="5.5rem">
        <HStack h="100%" pos="fixed">
          <Flex
            h="full"
            bg="white"
            alignItems="start"
            boxShadow="0px 0px 10px 0px #0000001a"
            padding={6}
            flexDirection="column"
            justifyContent="space-between"
            onMouseEnter={openSidebar}
            onMouseLeave={closeSidebar}
            animation={currentAnimation}
          >
            <Sidebar collapse={collapse} />
          </Flex>
        </HStack>
      </Flex>
    </Box>
  );
}
