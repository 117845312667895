import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Link as ReachLink, To } from 'react-router-dom';

interface LinkProps<S = Record<string, unknown>> extends ChakraLinkProps {
  children: ReactNode;
  to: To;
  state?: S;
}

export function Link<S>({ children, to, state, ...rest }: LinkProps<S>) {
  return (
    <ChakraLink as={ReachLink} to={to} state={state} {...rest}>
      {children}
    </ChakraLink>
  );
}
