import { Flex } from '@chakra-ui/react';
import { CardStats } from './CardStats';

interface StackCardsStatsProps {
  statsData: {
    ticket_count: number;
    protocol_count: number;
  };
}

export function StackCardsStats({ statsData }: StackCardsStatsProps) {
  return (
    <Flex mt="2.8125rem" gap="1.5625rem">
      <CardStats
        countStats={statsData.ticket_count}
        description={
          statsData.ticket_count <= 1
            ? 'atendimento solicitado pelo Blip'
            : 'atendimentos solicitados pelo Blip'
        }
      />
      <CardStats
        countStats={statsData.protocol_count}
        description={
          statsData.protocol_count <= 1
            ? 'solicitou protocolo'
            : 'solicitaram protocolo'
        }
      />
    </Flex>
  );
}
