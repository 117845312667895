import { Box, Flex, TabPanel, Text } from '@chakra-ui/react';

import { HeadingOne, HeadingTwo } from '@/components/Heading';
import { SnapEventInfo } from '../SnapEventInfo';
import { City } from './City';
import { Neighborhood } from './Neighborhood';
import { FederativeUnit } from './FederativeUnit';
import { DeviceCnt } from './DeviceCnt';

export function LocationEquipment() {
  return (
    <TabPanel p="0" pb="2rem">
      <Flex
        justifyContent="space-between"
        align="center"
        mb={{ base: '0', lg: '1rem' }}
      >
        <HeadingOne
          text="Atualizando Evento"
          display={{ base: 'none', lg: 'flex' }}
        />
      </Flex>

      <SnapEventInfo />

      <Box mt={{ base: '3rem', lg: '2.625rem' }}>
        <HeadingTwo text="Locais e equipamentos" />

        <Text mt="0.375rem" color="gray.400" fontSize="1rem">
          Informe quais os locais ou equipamentos estão sendo afetados por este
          evento.
        </Text>

        <Box
          border="1px"
          borderColor="#E5E7EA"
          bg="white"
          mt="1.9375rem"
          px="1.125rem"
          rounded="0.5rem"
        >
          <FederativeUnit />
          <City />
          <Neighborhood />
          <DeviceCnt />
        </Box>
      </Box>
    </TabPanel>
  );
}
