import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Icon,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import { useCreateEventStore } from '@store/create-event';

interface TemplateModalProps {
  name: string;
  reset: () => void;
  disclosure: {
    onOpen: () => void;
    isOpen: boolean;
    onClose: () => void;
  };
  children: ReactNode;
}

export function TemplateModal({
  name,
  reset,
  disclosure,
  children,
}: TemplateModalProps) {
  const isExistsSelectedCompany = useCreateEventStore((state) => {
    return !!state.selectedCompany.id;
  });

  return (
    <>
      <Button
        color="gray.400"
        fontWeight="400"
        onClick={disclosure.onOpen}
        p="0"
        bg="transparent"
        transition="0.6 ease"
        _hover={{
          color: 'blue.500',
          filter: 'brightness(0.8)',
        }}
        _active={{}}
        disabled={!isExistsSelectedCompany}
      >
        Adicionar
        <Flex
          bg="blue.500"
          color="white"
          rounded="full"
          ml="0.5rem"
          w="25px"
          h="25px"
          align="center"
          justify="center"
        >
          <Icon as={AddIcon} fontSize="0.6rem" />
        </Flex>
      </Button>

      <ChakraModal
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        size="2xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          minH="34.37rem"
          overflow="hidden"
          rounded="1.25rem"
          mx="1rem"
        >
          <ModalHeader py="0.5625rem">
            <Box
              justifyContent="space-between"
              w="100%"
              borderBottom="0.0625rem solid #E5E7EA"
              py="1.25rem"
            >
              <Text
                color="gray.700"
                fontWeight="600"
                fontSize={{ base: '1rem', md: '1.25rem' }}
              >
                Adicionar {name}
              </Text>
              <Text
                fontSize={{ base: '0.875rem', md: '1rem' }}
                fontWeight="500"
              >
                Locais e Equipamentos
              </Text>
            </Box>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Flex flexDirection="column">{children}</Flex>
          </ModalBody>

          <ModalFooter bg="#EBEFF2" justifyContent="space-between">
            <Button onClick={reset} fontWeight="400" color="gray.700">
              Limpar tudo
            </Button>

            <Button
              colorScheme="blue"
              onClick={disclosure.onClose}
              w="6.18rem"
              h="2rem"
              rounded="0.625rem"
              color="white"
              bg="blue.500"
              fontWeight="400"
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
}
