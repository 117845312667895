import { Box, Flex, IconButton, Link, Spinner, Text } from '@chakra-ui/react';
import { Link as ReachLink } from 'react-router-dom';
import { MdRefresh } from 'react-icons/md';

import { AvatarLogo } from '@/components/AvatarLogo';
import { EventTimelineSlider } from './EventTimelineSlider';
import { Skeleton } from './Skeleton';
import { AlertLatestEvent } from './AlertLatestEvent';
import { useGetLatestEvent } from '@/hooks/queries/get-latest-event';

export function LatestEventCard() {
  const { isLoading, data, refetch, isFetching } = useGetLatestEvent();

  if (isLoading) {
    return <Skeleton />;
  }

  if (data === null) {
    return <AlertLatestEvent />;
  }

  return (
    <Box
      bg="white"
      py="1.25rem"
      px="2.4375rem"
      rounded="1.25rem"
      h={{ base: '100%', lg: '17.8125rem' }}
    >
      <Flex justify="space-between" w="100%" flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" gap="0.5rem">
            <Text fontWeight="500" fontSize="1.125rem" color="gray.700">
              Acontecendo agora
            </Text>

            {!isLoading && isFetching ? (
              <Spinner size="sm" color="gray.500" />
            ) : (
              <IconButton
                aria-label="Refetch most recent event"
                p="0"
                minW="0"
                h="100%"
                fontSize="1.2rem"
                bg="white"
                onClick={() => refetch()}
                icon={<MdRefresh />}
              />
            )}
          </Flex>

          <Link
            as={ReachLink}
            to="/opened/events"
            state={{ idEvent: data.id }}
            color="gray.700"
            h="1rem"
            fontSize={{ base: '0.875rem', lg: '1rem' }}
            fontWeight="400"
          >
            Ver todos
          </Link>
        </Flex>

        <Flex mt="2.75rem" gap="0.375rem">
          <AvatarLogo
            url={data.companies.url_sm_logo}
            name={data.companies.name}
            circleSize="md"
          />

          <Flex flexDirection={{ base: 'column', lg: 'row' }} gap="0.75rem">
            <Flex gap={{ base: '0', lg: '0.5rem' }} flexDirection="column">
              <Link
                as={ReachLink}
                to={`/update/event/${data.id}`}
                state={{ idEvent: data.id }}
                color="gray.700"
                h="1rem"
                fontSize={{ base: '0.875rem', lg: '1rem' }}
              >
                {data.name}
              </Link>

              <Text fontSize="xs" mt={{ base: '0', sm: '0' }}>
                Aberto {data.elapsedTime}.
              </Text>
            </Flex>

            {data.categories && (
              <Text
                bg="#3F7EF8"
                color="white"
                justifyContent="center"
                alignItems="center"
                display="flex"
                padding="0.1rem 0.5rem"
                rounded="full"
                fontSize="0.875rem"
                h="1.6875rem"
              >
                {data.categories.name}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>

      <EventTimelineSlider event={data} />
    </Box>
  );
}
