import { create } from 'zustand';
import {
  DetailEventState,
  DetailEventActions,
} from '@/store/detail-event/types';
import { initialDetailEventState } from '@/store/detail-event/initial-state';

export const useDetailEventStore = create<
  DetailEventState & DetailEventActions
>((set) => ({
  ...initialDetailEventState,
  onInitializeStoreWithEventData: (data) => {
    set({
      event: data.event,
      isExistsEquipmentLocation: data.isExistsEquipmentLocation,
    });
  },
}));
