import { Box, Flex } from '@chakra-ui/react';

import { Header } from '@/components/Header';
import { Stats } from './Stats';
import { LatestEventCard } from './LatestEventCard';

export function Dashboard() {
  return (
    <Box
      my={{ base: '6', lg: '6' }}
      px={{ base: '4', md: '8', lg: '10' }}
      h="100%"
    >
      <Flex flexDirection="column" gap="3.5rem">
        <Header />

        <LatestEventCard />

        <Stats />
      </Flex>
    </Box>
  );
}
