import { Center, Heading, Image, Text } from '@chakra-ui/react';

import checkGreenImg from '@/assets/images/icon-check-green.svg';

interface IncidentResolvedProps {
  company: {
    url_logo: string;
    name: string;
  };
}

export function IncidentResolved({ company }: IncidentResolvedProps) {
  return (
    <Center as="main" h="100vh" px="1rem">
      <Center
        flexDir="column"
        bg="white"
        p="3rem"
        textAlign="center"
        rounded="0.4rem"
      >
        <Image
          src={checkGreenImg}
          alt="icon check green"
          boxSize="3.75rem"
          mb="1rem"
        />

        <Heading
          as="h1"
          fontSize={{ base: '1rem', lg: '1.4rem' }}
          mb="1rem"
          color="gray.700"
          fontWeight="600"
        >
          Incidente resolvido com sucesso!
        </Heading>

        <Text
          fontSize={{ base: '0.9rem', lg: '1rem' }}
          maxW="21.875rem"
          color="gray.400"
          lineHeight="1rem"
        >
          A sua localidade já se encontra com a sua conexão reestabelecida.
        </Text>

        <Center w="10rem" mt={{ base: '1rem', lg: '2rem' }}>
          <Image src={company.url_logo} alt={`logo da ${company.name}`} />
        </Center>
      </Center>
    </Center>
  );
}
