import { Center, Icon, Text } from '@chakra-ui/react';
import { AiFillInfoCircle } from 'react-icons/ai';

interface AlertEventBoxProps {
  title: string;
  description: string;
}

export function AlertEventBox({ title, description }: AlertEventBoxProps) {
  return (
    <Center
      h="20rem"
      color="gray.700"
      flexDirection="column"
      bg="white"
      rounded="0.25rem"
      px="0.8rem"
    >
      <Icon as={AiFillInfoCircle} color="blue.300" fontSize="2rem" />
      <Text mt="3" fontWeight="500" fontSize={{ base: 'md', lg: 'xl' }}>
        {title}
      </Text>

      <Text mt="1" fontSize={{ base: 'sm', lg: 'md' }}>
        {description}
      </Text>
    </Center>
  );
}
