import { create } from 'zustand';
import {
  PublicEventState,
  PublicEventActions,
} from '@store/public-event/types';
import { initialPublicEventState } from '@store/public-event/initial-state';

export const usePublicEventStore = create<
  PublicEventState & PublicEventActions
>((set) => ({
  ...initialPublicEventState,
  onToggleModal: (isOpen) => {
    set({ modal: { isOpen } });
  },
  handleOpenFocusImage: (index) => {
    set({
      highlightImageIndex: index,
      modal: {
        isOpen: true,
      },
    });
  },
  onChangeCurrentSlide: (index) => {
    set({ currentSlide: index });
  },
  onChangeLoaded: (state) => {
    set({ isLoaded: state });
  },
}));
