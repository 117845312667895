import { Box, Flex, Image, Text } from '@chakra-ui/react';

import logoImg from '@/assets/images/logo.svg';

export function Logo() {
  return (
    <Flex gap="2.875rem" align="center" mx="auto ">
      <Box w={{ base: '7rem', md: '9.687rem' }} h="4.312rem">
        <Image boxSize="100%" src={logoImg} alt="logo da click ip" />
      </Box>

      <Text
        as="span"
        fontSize={{ base: '1rem', md: '1.125rem' }}
        color="gray.400"
      >
        Monitoramento
        <Text color="blue.800" fontWeight="700">
          de Eventos
        </Text>
      </Text>
    </Flex>
  );
}
