import { StateCreator } from 'zustand';
import { initialStepState } from '@/store/update-event/initial-state/step';
import { StepState, StepActions } from '@/store/update-event/@types/step';

export const createStepSlice: StateCreator<StepState & StepActions> = (
  set,
) => ({
  ...initialStepState,
  onChangeTab: (index) => {
    set(() => ({
      tabIndex: index,
      currentTabIndex: index,
    }));
  },
});
