import { extendTheme } from '@chakra-ui/react';
import { blue, gray, green, orange } from './colors';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const customTheme = extendTheme({
  config,
  fonts: {
    body: 'Mukta, sans-serif',
    heading: 'Mukta, sans-serif',
  },
  styles: {
    global: {
      'html, body': {
        background: '#F1F2F4',
        color: 'gray.400',
      },
    },
  },
  colors: {
    blue,
    gray,
    green,
    orange,
    input: {
      border: '#62BF89',
      placeholder: '#D2D2D2',
    },
  },
});

export default customTheme;
