import {
  Box,
  Button,
  Flex,
  FormControl,
  ModalBody,
  ModalFooter,
  Text,
  Input,
  Select,
  Modal as ChakraModal,
} from '@chakra-ui/react';

import { CreateOrEditModalButton } from '@/components/Modal/ModalLocationEquipment/CreateOrEditModalButton';
import { ModalContent } from '@/components/Modal/ModalLocationEquipment/ModalContent';
import { FlexWrapTag } from '@/components/Modal/ModalLocationEquipment/FlexWrapTag';
import { TagButton } from '@/components/Modal/ModalLocationEquipment/TagButton';
import { useUpdateEventStore } from '@/store/update-event';

import { ChangeEvent, useState } from 'react';
import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';

import {
  useSelectMultiple,
  useAddEventDetails,
  useDeleteEventDetails,
} from '@/hooks/queries/get-event-detail-to-update/query-updater';

interface Store {
  store_id: string;
}

export function Modal() {
  const [store, setStore] = useState<Store>(null);

  const { queryData } = useGetEventDetailToUpdate();

  const { selectedCompany, onToggleModal, modals } = useUpdateEventStore(
    (state) => state,
  );

  const { onSelectMultipleDeviceCnt } = useSelectMultiple();
  const { onAddNewDeviceCnt } = useAddEventDetails();
  const { onDeleteDeviceCnt } = useDeleteEventDetails();

  const isExistsSelectedCompany = !!selectedCompany.id;
  const hasNoStoreSelected = store === null;

  const eventId = queryData.event.id;

  const isExistsSelectedDeviceCnt = queryData.event.device_cnt.some((item) => {
    return item.isSelected === true;
  });

  function handleSelectStore(e: ChangeEvent<HTMLSelectElement>) {
    if (e.target.value === '') {
      setStore(null);
      return;
    }

    setStore(JSON.parse(e.target.value));
  }

  return (
    <>
      <CreateOrEditModalButton
        data={queryData.event.device_cnt}
        onOpenModalClick={() =>
          onToggleModal({
            modalName: 'deviceCnt',
            isOpen: true,
          })
        }
        type="update"
      />

      <ChakraModal
        isOpen={modals.deviceCnt.isOpen}
        onClose={() =>
          onToggleModal({
            modalName: 'deviceCnt',
            isOpen: false,
          })
        }
        size="2xl"
        isCentered
      >
        <ModalContent name="Id Device Cnt">
          <ModalBody>
            <Flex flexDirection="column">
              <FormControl display="flex" alignItems="center">
                {isExistsSelectedCompany && (
                  <Flex w="100%" gap="1.125rem">
                    <Select
                      placeholder="Loja"
                      onChange={handleSelectStore}
                      w="9rem"
                    >
                      {selectedCompany.company_stores.map((store) => {
                        return (
                          <option key={store.id} value={JSON.stringify(store)}>
                            Loja {store.name}
                          </option>
                        );
                      })}
                    </Select>

                    <Input
                      disabled={hasNoStoreSelected}
                      placeholder="Informe o/os Id Device Cnt"
                      bg="white"
                      onKeyDown={(event) =>
                        onAddNewDeviceCnt({
                          event,
                          storeId: store.store_id,
                          eventId,
                        })
                      }
                    />
                  </Flex>
                )}
              </FormControl>

              {queryData.event.device_cnt.length > 0 && (
                <Box mt="2.5rem">
                  <Text fontSize="1rem">Id Devices CNT adicionados</Text>

                  <FlexWrapTag>
                    {queryData.event.device_cnt.map((device) => {
                      return (
                        <TagButton
                          key={device.name}
                          name={device.name}
                          description={device.description}
                          isSelected={device.isSelected}
                          onClick={() => onSelectMultipleDeviceCnt(device)}
                        />
                      );
                    })}
                  </FlexWrapTag>
                </Box>
              )}
            </Flex>
          </ModalBody>

          <ModalFooter bg="#EBEFF2" justifyContent="space-between">
            <Text>Total de {queryData.event.device_cnt.length} id devices</Text>

            <Flex gap="1rem" alignContent="end">
              {isExistsSelectedDeviceCnt && (
                <Button
                  bg="red.500"
                  w="6.18rem"
                  h="2rem"
                  rounded="0.625rem"
                  color="white"
                  fontWeight="400"
                  onClick={onDeleteDeviceCnt}
                >
                  Remover
                </Button>
              )}

              <Button
                colorScheme="blue"
                onClick={() =>
                  onToggleModal({
                    modalName: 'deviceCnt',
                    isOpen: false,
                  })
                }
                w="6.18rem"
                h="2rem"
                rounded="0.625rem"
                color="white"
                bg="blue.500"
                fontWeight="400"
              >
                Salvar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
}
