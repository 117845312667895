import { Location, useLocation, useNavigate } from 'react-router-dom';
import { CheckIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Hide, Icon, Input, Text } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { parseCookies } from 'nookies';

import { apiReactQuery } from '@/lib/axios/apiReactQuery';
import { queryClient } from '@/services/queryClient';
import { formatDateWithHour } from '@/utils/formatDate';
import { DeleteStoryMenu } from './DeleteStoryMenu';

export interface LocationUploadEvent extends Location {
  state: {
    idEvent?: string;
  };
}

export function Publication({ history }) {
  const navigate = useNavigate();
  const location: LocationUploadEvent = useLocation();
  const [initialDescription, setInitialDescription] = useState(
    history.description,
  );
  const [inputDescription, setInputDescription] = useState(initialDescription);

  const updateHistoricEvent = useMutation({
    mutationFn: async () => {
      try {
        const { '@event-monitor-web:token-1.0.1': token } = parseCookies();
        const response = await apiReactQuery.put(
          '/history-event',
          {
            id: history.id,
            description: inputDescription,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setInitialDescription(response.data.description);

        toast.success('O Evento foi atualizado com sucesso');
        return response.data;
      } catch (error) {
        const { message } = error as Error;
        toast.error(message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['latest-event'],
        exact: true,
      });
    },
  });

  async function onUpdateHistory() {
    await updateHistoricEvent.mutateAsync();

    return navigate(`/update/event/${location.state.idEvent}`, {
      state: { idEvent: location.state.idEvent },
    });
  }

  function handleInputChange(e) {
    setInputDescription(e.target.value);
  }

  return (
    <Box
      key={history.id}
      _notFirst={{
        borderTop: '0.0625rem solid #D9D9D9',
        marginTop: '1.562rem',
        paddingTop: '1.562rem',
      }}
    >
      <Flex align="center" justify="space-between">
        <Text color="gray.700" fontWeight="500">
          {history.status.name}
        </Text>
        <Flex align={{ base: 'center', md: 'center', lg: 'center' }}>
          {initialDescription !== inputDescription && (
            <Button
              h="100%"
              bg="none"
              color="blue.500"
              fontWeight="400"
              _hover={{ filter: 'brightness(0.9)' }}
              onClick={onUpdateHistory}
            >
              <CheckIcon /> Atualizar
            </Button>
          )}

          <DeleteStoryMenu storyId={history.id} />
        </Flex>
      </Flex>

      <Flex fontSize="0.875rem" mt="0.25rem">
        <Text color="blue.500">{formatDateWithHour(history.created_at)}</Text>

        <Flex ml="1.75rem" align="center" gap="0.375rem">
          <Icon as={AiOutlineUser} />
          <Text>
            <Hide below="md">Publicado por </Hide>
            {history.users.name}
          </Text>
        </Flex>
      </Flex>

      <Input
        value={inputDescription}
        variant="unstyled"
        mt="1.31rem"
        placeholder={initialDescription}
        onChange={handleInputChange}
      />
    </Box>
  );
}
