import { Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface WhiteBoxProps {
  children: ReactNode;
}

export function WhiteBox({ children }: WhiteBoxProps) {
  return (
    <Flex
      background="white"
      flexDir="column"
      w="100%"
      px={{ base: '1.625rem', md: '3.0625rem' }}
      py={{ base: '2.125rem', md: '2.5625rem' }}
      rounded="0.75rem"
    >
      {children}
    </Flex>
  );
}
