import { Flex } from '@chakra-ui/react';

import { CardEvent } from './CardEvent';
import { useGetPortalEvents } from '@/hooks/queries/get-portal-events';

export function ListEvents() {
  const { queryData } = useGetPortalEvents();

  return (
    <Flex mt="1.25rem" direction="column" gap="1.187rem">
      {queryData.events.map((event) => {
        return <CardEvent key={event.id} data={event} />;
      })}
    </Flex>
  );
}
