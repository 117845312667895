import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';
import { apiAxios } from '@/lib/axios/apiAxios';
import { queryClient } from '@/services/queryClient';
import { useUpdateEventStore } from '@/store/update-event';
import { useMutation } from '@tanstack/react-query';
import { parseCookies } from 'nookies';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

interface LocationState {
  idEvent: string;
}

export function useCreateHistory() {
  const location = useLocation() as { state: LocationState };
  const { queryData } = useGetEventDetailToUpdate();
  const { selectedStatus, storyDescription } = useUpdateEventStore(
    (state) => state,
  );

  const onResetHistorySlice = useUpdateEventStore(
    (state) => state.onResetHistorySlice,
  );

  const mutation = useMutation({
    mutationFn: async () => {
      try {
        const { '@event-monitor-web:token-1.0.1': token } = parseCookies();
        const response = await apiAxios.post(
          '/history-event',
          {
            event_id: queryData.event.id,
            status_id: selectedStatus.id,
            description: storyDescription,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        onResetHistorySlice();

        toast.success('O Evento foi atualizado com sucesso');

        return response.data;
      } catch (error) {
        toast.error(error.response.data.msg);
        return error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['opened-events', 1],
        exact: true,
      });

      queryClient.invalidateQueries({
        queryKey: ['latest-event'],
        exact: true,
      });

      queryClient.invalidateQueries({
        queryKey: [
          'event-detail-to-update',
          location.state,
          location.state.idEvent,
        ],
        exact: true,
      });
    },
  });

  return mutation;
}
