import { ChangeEvent, KeyboardEvent, useState } from 'react';
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai';

import { StackCardsStats } from '@/components/StackCardsStats';
import { HeadingOne } from '@/components/Heading';
import { ButtonGoBack } from '@/components/ButtonGoBack';
import { Pagination } from './Pagination';
import { ListTickets } from './ListTickets';
import { useGetTicketByEvent } from '@/hooks/queries/get-ticket-by-event';

export function TicketByEvent() {
  const [searchNameEvent, setSearchNameEvent] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, refetch } = useGetTicketByEvent({
    searchNameEvent: searchNameEvent,
    currentPage: currentPage,
  });

  async function handleSearchNameOrPE(
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>,
  ) {
    setCurrentPage(1);

    const isEnterKey = (event as KeyboardEvent).key === 'Enter';
    const isClick = event.type === 'click';

    if (isEnterKey || isClick) {
      refetch();
    }
  }

  if (isLoading) {
    return <></>;
  }

  return (
    <Box my="6" px={{ base: '4', md: '8', lg: '10' }} h="100%">
      <Box mt={{ base: '2rem', lg: '0' }} mb="3rem">
        <ButtonGoBack />

        <Flex flexDir="column">
          <HeadingOne text={data.event.name} />

          <StackCardsStats statsData={data.totalStats} />

          <InputGroup
            mt="1.375rem"
            border="transparent"
            bg="white"
            rounded="0.5rem"
          >
            <InputLeftElement pointerEvents="none" w="1.875rem">
              <AiOutlineSearch color="gray.300" />
            </InputLeftElement>
            <Input
              enterKeyHint="search"
              pl="1.875rem"
              pr="0"
              type="search"
              name="search"
              placeholder="Pesquisar por nome do cliente ou código do cliente"
              _placeholder={{ color: 'gray.400' }}
              onChange={(e) => setSearchNameEvent(e.target.value)}
              onKeyDown={(e) => handleSearchNameOrPE(e)}
              value={searchNameEvent}
            />
          </InputGroup>

          <ListTickets
            tickets={data.tickets}
            isExistsTickets={data.isExistsTickets}
          />

          <Pagination
            pagination={data.pagination}
            currentPage={currentPage}
            changePage={setCurrentPage}
          />
        </Flex>
      </Box>
    </Box>
  );
}
