import { Stack, Text } from '@chakra-ui/react';
import React from 'react';

import { usePagination } from '@/hooks/usePagination';
import { PaginationItem } from './PaginationItem';

export interface PaginationProps {
  totalCountOfRegisters: number;
  registerPerPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage?: number;
}

const siblingsCount = 1;

function generatePagesArray(from: number, to: number) {
  return [...new Array(to - from)]
    .map((_, index) => {
      return from + index + 1;
    })
    .filter((page) => page > 0);
}

export function Pagination({
  totalCountOfRegisters,
  registerPerPage,
  setCurrentPage,
  currentPage = 1,
}: PaginationProps) {
  const lastPage = Math.ceil(totalCountOfRegisters / registerPerPage);
  const { handleChangeCurrentPage } = usePagination();

  const previousPages =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : [];

  const nextPages =
    currentPage < lastPage
      ? generatePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage),
        )
      : [];

  return (
    <Stack
      direction={['column', 'row']}
      justify="space-between"
      align="center"
      rounded="0.25rem"
    >
      <Stack direction="row">
        {currentPage > 1 + siblingsCount && (
          <>
            <PaginationItem
              onPageChange={() => handleChangeCurrentPage(1, setCurrentPage)}
              number={1}
            />
            {currentPage > 2 + siblingsCount && (
              <Text color="gray.300" w="8" textAlign="center">
                ...
              </Text>
            )}
          </>
        )}

        {previousPages.length > 0 &&
          previousPages.map((page) => {
            return (
              <PaginationItem
                onPageChange={() =>
                  handleChangeCurrentPage(page, setCurrentPage)
                }
                key={page}
                number={page}
              />
            );
          })}

        <PaginationItem
          onPageChange={() =>
            handleChangeCurrentPage(currentPage, setCurrentPage)
          }
          number={currentPage}
          isCurrent
        />

        {nextPages.length > 0 &&
          nextPages.map((page) => {
            return (
              <PaginationItem
                onPageChange={() =>
                  handleChangeCurrentPage(page, setCurrentPage)
                }
                key={page}
                number={page}
              />
            );
          })}

        {currentPage + siblingsCount < lastPage && (
          <>
            {currentPage + 1 + siblingsCount < lastPage && (
              <Text color="gray.300" w="8" textAlign="center">
                ...
              </Text>
            )}
            <PaginationItem
              onPageChange={() =>
                handleChangeCurrentPage(lastPage, setCurrentPage)
              }
              number={lastPage}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
}
