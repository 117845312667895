import { StateCreator } from 'zustand';
import { toast } from 'react-toastify';

import { formatFirstString } from '@/utils/formatFirstString';
import { CityState, CityActions } from '@store/create-event/@types/city';
import { initialCityState } from '@store/create-event/initial-state/city';

export const citySlice: StateCreator<CityState & CityActions> = (set, get) => ({
  ...initialCityState,
  onAddCity: (city) => {
    const newCity = {
      id: city.id,
      name: formatFirstString(city.name),
    };

    const isExistsCity = get().cities.some((city) => city.id === newCity.id);

    if (isExistsCity) {
      return toast.warning(`${newCity.name} já existe`);
    }

    set((state) => ({
      cities: [...state.cities, newCity],
    }));
  },
  onRemoveCity: (name) => {
    const currentCities = get().cities;

    const remainingCities = currentCities.filter((city) => city.name !== name);

    set({ cities: remainingCities });
  },
  onResetCity: () => {
    set({ cities: [] });
  },
});
