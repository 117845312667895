import { useLocation } from 'react-router-dom';
import { useGetEventDetailToUpdate } from '..';
import { toast } from 'react-toastify';
import { apiAxios } from '@/lib/axios/apiAxios';
import { queryClient } from '@/services/queryClient';
import { Return } from '../types';

interface LocationState {
  idEvent: string;
}

export interface ErrorMessage extends Error {
  data: {
    msg: string;
  };
}

export interface ErrorMessage2 extends Error {
  response: {
    data: {
      msg: string;
    };
  };
}

export function useDeleteEventDetails() {
  const { queryData } = useGetEventDetailToUpdate();
  const location = useLocation() as { state: LocationState };

  async function onDeleteState() {
    try {
      const currentFederativeUnits = queryData.event.states;

      const getFederativeUnitsSelected = currentFederativeUnits.filter(
        (state) => {
          return state.isSelected === true;
        },
      );

      const parsedFederativeUnitsSelected = getFederativeUnitsSelected.map(
        (state) => {
          return {
            id: state.state_event_id,
          };
        },
      );

      await apiAxios.delete('/state', {
        data: {
          state_events: parsedFederativeUnitsSelected,
        },
      });

      const getFederativeUnitsNotSelected = queryData.event.states.filter(
        (state) => {
          return state.isSelected === false;
        },
      );

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              states: getFederativeUnitsNotSelected,
            },
          };
        },
      ) as Return;

      toast.success('State successfully removed.');
    } catch (error) {
      const { response } = error as ErrorMessage2;
      toast.success(response.data.msg);
    }
  }

  async function onDeleteCity(eventId) {
    try {
      const currentCities = queryData.event.cities;

      const citiesSelected = currentCities.filter((city) => {
        return city.isSelected === true;
      });

      const parsedCitiesSelected = citiesSelected.map((city) => {
        return {
          id: city.id,
        };
      });

      await apiAxios.delete('/city', {
        data: {
          event_id: eventId,
          cities: parsedCitiesSelected,
        },
      });

      const citiesNotSelected = queryData.event.cities.filter((city) => {
        return city.isSelected === false;
      });

      toast.success('City successful removed.');

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              cities: citiesNotSelected,
            },
          };
        },
      ) as Return;
    } catch (error) {
      const response = error as ErrorMessage;
      toast.error(response.data.msg);
    }
  }

  async function onDeleteNeighborhood() {
    try {
      const currentNeighborhoods = queryData.event.neighborhoods;

      const neighborhoodsSelected = currentNeighborhoods.filter(
        (neighborhood) => {
          return neighborhood.isSelected === true;
        },
      );

      const neighborhoodsId = neighborhoodsSelected.map(
        (neighborhood) => neighborhood.id,
      );

      await apiAxios.delete('/neighborhood', {
        data: {
          neighborhoords: neighborhoodsId,
        },
      });

      const neighborhoodsNotSelected = currentNeighborhoods.filter(
        (neighborhood) => {
          return neighborhood.isSelected === false;
        },
      );

      toast.success('Neighborhood successfully removed.');

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              neighborhoods: neighborhoodsNotSelected,
            },
          };
        },
      ) as Return;
    } catch (error) {
      const { response } = error as ErrorMessage2;

      toast.error(response.data.msg);
    }
  }

  async function onDeleteDeviceCnt() {
    try {
      const currentDeviceCnt = queryData.event.device_cnt;

      const getAllDeviceCntSelected = currentDeviceCnt.filter((device) => {
        return device.isSelected === true;
      });

      const parsedDeviceCntSelected = getAllDeviceCntSelected.map((device) => {
        return device.id;
      });

      await apiAxios.delete('/device-cnt', {
        data: {
          devices: parsedDeviceCntSelected,
        },
      });

      const getAllDeviceCntNotSelected = queryData.event.device_cnt.filter(
        (device) => {
          return device.isSelected === false;
        },
      );

      toast.success('Device Group successfully removed.');

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              device_cnt: getAllDeviceCntNotSelected,
            },
          };
        },
      ) as Return;
    } catch (error) {
      const { response } = error as ErrorMessage2;
      toast.error(response.data.msg);
    }
  }

  return {
    onDeleteState,
    onDeleteCity,
    onDeleteNeighborhood,
    onDeleteDeviceCnt,
  };
}
