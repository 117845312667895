import {
  ModalOverlay,
  ModalContent as ChakraModalContent,
  ModalHeader,
  Box,
  Text,
  ModalCloseButton,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface ModalContent {
  children: ReactNode;
  name: string;
}

export function ModalContent({ children, name }: ModalContent) {
  return (
    <>
      <ModalOverlay />
      <ChakraModalContent
        minH="34.37rem"
        overflow="hidden"
        rounded="1.25rem"
        mx="1rem"
      >
        <ModalHeader py="0.5625rem">
          <Box
            justifyContent="space-between"
            w="100%"
            borderBottom="0.0625rem solid #E5E7EA"
            my="1.25rem"
          >
            <Text
              color="gray.700"
              fontWeight="600"
              fontSize={{ base: '1rem', md: '1.25rem' }}
            >
              Adicionar {name}
            </Text>

            <Text fontSize={{ base: '0.875rem', md: '1rem' }} fontWeight="500">
              Locais e Equipamentos
            </Text>
          </Box>
        </ModalHeader>

        {children}

        <ModalCloseButton />
      </ChakraModalContent>
    </>
  );
}
