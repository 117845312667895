import { Box, Divider, Heading } from '@chakra-ui/react';

import { Publication } from './Publication';
import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';

export function PastPublications() {
  const { queryData } = useGetEventDetailToUpdate();

  const hasHistory = queryData.event.history_events.length > 0;

  if (hasHistory) {
    return (
      <Box>
        <Heading
          fontSize={{ base: '1rem', lg: '1rem' }}
          fontWeight="600"
          color="gray.700"
          mt="3.9375rem"
        >
          Publicações anteriores
        </Heading>

        <Box mt="1.625rem">
          {queryData.event.history_events.map((history) => (
            <Publication key={history.id} history={history} />
          ))}
        </Box>

        <Divider mt="1.5625rem" />
      </Box>
    );
  }
}
