import { Flex, TabPanels, Tabs, Spinner, Center } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { parseCookies } from 'nookies';

import { apiReactQuery } from '@/lib/axios/apiReactQuery';
import { FormCreateEventProvider } from '@/contexts/formCreateEvent';

import { ListSteps } from './Step/ListSteps';
import { TimelineStep } from './Step/TimelineStep';
import { AboutEvent } from './AboutEvent';
import { Stories } from './Stories';
import { LocationEquipment } from './LocationEquipment';
import { ImageEvent } from './ImageEvent';
import { SuccessCard } from './SuccessCard';
import { useCreateEventStore } from '@store/create-event';

export function CreateEvent() {
  const { tabIndex, onChangeTab, hasEventWasCreated, onSaveParameters } =
    useCreateEventStore((state) => state);

  const { isLoading } = useQuery({
    queryKey: ['create-event-params'],
    queryFn: async () => {
      try {
        const { '@event-monitor-web:token-1.0.1': token } = parseCookies();
        const response = await apiReactQuery.get('event/parameters', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        onSaveParameters(response.data);

        return {
          parameters: response.data,
        };
      } catch (error) {
        const { message } = error as Error;

        toast.error(message);
      }
    },
  });

  if (isLoading) {
    return (
      <Center w="100%" bg="white" h="100vh">
        <Spinner color="blue.500" size="lg" />
      </Center>
    );
  }

  if (hasEventWasCreated) {
    return (
      <FormCreateEventProvider>
        <SuccessCard />
      </FormCreateEventProvider>
    );
  }

  return (
    <FormCreateEventProvider>
      <Flex>
        <Tabs
          display={{ base: 'none', lg: 'flex' }}
          position="relative"
          index={tabIndex}
          onChange={onChangeTab}
          variant="unstyled"
        >
          <ListSteps />
        </Tabs>

        <Flex>
          <Tabs
            index={tabIndex}
            bg="white"
            w={{ base: '100%', lg: 'calc(100% - 15.8125rem - 6.5rem)' }}
            display="flex"
            position="fixed"
            overflowY="scroll"
            h="100%"
            py={{ base: '0', lg: '5.625rem' }}
            px={{ base: '2rem', xl: '4rem' }}
            flexDir="column"
          >
            <TimelineStep />

            <TabPanels>
              <AboutEvent />

              <Stories />

              <LocationEquipment />

              <ImageEvent />
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
    </FormCreateEventProvider>
  );
}
