import { useUpdateEventStore } from '@/store/update-event';
import { Button, Flex, Tag, Text } from '@chakra-ui/react';
import { memo } from 'react';

import { Modal } from './Modal';
import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';

function DeviceCntComponent() {
  const { queryData } = useGetEventDetailToUpdate();

  const { onToggleModal } = useUpdateEventStore((state) => state);

  const firstFiveDeviceCnt = queryData.event.device_cnt.slice(0, 5);

  return (
    <Flex
      flexDirection="column"
      w="100%"
      _notLast={{ borderBottom: '0.0625rem solid #E5E7EA' }}
    >
      <Flex align="center" my="1.875rem" justifyContent="space-between">
        <Text fontSize="1rem" fontWeight="400" color="gray.700">
          Id Device CNT
        </Text>

        <Modal />
      </Flex>

      {queryData.event.device_cnt.length > 0 && (
        <Flex
          mt="-1.875rem"
          mb="1.875rem"
          rowGap="1rem"
          columnGap="0.25rem"
          flexWrap="wrap"
          align="center"
        >
          {firstFiveDeviceCnt.map((data) => (
            <Tag
              key={data.name}
              bg="blue.500"
              color="white"
              rounded="full"
              fontSize="1rem"
            >
              {data.description || data.name}
            </Tag>
          ))}

          {queryData.event.device_cnt.length >= 5 && (
            <Button
              color="blue.500"
              fontWeight="400"
              bg="transparent"
              h="100%"
              _hover={{ textDecoration: 'underline' }}
              onClick={() =>
                onToggleModal({
                  modalName: 'deviceCnt',
                  isOpen: true,
                })
              }
            >
              Ver mais
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  );
}

export const DeviceCnt = memo(DeviceCntComponent);
