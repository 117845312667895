import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';

import { WhiteBox } from './WhiteBox';
import { Stepper } from './Stepper';
import { Images } from './Images';
import { IncidentResolved } from './IncidentResolved';
import { NotFoundIncident } from './NotFoundIncident';

import { useGetPublicEvent } from '@/hooks/queries/get-public-event';

export function PublicEvent() {
  const { data, isLoading } = useGetPublicEvent();

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner color="blue.500" size="xl" />
      </Center>
    );
  }

  if (data.event === null) {
    return <NotFoundIncident />;
  }

  if (data.isIncidentResolved === true) {
    return <IncidentResolved company={data.event.companies} />;
  }

  return (
    <Box as="main" px={{ base: '2rem', md: '4rem' }}>
      <VStack
        w={{ base: '100%', xl: '68.75rem' }}
        margin={{ base: '1.6875rem auto', lg: '5.625rem auto' }}
        spacing={{ base: '2rem', lg: '4.1875rem' }}
      >
        <WhiteBox>
          <Flex
            width={{ base: '6.625rem', md: '7.5rem' }}
            height={{ base: '3.0625rem', md: '3.5rem' }}
            mb={{ base: '2.125rem', md: '1.4375rem' }}
          >
            <Image
              src={data.event.companies.url_logo}
              alt={`logo da ${data.event.companies.name}`}
            />
          </Flex>

          <Heading
            color="gray.700"
            fontSize={{ base: '1rem', md: '1.375rem' }}
            fontWeight="500"
          >
            {data.event.name}
          </Heading>

          <Text mt="0.25rem" fontSize=" ">
            {data.event.description}
          </Text>
        </WhiteBox>

        <WhiteBox>
          <Heading
            color="gray.900"
            fontSize={{ base: '1rem', md: '1.625rem' }}
            fontWeight={{ base: '700', md: '500' }}
            mb={{ base: '2rem', md: '0' }}
          >
            Histórico de Evento
          </Heading>

          <Stepper stories={data.event.history_events} />
        </WhiteBox>

        {data.event.images.length > 0 && (
          <WhiteBox>
            <Images images={data.event.images} />
          </WhiteBox>
        )}
      </VStack>
    </Box>
  );
}
