import { usePreviousEventStore } from '@store/previous-event';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { IoOptionsOutline } from 'react-icons/io5';

export function DrawerFilter() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentFilterByEvent } = usePreviousEventStore((state) => state);

  const [company, setCompany] = useState('');
  const [sla, setSla] = useState('');
  const [category, setCategory] = useState('');

  function handleSetEventAndCloseModal() {
    currentFilterByEvent(company, sla, category);
    onClose();
  }

  return (
    <>
      <Button bg="white" onClick={onOpen}>
        <Icon as={IoOptionsOutline} color="blue.500" fontSize="1.5rem" />
      </Button>

      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} size="xl">
        <DrawerOverlay />
        <DrawerContent roundedTop="2rem">
          <DrawerHeader color="gray.700">Empresa</DrawerHeader>
          <DrawerBody>
            <RadioGroup onChange={setCompany} value={company}>
              <Stack spacing={3}>
                <Radio value="" defaultChecked>
                  Todas
                </Radio>

                <Radio value="Intlink">Intlink</Radio>

                <Radio value="Icom">Icom Telecom</Radio>

                <Radio value="Fiber Network">Fiber Network</Radio>

                <Radio value="Wire">Wire</Radio>
              </Stack>
            </RadioGroup>

            <DrawerHeader color="gray.700" px="0">
              SLA
            </DrawerHeader>
            <RadioGroup onChange={setSla} value={sla}>
              <Stack spacing={3}>
                <Radio value="" defaultChecked>
                  Todos
                </Radio>

                <Radio value="on_time">No prazo</Radio>

                <Radio value="lated">Expirado</Radio>
              </Stack>
            </RadioGroup>

            <DrawerHeader color="gray.700" px="0">
              Categoria
            </DrawerHeader>
            <RadioGroup onChange={setCategory} value={category}>
              <Stack spacing={3}>
                <Radio value="" defaultChecked>
                  Todas
                </Radio>

                <Radio value="Degradação">Degradação</Radio>

                <Radio value="Indisponibilidade">Indisponibilidade</Radio>
              </Stack>
            </RadioGroup>
          </DrawerBody>

          <DrawerFooter>
            <Button
              type="submit"
              bg="blue.500"
              color="white"
              onClick={handleSetEventAndCloseModal}
            >
              Aplicar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
