import { Button } from '@chakra-ui/react';

import { signOut } from '@/utils/signOut';

export function LinksAsideRedirect() {
  return (
    <Button w="100%" mt="20" fontSize="lg" color="gray.400" onClick={signOut}>
      Sair
    </Button>
  );
}
