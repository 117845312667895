import {
  Box,
  Flex,
  FormControl,
  Tag,
  Text,
  TagLabel,
  TagCloseButton,
  Select,
} from '@chakra-ui/react';

import { TemplateModal } from '../TemplateModal';
import { useCreateEventStore } from '@store/create-event';

interface ModalProps {
  disclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
}

export function Modal({ disclosure }: ModalProps) {
  const {
    states,
    onAddState,
    onRemoveState,
    nations,
    selectedCompany,
    onResetState,
  } = useCreateEventStore((state) => state);

  const isExistsSelectedCompany = !!selectedCompany.id;

  return (
    <TemplateModal name="Estado" reset={onResetState} disclosure={disclosure}>
      <FormControl display="flex" alignItems="center">
        {isExistsSelectedCompany && (
          <Flex w="100%" gap="1.125rem">
            <Select
              placeholder="Informe o Estado"
              onChange={(event) => onAddState(event.target.value)}
            >
              {nations.map((nation) => {
                return (
                  <option key={nation.id} value={JSON.stringify(nation)}>
                    {nation.name}
                  </option>
                );
              })}
            </Select>
          </Flex>
        )}
      </FormControl>

      {states.length > 0 && (
        <Box mt="2.5rem">
          <Text fontSize="1rem">Estados adicionados</Text>

          <Flex
            mt="0.75rem"
            rowGap="1rem"
            columnGap="0.25rem"
            flexWrap="wrap"
            overflowY="scroll"
            maxH="15.625rem"
            css={{
              '&::-webkit-scrollbar': {
                width: '0.3125rem',
              },
              '&::-webkit-scrollbar-track': {
                width: '0.375rem',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'var(--chakra-colors-gray-300);',
                borderRadius: '0.625rem',
              },
            }}
          >
            {states.map((state) => {
              return (
                <Tag
                  key={state.name}
                  bg="blue.500"
                  color="white"
                  rounded="full"
                  h="1.5625rem"
                >
                  <TagLabel>{state.name}</TagLabel>
                  <TagCloseButton
                    bg="#FFFFFF"
                    opacity="1"
                    w="0.9375rem"
                    h="0.9375rem"
                    color="blue.500"
                    fontSize="0.625rem"
                    ml="0.5rem"
                    onClick={() => onRemoveState(state.id)}
                  />
                </Tag>
              );
            })}
          </Flex>
        </Box>
      )}
    </TemplateModal>
  );
}
