import { useLocation, useNavigate } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { EventDetailsLayout } from '@/layouts/EventDetailsLayout';
import { LocationEvent } from './types';
import { useEffect } from 'react';
import { useGetEventDetail } from '@/hooks/queries/get-event-detail';

export function PortalDetails() {
  const location: LocationEvent = useLocation();
  const idEvent = location.state ? location.state.idEvent : null;
  const navigate = useNavigate();

  const { isLoading } = useGetEventDetail({ id: idEvent, location: 'portal' });

  useEffect(() => {
    if (!idEvent) {
      navigate('/dashboard');
    }
  }, [idEvent, navigate]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Flex
      px={{ base: '1.5rem', md: '4rem' }}
      my="6"
      mx="auto"
      flexDirection="column"
      w={{ base: '100%', '2xl': '88.75rem' }}
    >
      <EventDetailsLayout />
    </Flex>
  );
}
