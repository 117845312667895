import { Tag } from '@chakra-ui/react';

export interface StoryEvent {
  status: {
    name: string;
  };
  theme: {
    bg: string;
    color: string;
  };
}

/* interface StatusProps {
  story: StoryEvent[];
} */

interface StatusProps {
  status: {
    currentStatus: string;
    theme: {
      bg: string;
      color: string;
    };
  };
}

export function Status({ status }: StatusProps) {
  return (
    <Tag
      bg={status.theme.bg}
      color={status.theme.color}
      py={{ base: '0.2rem', lg: '0.635rem' }}
      w={{ base: '5.5rem', lg: '8.438rem' }}
      fontWeight="400"
      fontSize="1rem"
      alignItems="center"
      justifyContent="center"
    >
      {status.currentStatus}
    </Tag>
  );
}
