import {
  Flex,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react';

import { SkeletonBoxPortal } from './SkeletonBoxPortal';
import { SkeletonTrBodyPortal } from './SkeletonTrBodyPortal';

export function Skeleton() {
  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      ssr: false,
    },
  );

  return (
    <>
      {isMobile ? (
        <Flex mt="1.25rem" flexDirection="column" gap="1.187rem">
          <SkeletonBoxPortal />
          <SkeletonBoxPortal />
        </Flex>
      ) : (
        <TableContainer>
          <Table bg="white" mt="0.75rem" rounded="0.5rem" variant="unstyled">
            <Thead>
              <Tr>
                <Th
                  color="blue.500"
                  textTransform="capitalize"
                  fontSize="1rem"
                  fontWeight="600"
                  py="1rem"
                >
                  Nome do Evento
                </Th>
                <Th
                  color="blue.500"
                  textTransform="capitalize"
                  fontSize="1rem"
                  fontWeight="600"
                >
                  Empresa
                </Th>
                <Th
                  color="blue.500"
                  textTransform="capitalize"
                  fontSize="1rem"
                  fontWeight="600"
                >
                  Criado em
                </Th>
                <Th
                  color="blue.500"
                  textTransform="capitalize"
                  fontSize="1rem"
                  fontWeight="600"
                >
                  Status
                </Th>
                <Th />
              </Tr>
            </Thead>

            <Tbody>
              <SkeletonTrBodyPortal />
              <SkeletonTrBodyPortal />
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
