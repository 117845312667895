export const createRangeArray = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => {
    return { name: String(start + i * step) };
  });

export const createRangeDeviceId = (
  start: number,
  stop: number,
  step: number,
  storeId: string,
) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => {
    return { name: String(start + i * step), store_id: storeId };
  });

export const createRangeDeviceName = (
  start: number,
  stop: number,
  step: number,
) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => {
    return String(start + i * step);
  });
