import { SmallCloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  IconButton,
  Image,
  Text,
  ButtonProps,
} from '@chakra-ui/react';

interface CardImageWithNameProps extends ButtonProps {
  data: {
    imageUrl: string;
    lastModified?: number;
    name: string;
    size?: string;
  };
  opacityCard?: number;
  isShowSize: boolean;
}

export function CardImageWithName({
  data,
  isShowSize,
  opacityCard = 1,
  ...rest
}: CardImageWithNameProps) {
  return (
    <Flex
      bg="white"
      p="0.875rem"
      h="100px"
      align="center"
      rounded="0.25rem"
      border="1px solid #E5E7EA"
      position="relative"
      gap="0.562rem"
      opacity={opacityCard}
    >
      <Flex w="3rem" h="3rem">
        <Image
          src={data.imageUrl}
          alt={data.name}
          objectFit="cover"
          h="100%"
          w="100%"
          rounded="0.375rem"
        />
      </Flex>

      <Box w="80%">
        <Text color="gray.700" fontWeight="500" fontSize="1rem">
          {data.name}
        </Text>

        {isShowSize && (
          <Text fontSize="0.625rem" color="gray.400">
            {data.size}
          </Text>
        )}
      </Box>

      <IconButton
        position="absolute"
        right="0.6875rem"
        bg="gray.700"
        aria-label="Remove Selected Image"
        rounded="full"
        icon={<SmallCloseIcon fontSize="0.8rem" color="white" />}
        minW="1rem"
        w="1rem"
        h="1rem"
        ml="1.25rem"
        {...rest}
      />
    </Flex>
  );
}
