import { Box, Button, Flex, Tag, Text } from '@chakra-ui/react';
import { memo } from 'react';

import { Modal } from './Modal';
import { useUpdateEventStore } from '@/store/update-event';
import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';

function FederativeUnitComponent() {
  const { onToggleModal } = useUpdateEventStore((state) => state);

  const { queryData } = useGetEventDetailToUpdate();

  const firstFiveFederativeUnits = queryData.event.states.slice(0, 5);

  return (
    <Box _notLast={{ borderBottom: '0.0625rem solid #E5E7EA' }}>
      <Flex align="center" my="1.875rem" justifyContent="space-between">
        <Text fontSize="1rem" fontWeight="400" color="gray.700">
          Estado
        </Text>

        <Modal />
      </Flex>

      {queryData.event.states.length > 0 && (
        <Flex
          mt="-1.875rem"
          mb="1.875rem"
          rowGap="1rem"
          columnGap="0.25rem"
          flexWrap="wrap"
          align="center"
        >
          {firstFiveFederativeUnits.map((data) => (
            <Tag
              key={data.name}
              bg="blue.500"
              color="white"
              rounded="full"
              fontSize="1rem"
            >
              {data.name}
            </Tag>
          ))}

          {firstFiveFederativeUnits.length === 5 && (
            <Button
              color="blue.500"
              fontWeight="400"
              bg="transparent"
              h="100%"
              _hover={{ textDecoration: 'underline' }}
              onClick={() =>
                onToggleModal({
                  modalName: 'federativeUnit',
                  isOpen: true,
                })
              }
            >
              Ver mais
            </Button>
          )}
        </Flex>
      )}
    </Box>
  );
}

export const FederativeUnit = memo(FederativeUnitComponent);
