import { AvatarLogo } from '@/components/AvatarLogo';
import { Grid, StatGroup, Stat, StatNumber, StatLabel } from '@chakra-ui/react';
import { useGetStats } from '@/hooks/queries/get-stats';

export function ShowTotalEventsByCompany() {
  const { queryData } = useGetStats();

  return (
    <StatGroup>
      <Grid
        templateColumns={{
          base: 'repeat(2, 8rem)',
          md: 'repeat(2, 9rem)',
          lg: 'repeat(4, 9rem)',
        }}
        templateRows={{
          base: 'repeat(2, 8rem)',
          md: 'repeat(2, 9rem)',
          lg: 'repeat(1, 9rem)',
        }}
        gap={8}
        alignItems="center"
      >
        {queryData.stats.totalEventsByCompanies.map((event) => {
          return (
            <Stat
              key={event.company}
              rounded="1.25rem"
              bg="blue.800"
              position="relative"
              display="flex"
              justifyContent="center"
              py="1.7188rem"
              px={{ base: '1rem', lg: '1.3438rem' }}
              maxHeight="153x"
              maxWidth="150px"
              textAlign="center"
            >
              <AvatarLogo
                url={event.url_small_logo}
                name={event.company}
                size={{ base: '2rem', md: '2.3rem' }}
                position="absolute"
                top="-1.25rem"
                left="50%"
                transform="translateX(-50%)"
              />

              <StatLabel fontSize="1rem" color="white" fontWeight="400">
                {event.company}
              </StatLabel>

              <StatNumber
                fontSize={{ base: '3rem', lg: '3.75rem' }}
                lineHeight="3.75rem"
                color="blue.500"
                mt="0.375rem"
              >
                {event.total}
              </StatNumber>
            </Stat>
          );
        })}
      </Grid>
    </StatGroup>
  );
}
