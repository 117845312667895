import { apiReactQuery } from '@/lib/axios/apiReactQuery';
import { useTicketEventStore } from '@/store/ticket-event';
import { calculatePercentageProtocolOpening } from '@/utils/calculatePercentageProtocolOpening';
import { useQuery } from '@tanstack/react-query';

interface StatsItem {
  total_tickets: number;
  total_protocols: number;
}

export function useGetTotalTicketsProtocols() {
  const { intervalDates } = useTicketEventStore((state) => state);

  const query = useQuery({
    queryKey: ['tickets-protocols'],
    queryFn: async () => {
      const { data } = await apiReactQuery.get(
        `/ticket?search=&limit=5&page=1&filter=&initial_date=${intervalDates.initialDate}&end_date=${intervalDates.endDate}`,
      );

      const ticketsPerCompany = data.stats.reduce(
        (acc: number, item: StatsItem) => acc + item.total_tickets,
        0,
      );

      const protocolsPerCompany = data.stats.reduce(
        (acc: number, item: StatsItem) => acc + item.total_protocols,
        0,
      );

      const percentageBetweenTicketProtocols =
        calculatePercentageProtocolOpening({
          totalTickets: ticketsPerCompany,
          totalProtocols: protocolsPerCompany,
        });

      return {
        stats: data.stats,
        total: {
          tickets: ticketsPerCompany,
          protocols: protocolsPerCompany,
          percents: percentageBetweenTicketProtocols,
        },
      };
    },
  });

  return query;
}
