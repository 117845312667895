import {
  Button,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react';
import { SelectHistoryStatus } from './SelectHistoryStatus';
import { TextareaDescription } from './TextareaDescription';
import { useReopenEventStore } from '@/store/reopen-event';
import { useCreateHistory } from '@/hooks/mutations/create-history';
import { useGetParameters } from '@/hooks/queries/get-parameters';

export function ModalUpdateContent() {
  const { data, isLoading } = useGetParameters();

  const {
    historyDescription,
    selectedStatus,
    onToggleModal,
    onChangeEventReopenedSuccessfully,
  } = useReopenEventStore((state) => state);

  const { mutateAsync } = useCreateHistory();

  const isStatusAndDescriptionValid =
    selectedStatus.id && historyDescription.length >= 10;

  async function handleCreateNewHistory() {
    const response = await mutateAsync();

    if (response.historyEvent) {
      return onChangeEventReopenedSuccessfully(true);
    }

    return;
  }

  if (!isLoading) {
    return (
      <ModalContent
        minH={{ base: '23.75rem', sm: '26.3125rem' }}
        height={{ base: '23.75rem', sm: '26.3125rem' }}
        width={{ base: '19.5rem', sm: '38.9375rem' }}
        overflow="hidden"
        rounded="1.25rem"
        mx="1rem"
      >
        <ModalCloseButton
          top="1.5rem"
          right={{ base: '1rem', md: '2rem' }}
          width={{ base: '1.5rem', md: '2rem' }}
          height={{ base: '1.5rem', md: '2rem' }}
        />

        <ModalHeader pb="0" pt="1.5rem" px={{ base: '1.5rem', md: '2rem' }}>
          <Text
            color="gray.700"
            fontWeight="500"
            fontSize={{ base: '1rem', sm: '1.125rem' }}
            lineHeight={{ base: '1.21rem', md: '1.3613rem' }}
          >
            Selecionar status
          </Text>

          <Text
            color="gray.400"
            fontWeight="400"
            fontSize={{ base: '0.875rem', sm: '1rem' }}
            lineHeight={{ base: '1.0588rem', md: '1.21rem' }}
          >
            Reabrir evento
          </Text>

          <Divider borderColor="#E5E7EA" mt={{ base: '1rem', sm: '1.25rem' }} />
        </ModalHeader>

        <ModalBody
          pt={{ base: '1.5rem', sm: '2rem' }}
          px={{ base: '1.5rem', md: '2rem' }}
        >
          <SelectHistoryStatus stories={data.parameters.history_status} />
          <TextareaDescription />
        </ModalBody>

        <ModalFooter
          bg="#EBEFF2"
          gap="1.0625rem"
          height={{ base: '4rem', sm: '5rem' }}
          justifyContent={{ base: 'space-between', sm: 'end' }}
          px={{ base: '1.5rem', md: '2rem' }}
        >
          <Button
            background="white"
            color="blue.500"
            w="7rem"
            h="2rem"
            rounded="0.25rem"
            fontWeight="400"
            onClick={() => onToggleModal(false)}
          >
            Cancelar
          </Button>

          <Button
            isDisabled={!isStatusAndDescriptionValid}
            colorScheme="blue"
            w="7rem"
            h="2rem"
            rounded="0.25rem"
            color="white"
            bg="blue.500"
            fontWeight="400"
            onClick={handleCreateNewHistory}
          >
            Reabrir
          </Button>
        </ModalFooter>
      </ModalContent>
    );
  }
}
