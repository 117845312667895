import {
  Box,
  Button,
  Flex,
  FormControl,
  ModalBody,
  ModalFooter,
  Text,
  Select,
  Modal as ChakraModal,
} from '@chakra-ui/react';

import { useUpdateEventStore } from '@/store/update-event';
import { TagButton } from '@/components/Modal/ModalLocationEquipment/TagButton';
import { FlexWrapTag } from '@/components/Modal/ModalLocationEquipment/FlexWrapTag';
import { CreateOrEditModalButton } from '@/components/Modal/ModalLocationEquipment/CreateOrEditModalButton';
import { ModalContent } from '@/components/Modal/ModalLocationEquipment/ModalContent';
import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';
import {
  useSelectMultiple,
  useAddEventDetails,
  useDeleteEventDetails,
} from '@/hooks/queries/get-event-detail-to-update/query-updater';

export function Modal() {
  const { onToggleModal, modals } = useUpdateEventStore((state) => state);

  const { queryData } = useGetEventDetailToUpdate();

  const { onSelectMultipleStates } = useSelectMultiple();
  const { onAddNewState } = useAddEventDetails();
  const { onDeleteState } = useDeleteEventDetails();

  const hasFederativeUnitSelected = queryData.event.states.some((item) => {
    return item.isSelected === true;
  });

  return (
    <>
      <CreateOrEditModalButton
        data={queryData.event.states}
        onOpenModalClick={() =>
          onToggleModal({
            modalName: 'federativeUnit',
            isOpen: true,
          })
        }
        type="update"
      />

      <ChakraModal
        isOpen={modals.federativeUnit.isOpen}
        onClose={() =>
          onToggleModal({
            modalName: 'federativeUnit',
            isOpen: false,
          })
        }
        size="2xl"
        isCentered
      >
        <ModalContent name="Estado">
          <ModalBody>
            <Flex flexDirection="column">
              <FormControl display="flex" alignItems="center">
                <Flex w="100%" gap="1.125rem">
                  <Select
                    bg="white"
                    placeholder="Informe o Estado"
                    onChange={(e) => onAddNewState(e, queryData.event.id)}
                  >
                    {queryData.parameters.states.map((state) => {
                      return (
                        <option key={state.id} value={JSON.stringify(state)}>
                          {state.name}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
              </FormControl>

              {queryData.event.states.length > 0 && (
                <Box mt="2.5rem">
                  <Text fontSize="1rem">Estados publicados</Text>

                  <FlexWrapTag>
                    {queryData.event.states.map((state) => {
                      return (
                        <TagButton
                          key={state.id}
                          name={state.name}
                          isSelected={state.isSelected}
                          onClick={() => onSelectMultipleStates(state)}
                        />
                      );
                    })}
                  </FlexWrapTag>
                </Box>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter bg="gray.200" alignContent="end" gap="1rem">
            {hasFederativeUnitSelected && (
              <Button
                bg="red.500"
                w="6.18rem"
                h="2rem"
                rounded="0.625rem"
                color="white"
                fontWeight="400"
                onClick={onDeleteState}
              >
                Remover
              </Button>
            )}

            <Button
              colorScheme="blue"
              onClick={() =>
                onToggleModal({
                  modalName: 'federativeUnit',
                  isOpen: false,
                })
              }
              w="6.18rem"
              h="2rem"
              rounded="0.625rem"
              color="white"
              bg="blue.500"
              fontWeight="400"
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
}
