import { ListItem, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface InfoProps {
  name: string;
  description?: string;
  children?: ReactNode;
}

export function Info({ name, description, children }: InfoProps) {
  return (
    <ListItem display="flex" justifyContent="space-between" alignItems="center">
      <Text as="b" fontWeight="500" color="gray.900">
        {name}
      </Text>

      {description && (
        <Text w="9rem" alignItems="flex-start" color="gray.400" as="span">
          {description}
        </Text>
      )}

      {children}
    </ListItem>
  );
}
