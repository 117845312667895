import {
  Tag,
  TagCloseButton,
  TagLabel,
  TagCloseButtonProps,
} from '@chakra-ui/react';

interface TagRemoveDataButtonProps extends TagCloseButtonProps {
  isSelected?: boolean;
  name: string;
  description?: string;
}

export function TagButton({
  isSelected,
  name,
  description,
  ...rest
}: TagRemoveDataButtonProps) {
  return (
    <Tag
      bg={isSelected ? 'red.500' : 'blue.500'}
      color="white"
      rounded="full"
      h="1.5625rem"
    >
      <TagLabel>{description || name}</TagLabel>
      <TagCloseButton
        bg="#FFFFFF"
        opacity="1"
        w="0.9375rem"
        h="0.9375rem"
        color="blue.500"
        fontSize="0.625rem"
        ml="0.5rem"
        {...rest}
      />
    </Tag>
  );
}
