import { usePortalEventStore } from '@store/portal-event';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';

export function GroupCategory() {
  const { selectedCategory, changeCategory } = usePortalEventStore((state) => {
    const { selectedCategory, changeCategory } = state;

    return { selectedCategory, changeCategory };
  });

  return (
    <RadioGroup value={selectedCategory} onChange={(e) => changeCategory(e)}>
      <Stack spacing={3}>
        <Radio value="" defaultChecked>
          Todas
        </Radio>

        <Radio value="degradação">Degradação</Radio>
        <Radio value="indisponibilidade">Indisponibilidade</Radio>
      </Stack>
    </RadioGroup>
  );
}
