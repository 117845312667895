import {
  Button,
  Divider,
  Flex,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import { useCreateEventStore } from '@store/create-event';

export function TimelineStep() {
  const { steps, onChangeTab, tabIndex, currentTabIndex } = useCreateEventStore(
    (state) => state,
  );

  const isMobile = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    {
      ssr: false,
    },
  );

  if (isMobile) {
    return (
      <Flex flexDirection="column">
        <Flex
          mt="2rem"
          justifyContent="space-between"
          position="relative"
          h="1.5rem"
        >
          {steps.map((step, index) => {
            return (
              <Button
                key={step.name}
                rounded="full"
                p="0"
                h="100%"
                zIndex="1"
                fontWeight="300"
                minW="1.5rem"
                fontSize="1rem"
                onClick={() => onChangeTab(index)}
                border={tabIndex === index ? '1px solid #3F7EF8' : 'none'}
                _disabled={{ background: 'gray.400', color: 'white' }}
                isDisabled={
                  (!step.isCompleted && step.id !== currentTabIndex) ||
                  (step.isFinished && step.id !== currentTabIndex)
                }
                _hover={{}}
                bg={tabIndex === index ? 'white' : 'blue.500'}
                color={tabIndex === index ? 'blue.500' : 'white'}
                variant="solid"
              >
                {index + 1}
              </Button>
            );
          })}

          <Divider position="absolute" bottom="50%" zIndex="0" />
        </Flex>

        <Text mt="0.8125rem" fontSize="0.625rem">
          Criando novo Evento
        </Text>
        <Text fontSize="1rem" color="gray.700" fontWeight="500" mb="2rem">
          {steps[tabIndex].name}
        </Text>
      </Flex>
    );
  }
}
