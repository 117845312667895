import { Flex, HStack, Input, Text } from '@chakra-ui/react';

import { usePortalEventStore } from '@store/portal-event';

export function SelectIntervalDates() {
  const { intervalDates, saveIntervalDates } = usePortalEventStore((state) => {
    const { intervalDates, saveIntervalDates } = state;

    return { intervalDates, saveIntervalDates };
  });

  return (
    <HStack spacing="1.0625rem">
      <Flex align="center" gap="0.625rem">
        <Text>de</Text>

        <Input
          type="datetime-local"
          name="date"
          w="11.8rem"
          value={intervalDates.initialDate}
          onChange={(e) => saveIntervalDates(e.target.value, 'initial')}
        />
      </Flex>

      <Flex align="center" gap="0.625rem">
        <Text>até</Text>
        <Input
          type="datetime-local"
          w="11.8rem"
          value={intervalDates.endDate}
          onChange={(e) => saveIntervalDates(e.target.value, 'end')}
        />
      </Flex>
    </HStack>
  );
}
