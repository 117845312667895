import { useEffect } from 'react';
import {
  Center,
  Flex,
  Spinner,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import { Pagination } from '@/components/Pagination';
import { HeadingOne } from '@/components/Heading';

import { Filter } from './Filter';

import { useTicketEventStore } from '@store/ticket-event';
import { Tickets } from './Tickets';
import { useGetTickets } from '@/hooks/queries/get-tickets';

export function TicketList() {
  const { currentPage, changeCurrentPage, filterParams } = useTicketEventStore(
    (state) => state,
  );

  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      ssr: false,
    },
  );

  const { data, isLoading, refetch } = useGetTickets();

  useEffect(() => {
    changeCurrentPage(1);
    refetch();
  }, [
    filterParams.filterIntervalDates,
    filterParams.filterCompany,
    filterParams.filterSearch,
    changeCurrentPage,
    refetch,
  ]);

  return (
    <Flex flexDir="column">
      <HeadingOne text="Lista de Tickets" />

      <Filter />

      {isLoading ? (
        <Center w="100%" bg="white" h="10rem">
          <Spinner color="blue.500" size="lg" />
        </Center>
      ) : (
        <Tickets />
      )}

      {!isLoading && (
        <>
          {data.pagination.total > 5 && (
            <Flex
              mt="0.75rem"
              px="1.375rem"
              bg="white"
              rounded="0.5rem"
              py="1.25rem"
              justify="space-between"
              align="center"
            >
              {!isMobile && (
                <Text>
                  Visualizando {data.pagination.from}-{data.pagination.to} de{' '}
                  {data.pagination.total} eventos.
                </Text>
              )}

              <Pagination
                totalCountOfRegisters={data.pagination.total}
                currentPage={currentPage}
                setCurrentPage={changeCurrentPage}
                registerPerPage={5}
              />
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
}
