import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Tbody,
  Td,
  Icon,
} from '@chakra-ui/react';

import { FiExternalLink } from 'react-icons/fi';

import { Th } from '@/components/Form/Th';
import { Ticket } from './types';
import { Link } from '@/components/Link';

interface TicketsTableProps {
  tickets: Ticket[];
}

export function TicketsTable({ tickets }: TicketsTableProps) {
  return (
    <TableContainer
      border="1px solid #E5E7EA"
      mt="0.75rem"
      style={{ borderRadius: '0.625rem' }}
    >
      <Table bg="white" rounded="0.5rem">
        <Thead>
          <Tr>
            <Th>Cód. Cliente</Th>
            <Th>Nome</Th>
            <Th>Plataforma</Th>
            <Th>Regra</Th>
            <Th>Horário do atendimento</Th>
            <Th>Protocolo</Th>
            <Th>Visualizar</Th>
          </Tr>
        </Thead>

        <Tbody>
          {tickets.map((ticket) => {
            return (
              <Tr
                key={ticket.id}
                _notFirst={{
                  borderTop: '0.0625rem solid #DFE7F2',
                }}
              >
                <Td py="1.687rem" color="gray.700" fontWeight="500">
                  {ticket.customer.codigo_cliente}
                </Td>
                <Td py="1.687rem" w="30%">
                  {ticket.customer.personal_info.name}
                </Td>
                <Td py="1.687rem">{ticket.platform}</Td>
                <Td py="1.687rem">{ticket.hit_rule}</Td>
                <Td py="1.687rem">{ticket.service_hours}</Td>
                <Td py="1.687rem">{ticket.protocol_id}</Td>

                <Td py="1.687rem">
                  <Link
                    w="2rem"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color="white"
                    _hover={{ textDecoration: 'none' }}
                    to={`/event/details/${ticket.event_id}`}
                    state={{ idEvent: ticket.event_id }}
                  >
                    <Icon as={FiExternalLink} color="blue.500" />
                  </Link>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
