import { Pagination as PaginationComponent } from '@/components/Pagination';
import { usePagination } from '@/hooks/usePagination';
import { usePreviousEventStore } from '@store/previous-event';
import { Flex, Text } from '@chakra-ui/react';

interface PaginationProps {
  pagination: {
    currentPage: number;
    total: number;
    from: number;
    to: number;
  };
}

export function Pagination({ pagination }: PaginationProps) {
  const { currentPage, changeCurrentPage } = usePreviousEventStore(
    (state) => state,
  );

  const { limit } = usePagination();

  const isShowPagination = pagination.total > 10;

  if (isShowPagination) {
    return (
      <Flex
        mt="0.75rem"
        px="1.375rem"
        bg="white"
        rounded="0.5rem"
        py="1.25rem"
        justify="space-between"
      >
        <Text
          display="flex"
          alignItems="center"
          fontSize={{ base: '0.875rem', lg: '1rem' }}
        >
          Visualizando {pagination.from}-{pagination.to} de {pagination.total}{' '}
          eventos.
        </Text>

        <PaginationComponent
          totalCountOfRegisters={pagination.total}
          currentPage={currentPage}
          setCurrentPage={changeCurrentPage}
          registerPerPage={limit}
        />
      </Flex>
    );
  }
}
