import { useFormContext } from 'react-hook-form';

import { GroupRadio } from '@/components/GroupRadio';
import { SubmitNewEvent } from './types';
import { useCreateEventStore } from '@store/create-event';

type SubmitCompany = Pick<SubmitNewEvent, 'companyId'>;

export function CompaniesRadio() {
  const {
    register,
    formState: { errors },
  } = useFormContext<SubmitCompany>();

  const {
    companies,
    companyIdIndex,
    onChangeCompanyIndex,
    onChangeSelectedCompany,
  } = useCreateEventStore((state) => state);

  return (
    <GroupRadio
      title="Empresa"
      description="Escolha a empresa em que está ocorrendo o incidente."
      value={companyIdIndex}
      items={companies}
      type="create"
      isRequired
      error={errors.companyId}
      onChangeValue={onChangeCompanyIndex}
      onSelect={onChangeSelectedCompany}
      register={register}
      registerName="companyId"
    />
  );
}
