import {
  Button,
  Flex,
  Icon,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { IoOptionsOutline } from 'react-icons/io5';

import { GroupCategory } from './GroupCategory';
import { GroupCompany } from './GroupCompany';
import { SearchName } from './SearchName';
import { SelectIntervalDates } from './SelectIntervalDates';
import { Section } from './Section';

import { useGetPortalEvents } from '@/hooks/queries/get-portal-events';

export function Mobile() {
  const { onClose, isOpen, onOpen } = useDisclosure();

  const { refetch } = useGetPortalEvents();

  async function handleApllyFilter() {
    refetch();
    onClose();
  }

  return (
    <Flex justify="space-between" gap="0.56rem" mt="0.625rem">
      <SearchName />

      <Button bg="white" onClick={onOpen}>
        <Icon as={IoOptionsOutline} color="blue.500" fontSize="1.5rem" />
      </Button>

      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} size="xl">
        <DrawerOverlay />
        <DrawerContent roundedTop="2rem">
          <Section title="Empresa">
            <GroupCompany />
          </Section>

          <Section title="Categoria">
            <GroupCategory />
          </Section>

          <Section title="Período">
            <SelectIntervalDates />
          </Section>

          <DrawerFooter>
            <Button
              type="submit"
              bg="blue.500"
              color="white"
              onClick={handleApllyFilter}
            >
              Aplicar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
