import { KeenSliderHooks, KeenSliderInstance } from 'keen-slider/react';
import { MutableRefObject } from 'react';

import { CustomArrow } from './CustomArrow';
import { HStack } from '@chakra-ui/react';

interface ArrowsProps {
  isLoaded: boolean;
  currentSlide: number;
  instanceRef: MutableRefObject<KeenSliderInstance<
    object,
    object,
    KeenSliderHooks
  > | null>;
}

export function Arrows({ isLoaded, instanceRef, currentSlide }: ArrowsProps) {
  if (isLoaded && instanceRef.current) {
    return (
      <HStack>
        <CustomArrow
          size="lg"
          direction="left"
          onClick={() => instanceRef.current?.prev()}
          disabled={currentSlide === 0}
        />

        <CustomArrow
          size="lg"
          direction="right"
          onClick={() => instanceRef.current?.next()}
          disabled={
            currentSlide === instanceRef.current.track.details.slides.length - 1
          }
        />
      </HStack>
    );
  }
}
