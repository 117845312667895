import { ListItem, Box, Heading, Text, HStack } from '@chakra-ui/react';
import { memo } from 'react';
import { Info } from './Info';

import { TicketCardProps } from './types';

const TicketCardComponent = ({ ticket }: TicketCardProps) => {
  return (
    <ListItem
      bg="white"
      rounded="0.5rem"
      w="100%"
      overflow="hidden"
      boxShadow="0rem 0.125rem 0.625rem rgba(0, 0, 0, 0.08)"
    >
      <Box p="1.06rem 1.18rem 1.375rem">
        <HStack justifyContent="space-between">
          <Heading fontSize="1rem" fontWeight="500" color="gray.700">
            {ticket.customer.personal_info.name}
          </Heading>

          <Text fontSize="0.875rem" color="gray.400">
            {ticket.customer.personal_info.name}
          </Text>
        </HStack>

        <Info name="Plataforma" data={ticket.platform} />

        <Info name="Regra" data={ticket.hit_rule} />

        <Info name="Protocolo" data={ticket.protocol_id} />
      </Box>

      <Box as="footer" bg="#F3F7F9" p="1rem 1.18rem">
        <Text fontSize="0.875rem">Ticket criado em {ticket.service_hours}</Text>
      </Box>
    </ListItem>
  );
};

export const TicketCard = memo(TicketCardComponent);
