import { Box } from '@chakra-ui/react';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { EventDetailsLayout } from '@/layouts/EventDetailsLayout';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useGetEventDetail } from '@/hooks/queries/get-event-detail';

export interface LocationEvent extends Location {
  state: {
    idEvent?: string;
  };
}

export function EventDetails() {
  const location: LocationEvent = useLocation();
  const idEvent = location.state ? location.state.idEvent : null;
  const navigate = useNavigate();

  const { isLoading } = useGetEventDetail({
    id: idEvent,
    location: 'internal',
  });

  useEffect(() => {
    if (!idEvent) {
      navigate('/dashboard');
    }
  }, [idEvent, navigate]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box my={{ base: '6', lg: '6' }} px={{ base: '4', md: '8', lg: '10' }}>
      <EventDetailsLayout />
    </Box>
  );
}
