import { apiAxios } from '@/lib/axios/apiAxios';
import { usePreviousEventStore } from '@/store/previous-event';
import { useQuery } from '@tanstack/react-query';
import { Request, Response } from './types';
import { formatElapsedTime } from '@/utils/formatDate';

const ONE_MINUTE = 1000 * 60;
const TEN_MINUTES = ONE_MINUTE * 10;

export function useGetPastEvents() {
  const {
    selectCompany,
    selectedSla,
    selectedCategory,
    nameEvent,
    changeCurrentPage,
    currentPage,
  } = usePreviousEventStore((state) => state);

  const query = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      'previous-events',
      currentPage,
      selectCompany,
      selectedSla,
      selectedCategory,
    ],
    queryFn: async (): Promise<Response> => {
      const { data } = await apiAxios.get<Request>('/event/solved', {
        params: {
          search: nameEvent,
          limit: 10,
          page: currentPage,
          company: selectCompany,
          sla: selectedSla,
          category: selectedCategory,
        },
      });

      const { events, pagination } = data;

      if (events.length <= 0) {
        return {
          events: [],
          pagination,
        };
      }

      const eventsWithElapsedTime = events.map((event) => ({
        ...event,
        elapsedTime: formatElapsedTime(event.created_at),
        history_events: event.history_events.map((historyEvent) => ({
          ...historyEvent,
          elapsedTime: formatElapsedTime(event.created_at),
        })),
      }));

      changeCurrentPage(pagination.currentPage);

      return {
        events: eventsWithElapsedTime,
        pagination,
      };
    },
    staleTime: TEN_MINUTES,
  });

  return query;
}
