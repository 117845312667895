import { Box, Heading, Text } from '@chakra-ui/react';

import { LinksAsideRedirect } from './LinksAsideRedirect';

export function AsideMenu() {
  return (
    <Box
      as="aside"
      display={{ base: 'none', lg: 'block' }}
      bg="blue.900"
      pos="fixed"
      fontSize="sm"
      h="100%"
      zIndex="1000"
      w={{ lg: '17rem', xl: '20rem' }}
      py="6"
      px={{ lg: '7', xl: '9' }}
    >
      <Box>
        <Heading color="gray.400" fontSize="lg" fontWeight="normal">
          Monitoramento
          <Text color="orange.500" fontWeight="bold">
            de Eventos
          </Text>
        </Heading>

        <LinksAsideRedirect />
      </Box>
    </Box>
  );
}
