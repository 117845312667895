import { StateCreator } from 'zustand';
import { toast } from 'react-toastify';

import {
  FederativeUnitState,
  FederativeUnitActions,
} from '@store/create-event/@types/federative-unit';
import { initialFederativeUnitState } from '@store/create-event/initial-state/federative-unit';

export const federativeUnitSlice: StateCreator<
  FederativeUnitState & FederativeUnitActions
> = (set, get) => ({
  ...initialFederativeUnitState,
  onAddState: (state) => {
    const newState = JSON.parse(state);

    const isExistsState = get().states.some(
      (state) => state.id === newState.id,
    );

    if (isExistsState) {
      return toast.warning(`${newState.name} já existe`);
    }

    set((state) => ({
      states: [
        ...state.states,
        {
          id: newState.id,
          name: newState.name,
        },
      ],
    }));

    toast.success(`${newState.name} adicionado`);
  },
  onRemoveState: (id) => {
    const currentState = get().states;

    const remainingStates = currentState.filter((state) => state.id !== id);

    set({ states: remainingStates });

    toast.success(`Estado removido`);
  },
  onResetState: () => {
    set({ states: [] });
  },
});
