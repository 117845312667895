import { createRangeDeviceId } from '@/utils/createRangeArray';
import { ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import { StateCreator } from 'zustand';
import { initialDeviceCntState } from '@store/create-event/initial-state/device-cnt';
import {
  DeviceCntState,
  DevicesCntActions,
} from '@store/create-event/@types/device-cnt';

const maximumNumberOfDeviceId = 63; // total 64

export const deviceCntSlice: StateCreator<
  DeviceCntState & DevicesCntActions
> = (set, get) => ({
  ...initialDeviceCntState,
  onAddDeviceCnt: ({ event, storeId }) => {
    const eventValue = event as unknown as ChangeEvent<HTMLInputElement>;

    if (event.key !== 'Enter') return;

    event.preventDefault();
    const value = eventValue.target.value.trim();

    if (!value) return;

    const isRange = value.includes('-');
    const deviceIds = value.split('-').map(Number);

    if (deviceIds.some(Number.isNaN)) {
      return toast.error('Insira apenas números');
    }

    if (!isRange) {
      const [deviceCntFrom] = deviceIds;

      if (
        get().deviceCnt.some((device) => device.name === String(deviceCntFrom))
      ) {
        return toast.error(`${deviceCntFrom} já existe`);
      }

      set((state) => ({
        deviceCnt: [
          ...state.deviceCnt,
          { name: String(deviceCntFrom), store_id: storeId },
        ],
      }));
    } else {
      const [deviceCntFrom, deviceCntTo] = deviceIds;

      if (deviceCntTo - deviceCntFrom > maximumNumberOfDeviceId) {
        return toast.error(
          `Você ultrapassou a quantidade de ID Devices permitidos (${
            maximumNumberOfDeviceId + 1
          })`,
        );
      }

      const newDeviceCntArray = createRangeDeviceId(
        deviceCntFrom,
        deviceCntTo,
        1,
        storeId,
      );

      if (
        newDeviceCntArray.some((device) =>
          get().deviceCnt.some((d) => d.name === device.name),
        )
      ) {
        return toast.error('Id já cadastrado');
      }

      set((state) => ({
        deviceCnt: [...state.deviceCnt, ...newDeviceCntArray],
      }));
    }

    eventValue.target.value = '';
  },
  onRemoveDeviceCnt: (name) => {
    const deviceCnt = get().deviceCnt;
    const updatedDeviceCnt = deviceCnt.filter((device) => device.name !== name);
    set({ deviceCnt: updatedDeviceCnt });
  },
  onResetDeviceCnt: () => {
    set({ deviceCnt: [] });
  },
});
