import { Flex, Text } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';

import { HeadingTwo } from '@/components/Heading';
import { usePortalEventStore } from '@store/portal-event';

export function NoEventFound() {
  const { nameEvent, intervalDates } = usePortalEventStore((state) => state);

  const hasResearched = nameEvent.length > 1;

  const SearchResult = () => {
    return (
      <>
        com <b>{nameEvent}</b>{' '}
      </>
    );
  };

  const IntervalDates = () => {
    return (
      <>
        <b>{format(parseISO(intervalDates.initialDate), 'dd/MM/yyyy')}</b> e{' '}
        <b>{format(parseISO(intervalDates.endDate), 'dd/MM/yyyy')}</b>{' '}
      </>
    );
  };

  return (
    <Flex
      flexDirection="column"
      bg="white"
      borderRadius="0.625rem"
      height="8rem"
      alignItems="center"
      justifyContent="center"
      my="1rem"
      p={{ base: '1rem' }}
    >
      <HeadingTwo text="Nenhum resultado foi encontrado" />
      <Text mt="1rem" textAlign="center">
        Não encontramos nenhum evento {hasResearched && <SearchResult />}
        entre <IntervalDates />
      </Text>
    </Flex>
  );
}
