import { TabList, Flex, Tab, Icon, List, ListItem } from '@chakra-ui/react';
import { BsFillCheckCircleFill } from 'react-icons/bs';

import { useFormCreateEvent } from '@/hooks/useFormCreateEvent';

import { useCreateEventStore } from '@store/create-event';
import { useCanSubmitNewEvent } from '@/hooks/useCanSubmitNewEvent';

export function ListSteps() {
  const { isDisabledToSubmit } = useCanSubmitNewEvent();

  const { steps, currentTabIndex } = useCreateEventStore((state) => state);

  const { isDisabledToGoHistoric, isDisabledToGoLocationEquipment } =
    useFormCreateEvent();

  return (
    <TabList display="flex" flexDirection="column" w="15.8125rem">
      <Flex
        w="15.8125rem"
        direction="column"
        pos="fixed"
        h="100%"
        borderRight="1px solid #D9D9D9"
      >
        <List spacing="0.75rem" pt="5.625rem" px="3rem">
          {steps.map((step, index) => {
            return (
              <ListItem key={step.name}>
                <Tab
                  p="0"
                  display="flex"
                  gap="0.3125rem"
                  _selected={{ color: 'blue.500' }}
                  isDisabled={
                    (!step.isCompleted && step.id !== currentTabIndex) ||
                    (step.isFinished && step.id !== currentTabIndex)
                  }
                >
                  {index === 0 &&
                    step.isCompleted &&
                    !isDisabledToGoHistoric && (
                      <Icon as={BsFillCheckCircleFill} color="blue.500" />
                    )}

                  {index === 1 &&
                    step.isCompleted &&
                    !isDisabledToGoLocationEquipment && (
                      <Icon as={BsFillCheckCircleFill} color="blue.500" />
                    )}

                  {index === 2 && !isDisabledToSubmit && (
                    <Icon as={BsFillCheckCircleFill} color="blue.500" />
                  )}

                  {step.name}
                </Tab>
              </ListItem>
            );
          })}
        </List>
      </Flex>
    </TabList>
  );
}
