import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useGetStats } from '@/hooks/queries/get-stats';

export function GraphicByDay() {
  const { queryData } = useGetStats();

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    colors: ['#193498', '#113CFC', '#1597E5', '#69DADB'],
    xaxis: {
      type: 'datetime',
      categories: queryData.charts.days,
    },
  };

  return (
    <Flex
      w="100%"
      bg="white"
      rounded="1.25rem"
      flexDirection="column"
      py="1.375rem"
      px={{ base: '2rem', lg: '2.4375rem' }}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Heading fontSize="1.125rem" fontWeight="500" color="gray.700">
          Eventos por dia
        </Heading>

        <Text fontSize="1rem">Últimos 30 dias</Text>
      </Flex>

      <Box mt={{ base: '2rem', lg: '3.875rem' }} aria-label="chart-stats">
        <Chart
          options={options}
          series={queryData.charts.totalEventsByCompany}
          type="area"
          width="100%"
          height="250"
        />
      </Box>
    </Flex>
  );
}
