export const blue = {
  100: '#CDDEFF',
  500: '#3F7EF8',
  600: '#1852C2',
  800: '#283C6D',
  900: '#202331',
};

export const gray = {
  200: '#E5E7EA',
  400: '#959595',
  700: '#5F636A',
  900: '#323232',
};

export const green = {
  100: '#CFFFE3',
  500: '#62BF89',
};

export const orange = {
  50: '#FFF3EC',
  100: '#FFC9AA',
  500: '#F07F3F',
};
