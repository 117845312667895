import { Link } from '@chakra-ui/react';
import { Link as ReachLink } from 'react-router-dom';

interface LinkTitleData {
  eventData: {
    id: string;
    name: string;
  };
  isOpenedEvent: boolean;
}

export function LinkTitle({ eventData, isOpenedEvent }: LinkTitleData) {
  return (
    <Link
      as={ReachLink}
      to={
        isOpenedEvent
          ? `/update/event/${eventData.id}`
          : `/event/details/${eventData.id}`
      }
      state={{ idEvent: eventData.id }}
      color="gray.700"
      _hover={{ textDecoration: 'none', color: 'orange.500' }}
      fontWeight="600"
      lineHeight="1.21rem"
    >
      {eventData.name}
    </Link>
  );
}
