import { Box, Flex, Text } from '@chakra-ui/react';

import { AlertEventBox } from '@/components/AlertEventBox';
import { ButtonGoBack } from '@/components/ButtonGoBack';
import { HeadingOne } from '@/components/Heading';
import { QuickDetailsEvent } from '@/components/QuickDetailsEvent';

import { usePreviousEventStore } from '@store/previous-event';

import { useEffect } from 'react';
import { Loading } from './Loading';

import { Filter } from './Filter';
import { Pagination } from './Pagination';
import { useGetPastEvents } from '@/hooks/queries/get-past-events';

export function PreviousEvents() {
  const { selectCompany, selectedSla, selectedCategory, changeCurrentPage } =
    usePreviousEventStore((state) => state);

  const { data, isLoading, refetch } = useGetPastEvents();

  useEffect(() => {
    changeCurrentPage(1);
    refetch();
  }, [
    selectCompany,
    selectedSla,
    selectedCategory,
    changeCurrentPage,
    refetch,
  ]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box
      my={{ base: '6', lg: '6' }}
      px={{ base: '4', md: '8', lg: '10' }}
      h="100%"
    >
      <Box mt={{ base: '2rem', lg: '0' }} mb="3rem">
        <ButtonGoBack />

        <HeadingOne text="Eventos anteriores" mb="2rem" />

        <Filter refetch={refetch} />

        <Box>
          {selectCompany !== '' && (
            <Text color="gray.700" mb="0.5rem">
              Filtrado por: {selectCompany}{' '}
            </Text>
          )}
        </Box>

        <Flex flexDirection="column" gap={{ base: '1.2rem', lg: '0.5rem' }}>
          {data.events.length <= 0 ? (
            <AlertEventBox
              title="Comece criando um novo Evento."
              description="Os Eventos resolvidos aparecerão por aqui"
            />
          ) : (
            <>
              {data.events.map((event) => (
                <QuickDetailsEvent key={event.id} data={event} />
              ))}
            </>
          )}
        </Flex>

        <Pagination pagination={data.pagination} />
      </Box>
    </Box>
  );
}
