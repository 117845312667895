import { StateCreator } from 'zustand';
import { initialAboutEventState } from '@/store/update-event/initial-state/about-event';
import {
  AboutEventActions,
  AboutEventState,
} from '@/store/update-event/@types/about-event';

export const createAboutEventSlice: StateCreator<
  AboutEventState & AboutEventActions
> = (set) => ({
  ...initialAboutEventState,
  onSaveCurrentSelectionsAndDetails: ({ event, parameters }) => {
    const findCompany = parameters.company.find((company) => {
      return company.name === event.companies.name;
    });

    set({
      name: event.name,
      description: event.description,
      selectedCategory: event.categories,
      selectedRepairTeam: event.repair_team,
      selectedCompany: {
        ...event.companies,
        company_stores: findCompany.company_stores,
      },
    });
  },
  onWriteName: (name) => {
    set({ name });
  },
  onWriteDescription: (description) => {
    set({ description });
  },
  onSelectCategory: (category) => {
    set({ selectedCategory: category });
  },
  onSelectCompany: (company) => {
    set({ selectedCompany: company });
  },
  onSelectRepairTeam: (team) => {
    set({ selectedRepairTeam: team });
  },
});
