import { List } from '@chakra-ui/react';
import { TicketCard } from './TicketCard';

import { ListTicketsMobileProps } from './types';

export function ListTicketsMobile({ tickets }: ListTicketsMobileProps) {
  return (
    <List display="flex" mt="1.25rem" flexDirection="column" gap="1.187rem">
      {tickets.map((ticket) => (
        <TicketCard key={ticket.id} ticket={ticket} />
      ))}
    </List>
  );
}
