import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { memo } from 'react';

import { AvatarCompanyLogo } from './AvatarCompanyLogo';
import { BadgeCategory } from './BadgeCategory';
import { CircularProgressSla } from './CircularProgressSla';
import { LinkShowEvent } from './LinkShowEvent';
import { QuickDetailsEventsData } from './types';

import { LinkTitle } from './LinkTitle';

export const QuickDetailsEvent = memo(function QuickDetailsEvent({
  data,
  isOpenedEvent = false,
}: QuickDetailsEventsData) {
  return (
    <Flex
      bg="white"
      w="100%"
      py="1.375rem"
      px={{ base: '1.4rem', lg: '1.875rem' }}
      rounded=".6rem "
      color="gray.700"
      justify="space-between"
      align={{ base: 'initial', lg: 'center' }}
      flexDirection={{ base: 'column', lg: 'row' }}
    >
      <Flex
        alignItems="center"
        gap={{ base: '.875rem', lg: '1.625rem' }}
        w={{ base: '100%', lg: '20rem' }}
        position="relative"
        mb={{ base: '1rem', lg: 'unset' }}
      >
        <AvatarCompanyLogo companyData={data} />

        <Stack spacing=".625rem" w={{ base: '60%', lg: 'auto' }}>
          <LinkTitle eventData={data} isOpenedEvent={isOpenedEvent} />

          <Stack
            direction="row"
            spacing="2.4375rem"
            display={{ base: 'none', lg: 'flex' }}
          >
            {!isOpenedEvent && (
              <Text>{data.history_events[0]?.status.name}</Text>
            )}

            {data.repair_team && <Text>{data.repair_team.name}</Text>}
          </Stack>
        </Stack>

        <CircularProgressSla slaData={data} isMobile />
      </Flex>

      <BadgeCategory categoryData={data} />

      <Flex
        display={{ base: 'none', lg: 'flex' }}
        gap={{ base: '1rem', lg: '5rem' }}
        align="center"
        justify="left"
      >
        <Flex gap=".625rem" alignItems="center">
          <CircularProgressSla slaData={data} />

          <Flex>
            {isOpenedEvent ? (
              <Text
                color="gray.400"
                w={{ base: 'initial', lg: '13rem' }}
                fontSize={{ base: '.75rem', lg: '1rem' }}
              >
                {data.history_events[0]?.status.name} aaa {data.elapsedTime}
              </Text>
            ) : (
              <Box>
                <Text
                  color="gray.400"
                  fontSize={{ base: '.75rem', lg: '1rem' }}
                  w={{ base: 'initial', lg: '10rem' }}
                >
                  Criado{' '}
                  {
                    data.history_events[data.history_events.length - 1]
                      .elapsedTime
                  }
                </Text>

                <Text
                  color="gray.400"
                  fontSize={{ base: '.75rem', lg: '1rem' }}
                  w={{ base: 'initial', lg: '10rem' }}
                >
                  Resolvido {data.history_events[0].elapsedTime}
                </Text>
              </Box>
            )}
          </Flex>
        </Flex>

        <Text
          display={{ base: 'flex', lg: 'none' }}
          w={{ base: 'initial', lg: '7.2rem' }}
          color="gray.400"
          fontSize={{ base: '.75rem', lg: '1rem' }}
        >
          {data.categories ? data.categories.name : ''}
        </Text>
      </Flex>

      <Stack
        display={{ base: 'flex', lg: 'none' }}
        direction={{ base: 'column', lg: 'row' }}
      >
        {data.repair_team && <Text>{data.repair_team.name}</Text>}

        <Text>{data.history_events[0]?.status.name}</Text>

        <Text>{data.categories.name}</Text>
      </Stack>

      <LinkShowEvent
        statusEvent={isOpenedEvent ? 'opened' : 'resolved'}
        eventData={data}
      />
    </Flex>
  );
});
