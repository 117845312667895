import { Flex, Heading, Text } from '@chakra-ui/react';
import { useGetStats } from '@/hooks/queries/get-stats';

export function ShowTotalEvents() {
  const { queryData } = useGetStats();

  return (
    <Flex direction="column" justifyContent="center">
      <Heading
        color="blue.500"
        fontWeight="600"
        fontSize="1.625rem"
        mb="0.9375rem"
      >
        Eventos
      </Heading>
      <Text color="gray.700" fontSize="1rem">
        Até o momento{' '}
        <Text as="span" color="blue.500" fontWeight="bold">
          {queryData.stats.totalEvents}
        </Text>{' '}
        Eventos foram criados.
      </Text>
      <Text color="gray.700">Confira o total por empresa.</Text>
    </Flex>
  );
}
