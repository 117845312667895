import { ButtonGoBack } from '@/components/ButtonGoBack';
import { Flex } from '@chakra-ui/react';
import { Company } from './Company';

import { Images } from './Images';
import { QuickEventStatus } from './QuickEventStatus';
import { Tickets } from './Tickets';
import { LocationAndEquipment } from './LocationAndEquipment';
import { useDetailEventStore } from '@/store/detail-event';
import { FullScreenModal } from './Images/FullScreenModal';
import { HistoricEvent } from './HistoricEvent';

export function DesktopLayout() {
  const { hasImages, isExistsEquipmentLocation } = useDetailEventStore(
    (state) => {
      const hasImages = state.event.images.length > 0;

      return { ...state, hasImages };
    },
  );
  return (
    <>
      <Flex flexDir="column" display={{ base: 'none', md: 'flex' }}>
        <ButtonGoBack />

        <Flex gap="2.125rem">
          <Flex as="section" flexDirection="column" flex="1" gap="2rem">
            <Company />
            <HistoricEvent />

            {hasImages && <Images />}
          </Flex>

          <Flex
            as="section"
            flexDirection="column"
            minW={{ md: '21.8125rem', xl: '24.625rem' }}
            maxW={{ md: '21.8125rem', xl: '24.625rem' }}
          >
            <QuickEventStatus />

            <Tickets />

            {isExistsEquipmentLocation && <LocationAndEquipment />}
          </Flex>
        </Flex>
      </Flex>
      <FullScreenModal />
    </>
  );
}
