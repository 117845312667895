import { StateCreator } from 'zustand';

import {
  ParameterState,
  ParameterActions,
} from '@store/create-event/@types/parameter';
import { initialParameterState } from '@store/create-event/initial-state/parameter';

export const parameterSlice: StateCreator<ParameterState & ParameterActions> = (
  set,
) => ({
  ...initialParameterState,
  onSaveParameters: (data) => {
    set({
      categories: data.categories,
      companies: data.company,
      historyStatus: data.history_status,
      repairTeams: data.repair_teams,
      nations: data.states,
    });
  },
});
