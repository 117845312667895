import { StateCreator } from 'zustand';

import {
  HistoryState,
  HistoryActions,
} from '@store/create-event/@types/history';
import { initialHistoryState } from '@store/create-event/initial-state/history';

export const historySlice: StateCreator<HistoryState & HistoryActions> = (
  set,
) => ({
  ...initialHistoryState,
  onChangeSelectedStatus: (status) => {
    set({ selectedStatus: status });
  },
  onSelectHistoryStatus: (event) => {
    if (event.target.value === '') {
      set({
        selectedStatus: {
          id: '',
          name: '',
        },
      });
      return;
    }

    const currentHistoryStatus = JSON.parse(event.target.value);

    set({
      selectedStatus: currentHistoryStatus,
      statusIdIndex: currentHistoryStatus.id,
    });
  },
  onChangeStatusIndex: (status) => {
    const parsedStatus = JSON.parse(status);

    set({ statusIdIndex: parsedStatus.id });
  },
  onResetStatus: () => {
    set({ selectedStatus: { id: '', name: '' } });
  },
});
