import { useBreakpointValue } from '@chakra-ui/react';
import { FilterDasktop } from './FilterDesktop';
import { FilterMobile } from './FilterMobile';

interface FilterProps {
  refetch: () => void;
}

export function Filter({ refetch }: FilterProps) {
  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      ssr: false,
    },
  );

  return (
    <>
      {!isMobile ? (
        <FilterDasktop refetch={refetch} />
      ) : (
        <FilterMobile refetch={refetch} />
      )}
    </>
  );
}
