import { Flex, Text } from '@chakra-ui/react';

interface BadgeCategoryData {
  categoryData: {
    categories: {
      name: string;
    };
  };
}

export function BadgeCategory({ categoryData }: BadgeCategoryData) {
  return (
    <Flex
      w="8rem"
      justifyContent="flex-start"
      display={{ base: 'none', lg: 'flex' }}
    >
      <Text
        display="flex"
        color="blue.500"
        bg="blue.100"
        rounded="full"
        px=".5rem"
        py=".375rem"
        fontWeight="400"
        fontSize={{ base: '.75rem', lg: '1rem' }}
      >
        {categoryData.categories.name}
      </Text>
    </Flex>
  );
}
