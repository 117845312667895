import { useDetailEventStore } from '@/store/detail-event';
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';

export function Company() {
  const event = useDetailEventStore((state) => state.event);

  return (
    <Flex
      w="100%"
      bg="white"
      borderRadius="0.75rem"
      p={{ base: '1.5rem 1.625rem', lg: '2rem' }}
      flexDirection="column"
    >
      <Box
        width={{ base: '6.625rem', lg: '6.25rem' }}
        height={{ base: '3.0625rem', lg: '2.5625rem' }}
      >
        <Image
          boxSize="100%"
          src={event.companies.url_logo}
          alt={`${event.companies.name} company logo`}
        />
      </Box>

      <Heading
        mt="1.4375rem"
        fontSize={{ base: '1rem', lg: '1.375rem' }}
        fontWeight="500"
        color="gray.900"
      >
        {event.name}
      </Heading>

      <Text
        mt="0.25rem"
        fontSize={{ base: '1rem', lg: '1.25rem' }}
        color="gray.400"
        fontWeight="400"
      >
        {event.description}
      </Text>
    </Flex>
  );
}
