import { useContext } from 'react';
import { FormCreateEventContext } from '@/contexts/formCreateEvent';

export function useFormCreateEvent() {
  const context = useContext(FormCreateEventContext);

  if (!context) {
    throw new Error('useAuth must be used within an FormCreateEventProvider');
  }

  return context;
}
