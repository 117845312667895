import { usePortalEventStore } from '@store/portal-event';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';

export function GroupCompany() {
  const { selectedCompany, changeCompany } = usePortalEventStore((state) => {
    const { selectedCompany, changeCompany } = state;

    return { selectedCompany, changeCompany };
  });

  return (
    <RadioGroup value={selectedCompany} onChange={(e) => changeCompany(e)}>
      <Stack spacing={3}>
        <Radio value="" defaultChecked>
          Todas
        </Radio>

        <Radio value="Intlink">Intlink</Radio>
        <Radio value="Icom">Icom Telecom</Radio>
        <Radio value="Fiber Network">Fiber Network</Radio>
        <Radio value="Wire">Wire</Radio>
      </Stack>
    </RadioGroup>
  );
}
