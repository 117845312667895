import { Skeleton, Td, Tr } from '@chakra-ui/react';

export function SkeletonTrBodyPortal() {
  return (
    <Tr _notFirst={{ borderTop: '0.0625rem solid #DFE7F2' }}>
      <Td w="50%" py="1.687rem" color="gray.700" fontWeight="500">
        <Skeleton height="1.25rem" w="12.5rem" />
      </Td>
      <Td py="1.687rem">
        <Skeleton height="1.25rem" />
      </Td>
      <Td py="1.687rem">
        <Skeleton height="1.25rem" />
      </Td>
      <Td py="1.687rem">
        <Skeleton height="1.25rem" />
      </Td>
      <Td py="1.687rem">
        <Skeleton height="1.25rem" />
      </Td>
    </Tr>
  );
}
