import { create } from 'zustand';

import * as slices from './slices';
import { CombineStore } from '@store/create-event/@types';

export const useCreateEventStore = create<CombineStore>((...state) => ({
  ...slices.parameterSlice(...state),
  ...slices.aboutEventSlice(...state),
  ...slices.historySlice(...state),
  ...slices.citySlice(...state),
  ...slices.neighborhoodSlice(...state),
  ...slices.federativeUnitSlice(...state),
  ...slices.imageSlice(...state),
  ...slices.saveNewEventSlice(...state),
  ...slices.stepSlice(...state),
  ...slices.deviceCntSlice(...state),
}));
