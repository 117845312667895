import { Flex, Heading, Text } from '@chakra-ui/react';

import { Logo } from './Logo';

export function ErrorPage() {
  return (
    <Flex h="100vh">
      <Flex
        px={{ base: '1rem', lg: '4.875rem' }}
        w="100%"
        flexDirection="column"
        my="auto"
      >
        <Logo />

        <Flex
          bg="white"
          align="center"
          py="3.75rem"
          px="2rem"
          rounded="0.75rem"
          mt="2rem"
          maxW="600px"
          mx="auto"
        >
          <Flex
            flexDirection="column"
            align="center"
            textAlign="center"
            gap="0.6rem"
          >
            <Heading fontSize="1rem">Ooops...</Heading>

            <Text>Este conteúdo não está disponível para visualização.</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
