import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';
import { queryClient } from '@/services/queryClient';
import { useLocation } from 'react-router-dom';
import * as Types from '../types';

interface LocationState {
  idEvent: string;
}

export function useSelectMultiple() {
  const { queryData } = useGetEventDetailToUpdate();
  const location = useLocation() as { state: LocationState };

  function onSelectMultipleCities(city: Types.ExtendedCity) {
    if (city.isSelected) {
      const currentCities = queryData.event.cities;

      const itemIndex = currentCities.findIndex((item) => item.id === city.id);

      currentCities.splice(itemIndex, 1, {
        event_id: city.event_id,
        id: city.id,
        name: city.name,
        isSelected: false,
      });

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              cities: currentCities,
            },
          };
        },
      ) as Types.Return;
    } else {
      const currentCities = queryData.event.cities;

      const itemIndex = currentCities.findIndex((item) => item.id === city.id);

      currentCities.splice(itemIndex, 1, {
        event_id: city.event_id,
        id: city.id,
        name: city.name,
        isSelected: true,
      });

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              cities: currentCities,
            },
          };
        },
      ) as Types.Return;
    }
  }

  function onSelectMultipleStates(state: Types.ExtendedStateEvent) {
    if (state.isSelected) {
      const currentFederativeUnits = queryData.event.states;
      const itemIndex = currentFederativeUnits.findIndex(
        (item) => item.id === state.id,
      );

      currentFederativeUnits.splice(itemIndex, 1, {
        state_event_id: state.state_event_id,
        id: state.id,
        name: state.name,
        isSelected: false,
      });

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              states: currentFederativeUnits,
            },
          };
        },
      ) as Types.Return;
    } else {
      const currentFederativeUnits = queryData.event.states;
      const itemIndex = currentFederativeUnits.findIndex(
        (item) => item.id === state.id,
      );

      currentFederativeUnits.splice(itemIndex, 1, {
        id: state.id,
        state_event_id: state.state_event_id,
        name: state.name,
        isSelected: true,
      });

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              states: currentFederativeUnits,
            },
          };
        },
      ) as Types.Return;
    }
  }

  function onSelectMultipleNeighborhoods(
    neighborhood: Types.ExtendedNeighborhood,
  ) {
    const currentNeighborhoods = queryData.event.neighborhoods;

    if (neighborhood.isSelected) {
      const itemIndex = currentNeighborhoods.findIndex(
        (item) => item.id === neighborhood.id,
      );

      currentNeighborhoods.splice(itemIndex, 1, {
        id: neighborhood.id,
        name: neighborhood.name,
        isSelected: false,
        cities: {
          name: neighborhood.cities.name,
        },
      });

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              neighborhoods: currentNeighborhoods,
            },
          };
        },
      ) as Types.Return;
    } else {
      const itemIndex = currentNeighborhoods.findIndex(
        (item) => item.id === neighborhood.id,
      );

      currentNeighborhoods.splice(itemIndex, 1, {
        id: neighborhood.id,
        name: neighborhood.name,
        isSelected: true,
        cities: {
          name: neighborhood.cities.name,
        },
      });

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              neighborhoods: currentNeighborhoods,
            },
          };
        },
      ) as Types.Return;
    }
  }

  function onSelectMultipleDeviceCnt(device: Types.ExtendedDeviceCnt) {
    if (device.isSelected) {
      const currentDeviceCnt = queryData.event.device_cnt;

      const itemIndex = currentDeviceCnt.findIndex(
        (item) => item.id === device.id,
      );

      currentDeviceCnt.splice(itemIndex, 1, {
        id: device.id,
        name: device.name,
        description: device.description,
        isSelected: false,
      });

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              device_cnt: currentDeviceCnt,
            },
          };
        },
      ) as Types.Return;
    } else {
      const currentDeviceCnt = queryData.event.device_cnt;

      const itemIndex = currentDeviceCnt.findIndex(
        (item) => item.id === device.id,
      );

      currentDeviceCnt.splice(itemIndex, 1, {
        id: device.id,
        name: device.name,
        description: device.description,
        isSelected: true,
      });

      queryClient.setQueryData(
        ['event-detail-to-update', location.state, location.state.idEvent],
        (oldData) => {
          return {
            ...oldData,
            event: {
              ...oldData.event,
              device_cnt: currentDeviceCnt,
            },
          };
        },
      ) as Types.Return;
    }
  }

  return {
    onSelectMultipleCities,
    onSelectMultipleStates,
    onSelectMultipleNeighborhoods,
    onSelectMultipleDeviceCnt,
  };
}
