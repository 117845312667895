import { useCreateEventStore } from '@store/create-event';
import {
  Box,
  Flex,
  FormControl,
  Tag,
  Text,
  TagLabel,
  TagCloseButton,
  Input,
  Select,
} from '@chakra-ui/react';

import { TemplateModal } from '../TemplateModal';
import { ChangeEvent, useState } from 'react';

interface ModalProps {
  disclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
}

interface Store {
  store_id: string;
}

export function Modal({ disclosure }: ModalProps) {
  const {
    selectedCompany,
    deviceCnt,
    onAddDeviceCnt,
    onRemoveDeviceCnt,
    onResetDeviceCnt,
  } = useCreateEventStore((state) => state);
  const [store, setStore] = useState<Store>(null);

  const isExistsSelectedCompany = !!selectedCompany.id;

  const hasNoStoreSelected = store === null;

  function handleSelectStore(e: ChangeEvent<HTMLSelectElement>) {
    if (e.target.value === '') {
      setStore(null);
      return;
    }

    setStore(JSON.parse(e.target.value));
  }

  return (
    <TemplateModal
      name="Device CNT"
      reset={onResetDeviceCnt}
      disclosure={disclosure}
    >
      <FormControl display="flex" alignItems="center">
        {isExistsSelectedCompany && (
          <Flex w="100%" gap="1.125rem">
            <Select placeholder="Loja" onChange={handleSelectStore} w="9rem">
              {selectedCompany.company_stores.map((store) => {
                return (
                  <option key={store.id} value={JSON.stringify(store)}>
                    Loja {store.name}
                  </option>
                );
              })}
            </Select>

            <Input
              isDisabled={hasNoStoreSelected}
              placeholder="Informe o/os Id Device Cnt"
              bg="white"
              onKeyDown={(event) =>
                onAddDeviceCnt({ event, storeId: store.store_id })
              }
            />
          </Flex>
        )}
      </FormControl>

      {deviceCnt.length > 0 && (
        <Box mt="2.5rem">
          <Text fontSize="1rem">
            {deviceCnt.length} Id Devices CNT adicionados
          </Text>

          <Flex
            mt="0.75rem"
            rowGap="1rem"
            columnGap="0.25rem"
            flexWrap="wrap"
            overflowY="scroll"
            maxH="15.625rem"
            css={{
              '&::-webkit-scrollbar': {
                width: '0.3125rem',
              },
              '&::-webkit-scrollbar-track': {
                width: '0.375rem',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'var(--chakra-colors-gray-300);',
                borderRadius: '0.625rem',
              },
            }}
          >
            {deviceCnt.map((device) => {
              return (
                <Tag
                  key={device.name}
                  bg="blue.500"
                  color="white"
                  rounded="full"
                  h="1.5625rem"
                >
                  <TagLabel>{device.name}</TagLabel>
                  <TagCloseButton
                    bg="#FFFFFF"
                    opacity="1"
                    w="0.9375rem"
                    h="0.9375rem"
                    color="blue.500"
                    fontSize="0.625rem"
                    ml="0.5rem"
                    onClick={() => onRemoveDeviceCnt(device.name)}
                  />
                </Tag>
              );
            })}
          </Flex>
        </Box>
      )}
    </TemplateModal>
  );
}
