import { useBreakpointValue } from '@chakra-ui/react';

import { useTicketEventStore } from '@store/ticket-event';
import { NoTicketFound } from './NoTicketFound';

import { TicketsTable } from './TicketsTable';
import { ListTicketsMobile } from '@/components/TicketCardMobile';

export function Tickets() {
  const { tickets } = useTicketEventStore((state) => state);

  const hasNoTickets = tickets.length <= 0;
  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      ssr: false,
    },
  );

  if (hasNoTickets) {
    return <NoTicketFound />;
  }

  if (isMobile) {
    return <ListTicketsMobile tickets={tickets} />;
  }

  return <TicketsTable tickets={tickets} />;
}
