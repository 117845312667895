import {
  Flex,
  RangeSlider,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Fragment } from 'react';

import { formatDateHour } from '@/utils/formatDate';
import { EventTimelineSliderProps } from './types';

export function EventTimelineSlider({ event }: EventTimelineSliderProps) {
  const isMobile = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    {
      ssr: false,
    },
  );

  const sizeEventArray = event.history_events.length - 1;

  const isExitsResolvedEvent = event.history_events.some((item) => {
    return item.status.name === 'Resolvido';
  });

  if (isMobile) {
    return (
      <Flex
        mt="3rem"
        mb="5rem"
        flexDirection="column"
        align="start"
        aria-label="slider-mobile"
      >
        {!isExitsResolvedEvent && (
          <RangeSlider
            defaultValue={[0]}
            min={0}
            max={0}
            orientation="vertical"
            minH="10rem"
            isDisabled
          >
            <RangeSliderTrack bg="orange.100" />
            <RangeSliderThumb boxSize="1rem" index={0} bg="orange.400">
              <Text pos="absolute" mt="16" w="6rem" left="1.8rem">
                Resolvido
              </Text>
            </RangeSliderThumb>
          </RangeSlider>
        )}

        {event.history_events.map((event) => (
          <Fragment key={event.id}>
            <RangeSlider
              defaultValue={[0]}
              min={0}
              max={0}
              orientation="vertical"
              minH="10rem"
            >
              <RangeSliderTrack bg="orange.100" />

              <RangeSliderThumb boxSize="1rem" index={0} bg="orange.400">
                <Text pos="absolute" left="1.8rem" color="gray.700">
                  {formatDateHour(event.created_at)}
                </Text>

                <Text pos="absolute" mt="16" w="6rem" left="1.8rem">
                  {event.status.name}
                </Text>
              </RangeSliderThumb>
            </RangeSlider>
          </Fragment>
        ))}

        <RangeSlider defaultValue={[0]} min={0} max={0} orientation="vertical">
          <RangeSliderThumb boxSize="1rem" index={0} bg="orange.400">
            <Text pos="absolute" left="1.8rem" color="gray.700">
              {formatDateHour(event.created_at)}
            </Text>

            <Text pos="absolute" mt="16" w="6rem" left="1.8rem">
              Evento Criado
            </Text>
          </RangeSliderThumb>
        </RangeSlider>
      </Flex>
    );
  }

  return (
    <Flex
      w="100%"
      mt="4rem"
      pl="1.5rem"
      pr="2.1rem"
      aria-label="slider-desktop"
    >
      <RangeSlider defaultValue={[0]} min={0} max={0} width="100%" m="0 auto">
        <RangeSliderTrack bg="orange.100" h="0.3125rem" />

        <RangeSliderThumb boxSize="1rem" index={0} bg="orange.400">
          <Text
            pos="absolute"
            fontSize="0.75rem"
            top="-1.5rem"
            color="gray.900"
          >
            {formatDateHour(event.created_at)}
          </Text>

          <Text
            pos="absolute"
            mt="4rem"
            w="4.3rem"
            fontSize="0.75rem"
            fontWeight="300"
          >
            Evento Criado
          </Text>
        </RangeSliderThumb>
      </RangeSlider>

      {event.history_events
        .slice(0)
        .reverse()
        .map((event, index) => (
          <Fragment key={event.id}>
            {!isExitsResolvedEvent ? (
              <RangeSlider
                defaultValue={[0]}
                min={0}
                max={0}
                width="100%"
                m="0 auto"
              >
                <RangeSliderTrack
                  h="0.3125rem"
                  bg={
                    index === sizeEventArray && !isExitsResolvedEvent
                      ? '#D2D2D2'
                      : 'orange.100'
                  }
                />

                <RangeSliderThumb boxSize="1rem" index={0} bg="orange.400">
                  <Text
                    pos="absolute"
                    top="-1.5rem"
                    fontSize="0.75rem"
                    color="gray.900"
                  >
                    {formatDateHour(event.created_at)}
                  </Text>

                  <Text pos="absolute" mt="16" w="4.5rem" fontSize="0.75rem">
                    {event.status.name}
                  </Text>
                </RangeSliderThumb>
              </RangeSlider>
            ) : (
              <RangeSlider
                defaultValue={[0]}
                min={0}
                max={0}
                width={index !== sizeEventArray ? '100%' : 'auto'}
                m="0 auto"
              >
                {index !== sizeEventArray && (
                  <RangeSliderTrack bg="orange.100" h="0.3125rem" />
                )}

                <RangeSliderThumb boxSize="1.5rem" index={0} bg="orange.400">
                  <Text pos="absolute" top="-1.5rem" color="gray.900">
                    {formatDateHour(event.created_at)}
                  </Text>

                  <Text pos="absolute" mt="4rem" w="6rem" fontSize="0.75rem">
                    {event.status.name}
                  </Text>
                </RangeSliderThumb>
              </RangeSlider>
            )}
          </Fragment>
        ))}

      {!isExitsResolvedEvent && (
        <RangeSlider
          defaultValue={[0]}
          min={0}
          max={0}
          width="auto"
          m="0 auto"
          isDisabled
        >
          <RangeSliderThumb boxSize="1rem" index={0} bg="orange.400">
            <Text
              pos="absolute"
              mt="4rem"
              w="3.1rem"
              fontSize="0.75rem"
              color="gray.400"
            >
              Resolvido
            </Text>
          </RangeSliderThumb>
        </RangeSlider>
      )}
    </Flex>
  );
}
