import { EventReturn } from '@/hooks/queries/get-event-detail-to-update/types';

interface RepairTeam {
  id: string;
}

interface Company {
  name: string;
}

interface SelectedCategory {
  id: string;
  name: string;
}

interface StoreState {
  name: string;
  description: string;
  selectedCategory: SelectedCategory;
  selectedRepairTeam: RepairTeam | null;
  selectedCompany: Company | null;
}

export function isEventMatching(
  event: EventReturn,
  name: string,
  description: string,
): boolean {
  return event.name === name && event.description === description;
}

export function isRepairTeamMatching(
  event: EventReturn,
  selectedRepairTeam: RepairTeam | null,
): boolean {
  return (
    selectedRepairTeam === null ||
    event.repair_team === null ||
    event.repair_team.id === selectedRepairTeam.id
  );
}

export function isCompanyMatching(
  event: EventReturn,
  selectedCompany: Company | null,
): boolean {
  return (
    selectedCompany === null ||
    event.companies === null ||
    event.companies.name === selectedCompany.name
  );
}

export function isCategoryIndisponibilidade(
  selectedCategory: SelectedCategory,
  selectedRepairTeam: RepairTeam | null,
): boolean {
  return (
    selectedCategory.name === 'Indisponibilidade' && selectedRepairTeam === null
  );
}

export function isDisabledSubmitAboutEvent(
  event: EventReturn,
  store: StoreState,
): boolean {
  const {
    name,
    description,
    selectedCategory,
    selectedRepairTeam,
    selectedCompany,
  } = store;

  const eventMatches = isEventMatching(event, name, description);

  const repairTeamOrCategoryMatch =
    selectedRepairTeam === null || event.categoryId === selectedCategory.id;

  const repairTeamMatches = isRepairTeamMatching(event, selectedRepairTeam);

  const companyMatches = isCompanyMatching(event, selectedCompany);

  const isIndisponibilidade = isCategoryIndisponibilidade(
    selectedCategory,
    selectedRepairTeam,
  );

  const shouldDisableButton =
    eventMatches &&
    repairTeamOrCategoryMatch &&
    repairTeamMatches &&
    companyMatches;

  return shouldDisableButton || isIndisponibilidade;
}
