import { Flex, Text } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';

import { HeadingTwo } from '@/components/Heading';
import { useTicketEventStore } from '@store/ticket-event';

export function NoTicketFound() {
  const { filterParams } = useTicketEventStore((state) => state);

  const hasResearched = filterParams.filterSearch.length > 1;

  const SearchResult = () => {
    return (
      <>
        com <b>{filterParams.filterSearch}</b>{' '}
      </>
    );
  };

  const IntervalDates = () => {
    return (
      <>
        <b>
          {format(
            parseISO(filterParams.filterIntervalDates.initialDate),
            'dd/MM/yyyy',
          )}
        </b>{' '}
        e{' '}
        <b>
          {format(
            parseISO(filterParams.filterIntervalDates.endDate),
            'dd/MM/yyyy',
          )}
        </b>{' '}
      </>
    );
  };

  return (
    <Flex
      flexDirection="column"
      bg="white"
      borderRadius="0.625rem"
      height="8rem"
      alignItems="center"
      justifyContent="center"
      my="1rem"
      p={{ base: '1rem' }}
    >
      <HeadingTwo text="Nenhum resultado foi encontrado" />
      <Text mt="1rem" textAlign="center">
        Não encontramos nenhum ticket {hasResearched && <SearchResult />}
        entre <IntervalDates />
      </Text>
    </Flex>
  );
}
