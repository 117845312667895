import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { IoApps } from 'react-icons/io5';
import { LinksAsideRedirect } from './LinksAsideRedirect';

export function AsideMenuMobile() {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <>
      <Button
        border="0.0625rem solid #D5D5D5"
        onClick={onOpen}
        rounded="full"
        bg="white"
        boxSize="10"
        position="fixed"
        top="1.2rem"
        zIndex="1000"
        right={{ base: '4', md: '8', lg: '10' }}
        display={{ base: 'inline-flex', lg: 'none' }}
      >
        <Icon as={IoApps} boxSize="5" color="gray.900" />
      </Button>
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent bg="blue.900">
          <DrawerHeader>
            <Button onClick={onClose} width="2">
              <Icon as={AiOutlineClose} w={5} h={5} color="gray.900" />
            </Button>
          </DrawerHeader>
          <DrawerBody bg="blue.900" color="#A8DAFF">
            <Flex fontSize="md" flexDir="column">
              <Heading color="gray.400" fontSize="lg" fontWeight="normal">
                Monitoramento
                <Text color="orange.500" fontWeight="bold">
                  de Eventos
                </Text>
              </Heading>

              <LinksAsideRedirect />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
