import { Heading, HeadingProps, Text } from '@chakra-ui/react';

interface HeadingTwoProps extends HeadingProps {
  text: string;
  isRequired?: boolean;
}

export function HeadingTwo({
  text,
  isRequired = false,
  ...rest
}: HeadingTwoProps) {
  return (
    <Heading
      fontWeight="500"
      color="gray.700"
      fontSize={{ base: '1rem', lg: '1.125rem' }}
      display="flex"
      {...rest}
    >
      {text} {isRequired && <Text color="red.400">*</Text>}
    </Heading>
  );
}
