import { Center, Heading, Image, Text } from '@chakra-ui/react';

import notFoundImg from '@/assets/images/icon-not-found.svg';

export function NotFoundIncident() {
  return (
    <Center as="main" h="100vh" px="1rem">
      <Center
        flexDir="column"
        bg="white"
        p="3rem"
        textAlign="center"
        rounded="0.4rem"
      >
        <Image
          src={notFoundImg}
          alt="icon not found page"
          boxSize="3.75rem"
          mb="1rem"
        />

        <Heading
          as="h1"
          fontSize={{ base: '1rem', lg: '1.4rem' }}
          mb="1rem"
          color="gray.700"
          fontWeight="600"
        >
          Página não encontrada
        </Heading>

        <Text
          fontSize={{ base: '0.9rem', lg: '1rem' }}
          maxW="21.875rem"
          color="gray.400"
          lineHeight="1rem"
        >
          Woops... Tentamos buscar a página solicitada mas sem sucesso :(
        </Text>
      </Center>
    </Center>
  );
}
