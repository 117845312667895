import { ButtonGoBack } from '@/components/ButtonGoBack';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Box } from '@chakra-ui/react';

export function Loading() {
  return (
    <Box
      my={{ base: '6', lg: '6' }}
      px={{ base: '4', md: '8', lg: '10' }}
      h="100%"
    >
      <Box mt={{ base: '2rem', lg: '0' }} mb="3rem">
        <ButtonGoBack />

        <LoadingSpinner />
      </Box>
    </Box>
  );
}
