import { Flex } from '@chakra-ui/react';

import { TotalStats } from './TotalStats';

interface OverallTotalProps {
  total: {
    tickets: number;
    protocols: number;
    percents: number;
  };
}

export function OverallTotal({ total }: OverallTotalProps) {
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems={{ base: 'flex-start', lg: 'center' }}
      gap={{ base: '3rem', md: '2.5rem' }}
      justifyContent={{
        base: 'flex-start',
        md: 'space-between',
        lg: 'space-between',
      }}
      w="100%"
      rounded="1.25rem"
      bg="#283C6D"
      p={10}
    >
      <TotalStats stats={total.tickets}>
        Clientes foram notificados no total
      </TotalStats>

      <TotalStats stats={total.protocols}>
        Protocolos foram abertos no total
      </TotalStats>

      <TotalStats stats={`${total.percents} %`}>
        dos clientes notificados solicitaram abertura de protocolo
      </TotalStats>
    </Flex>
  );
}
