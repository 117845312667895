import { Flex } from '@chakra-ui/react';
import { CategorySelect } from '../Inputs/CategorySelect';
import { CompanySelect } from '../Inputs/CompanySelect';
import { NameEventSearch } from '../Inputs/NameEventSearch';
import { SlaSelect } from '../Inputs/SlaSelect';

interface FilterDasktopProps {
  refetch: () => void;
}

export function FilterDasktop({ refetch }: FilterDasktopProps) {
  return (
    <Flex
      w="100%"
      rounded="0.5rem"
      background="white"
      alignItems="center"
      mb="0.75rem"
      p="1.25rem"
      gap="1rem"
    >
      <CompanySelect />

      <SlaSelect />

      <CategorySelect />

      <NameEventSearch refetch={refetch} />
    </Flex>
  );
}
