import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { MutableRefObject } from 'react';
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from 'react-icons/md';

interface CustomArrowProps extends ButtonProps {
  direction: 'left' | 'right';
  openRef?: MutableRefObject<unknown>;
  size: 'lg' | 'xl';
}

export function CustomArrow({
  direction,
  openRef,
  size,
  ...rest
}: CustomArrowProps) {
  return (
    <Button
      ref={openRef}
      minWidth={{
        base: size === 'xl' ? '3.125rem' : '1.625rem',
        xl: size === 'xl' ? '3.5rem' : '2rem',
      }}
      rounded="full"
      padding="0"
      background="blue.100"
      color="blue.500"
      height={{
        base: size === 'xl' ? '3.125rem' : '1.625rem',
        xl: size === 'xl' ? '3.5rem' : '2rem',
      }}
      {...rest}
      zIndex="2"
    >
      {direction === 'left' ? (
        <Icon as={MdOutlineArrowBackIos} aria-label="left" />
      ) : (
        <Icon as={MdOutlineArrowForwardIos} aria-label="right" />
      )}
    </Button>
  );
}
