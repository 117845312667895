import { Box, Flex } from '@chakra-ui/react';
import { LogoutBox } from './LogoutBox';
import { Logo } from './Logo';
import { Navigation } from './Navigation';
import { useLocation } from 'react-router-dom';

interface SidebarProps {
  collapse: boolean;
}

export function Sidebar({ collapse }: SidebarProps) {
  const { pathname } = useLocation();

  return (
    <Flex flexDir="column" h="100%" justify="space-between">
      <Box w="full">
        <Logo collapse={collapse} />

        {pathname !== '/welcome/notpermission' && (
          <Navigation collapse={collapse} />
        )}
      </Box>
      <LogoutBox collapse={collapse} />
    </Flex>
  );
}
