import { apiReactQuery } from '@/lib/axios/apiReactQuery';
import { useQuery } from '@tanstack/react-query';
import { parseCookies } from 'nookies';
import { toast } from 'react-toastify';
import { Parameter } from './types';

interface ParametersResponse {
  parameters: Parameter;
}

export function useGetParameters() {
  const query = useQuery({
    queryKey: ['parameters'],
    queryFn: async (): Promise<ParametersResponse> => {
      try {
        const { '@event-monitor-web:token-1.0.1': token } = parseCookies();
        const response = await apiReactQuery.get<Parameter>(
          'event/parameters',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        return {
          parameters: response.data,
        };
      } catch (error) {
        const { message } = error as Error;

        toast.error(message);
        return {
          parameters: null,
        };
      }
    },
  });

  return query;
}
