import { differenceInHours, parseISO } from 'date-fns';

interface Story {
  created_at: string;
  status: {
    name: string;
  };
}

interface Stories {
  stories: Story[];
}

export const checkEventResolved24HoursAgo = ({ stories }: Stories) => {
  const now = new Date();

  const isResolvedEvent = stories.some((event) => {
    if (event.status.name === 'Resolvido') {
      const createdAt = parseISO(event.created_at);
      const hoursDifference = differenceInHours(now, createdAt);

      return hoursDifference > 24;
    }

    return false;
  });

  return isResolvedEvent;
};
