import { useCreateEventStore } from '@store/create-event';

export const useCanSubmitNewEvent = () => {
  const {
    cities,
    states,
    neighborhoods,
    selectedStatus,
    selectedCompany,
    selectedCategory,
    selectedRepairTeam,
    deviceCnt,
  } = useCreateEventStore((state) => state);

  const isUnavailabilityCategory =
    selectedCategory.name === 'Indisponibilidade';

  const isCompanySelected = !!selectedCompany.id;
  const isStatusSelected = !!selectedStatus.id;
  const isCategorySelected = !!selectedCategory.id;
  const isRepairTeamSelected = !!selectedRepairTeam.id;
  const hasLocationEquipment =
    cities.length >= 1 ||
    states.length >= 1 ||
    neighborhoods.length >= 1 ||
    deviceCnt.length >= 1;

  const isDisabledToSubmit = isUnavailabilityCategory
    ? !(
        isCompanySelected &&
        isStatusSelected &&
        isCategorySelected &&
        isRepairTeamSelected
      ) || !hasLocationEquipment
    : !(isCompanySelected && isStatusSelected && isCategorySelected) ||
      !hasLocationEquipment;

  return { isDisabledToSubmit };
};
