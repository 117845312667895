import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { IoApps } from 'react-icons/io5';
import { Sidebar } from './Sidebar';

export function Mobile() {
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <Button
      border="0.0625rem solid #D5D5D5"
      onClick={onOpen}
      rounded="full"
      bg="white"
      boxSize="10"
      position="fixed"
      top="1.2rem"
      zIndex="1000"
      right={{ base: '4', md: '8', lg: '10' }}
      display={{ base: 'inline-flex', lg: 'none' }}
    >
      <Icon as={IoApps} boxSize="5" color="gray.900" />

      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent bg="white">
          <DrawerHeader>
            <Button onClick={onClose} width="2">
              <Icon as={AiOutlineClose} w={5} h={5} color="gray.900" />
            </Button>
          </DrawerHeader>

          <DrawerBody bg="white" color="#A8DAFF" h="100%">
            <Sidebar collapse={true} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Button>
  );
}
