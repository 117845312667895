import { toast } from 'react-toastify';
import { StateCreator } from 'zustand';

import { formatFirstString } from '@/utils/formatFirstString';
import {
  NeighborhoodState,
  NeighborhoodActions,
} from '@store/create-event/@types/neighborhood';
import { initialNeighborhoodState } from '@store/create-event/initial-state/neighborhood';

export const neighborhoodSlice: StateCreator<
  NeighborhoodState & NeighborhoodActions
> = (set, get) => ({
  ...initialNeighborhoodState,
  onAddNeighborhood: ({ city, neighborhood }) => {
    const newNeighborhood = formatFirstString(neighborhood);

    const isExistsNeighborhoods = get().neighborhoods.find(
      (neighborhood) => neighborhood.name === newNeighborhood,
    );

    if (isExistsNeighborhoods) {
      return toast.error(`${newNeighborhood} já existe`);
    }

    set((state) => ({
      neighborhoods: [
        ...state.neighborhoods,
        {
          name: newNeighborhood,
          city_id: city.id,
          city_name: city.name,
        },
      ],
    }));
  },
  onRemoveNeighborhood: (name) => {
    const currentNeighborhood = get().neighborhoods;

    const remainingNeighborhood = currentNeighborhood.filter(
      (neighborhood) => neighborhood.name !== name,
    );

    set({ neighborhoods: remainingNeighborhood });
  },
  onResetNeighborhood: () => {
    set(initialNeighborhoodState);
  },
});
