export const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 0,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: {
      max: 0,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 0,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: {
      max: 0,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 0,
    slidesToSlide: 1,
  },
};
