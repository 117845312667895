import { zodResolver } from '@hookform/resolvers/zod';
import { ReactNode, createContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { newEventValidationSchema } from '@/utils/zod/newEventValidationSchema';
import { FormCreateEventContextData, SubmitNewEvent } from './types';
import { useCreateEventStore } from '@store/create-event';

export const FormCreateEventContext = createContext(
  {} as FormCreateEventContextData,
);

export function FormCreateEventProvider({ children }: { children: ReactNode }) {
  const newEventForm = useForm<SubmitNewEvent>({
    resolver: zodResolver(newEventValidationSchema),
  });

  const selectedCategory = useCreateEventStore(
    (state) => state.selectedCategory,
  );

  const { handleSubmit, control, reset } = newEventForm;

  const watch = useWatch({
    control,
  });

  const isDisabledToGoHistoric =
    selectedCategory.name === 'Indisponibilidade'
      ? !watch.name ||
        !watch.description ||
        !watch.categoryId ||
        !watch.companyId ||
        !watch.repairTeamId
      : !watch.name ||
        !watch.description ||
        !watch.categoryId ||
        !watch.companyId;

  const isDisabledToGoLocationEquipment =
    !watch.historyEvent || !watch.historyDescription;

  return (
    <FormCreateEventContext.Provider
      value={{
        newEventForm,
        handleSubmit,
        isDisabledToGoHistoric,
        isDisabledToGoLocationEquipment,
        reset,
      }}
    >
      {children}
    </FormCreateEventContext.Provider>
  );
}
