import { createIcon } from '@chakra-ui/icons';

export const Logout = createIcon({
  displayName: 'Logout',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M2.66667 24C1.93333 24 1.30533 23.7391 0.782667 23.2173C0.260889 22.6947 0 22.0667 0 21.3333V2.66667C0 1.93333 0.260889 1.30533 0.782667 0.782667C1.30533 0.260889 1.93333 0 2.66667 0H12V2.66667H2.66667V21.3333H12V24H2.66667ZM17.3333 18.6667L15.5 16.7333L18.9 13.3333H8V10.6667H18.9L15.5 7.26667L17.3333 5.33333L24 12L17.3333 18.6667Z"
      fill="#3F7EF8"
    />
  ),
});
