import { Button, Flex, TabPanel } from '@chakra-ui/react';

import { HeadingOne } from '@/components/Heading';
import { useAuth } from '@/hooks/useAuth';
import { SnapEventInfo } from '../SnapEventInfo';
import { NewImagesEvent } from './NewImagesEvent';
import { useUpdateEventStore } from '@/store/update-event';
import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';

export function ImageEvent() {
  const { user } = useAuth();
  const { queryData } = useGetEventDetailToUpdate();

  const { files, onSubmit } = useUpdateEventStore((state) => state);

  const hideButtonWhenThereIsNoNewImage = files.length > 0;

  return (
    <TabPanel p="0" pb="2rem">
      <Flex
        justifyContent="space-between"
        align="center"
        mb={{ base: '0', lg: '1rem' }}
      >
        <HeadingOne
          text="Atualizando Evento"
          display={{ base: 'none', lg: 'flex' }}
        />
        {hideButtonWhenThereIsNoNewImage && (
          <Button
            w="6.8125rem"
            height="2.125rem"
            bg="blue.500"
            color="white"
            fontWeight="400"
            onClick={() =>
              onSubmit({ eventId: queryData.event.id, userId: user.id })
            }
          >
            Salvar
          </Button>
        )}
      </Flex>

      <SnapEventInfo />

      <NewImagesEvent />
    </TabPanel>
  );
}
