import {
  Box,
  Button,
  Flex,
  FormControl,
  Tag,
  Text,
  TagLabel,
  TagCloseButton,
  Select,
} from '@chakra-ui/react';
import { ChangeEvent, useState } from 'react';

import { TemplateModal } from '../TemplateModal';

import { SelectedCity, StoreCity } from '../../types';
import { useCreateEventStore } from '@store/create-event';

interface ModalProps {
  disclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
}

export function Modal({ disclosure }: ModalProps) {
  const [storeCities, setStoreCities] = useState<StoreCity[]>([]);
  const [selectedCity, setSelectedCity] = useState({} as SelectedCity);

  const {
    selectedCompany,
    cities,
    nations,
    onAddCity,
    onRemoveCity,
    onResetCity,
  } = useCreateEventStore((state) => state);

  const isExistsSelectedCompany = !!selectedCompany.id;

  function handleSelectState(event: ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === '') {
      setStoreCities([]);
      return;
    }

    const currentCities = JSON.parse(event.target.value);

    setStoreCities(currentCities.city);
  }

  function handleGetCity(event: ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === '') {
      setSelectedCity({} as null);
      return;
    }

    setSelectedCity(JSON.parse(event.target.value));
  }

  const isExistsStoreCity = storeCities.length > 0;

  const hasCityAndState = isExistsStoreCity && !!selectedCity.id;

  return (
    <TemplateModal name="Cidade" reset={onResetCity} disclosure={disclosure}>
      <FormControl display="flex" alignItems="center">
        {isExistsSelectedCompany && (
          <Flex w="100%" gap="1.125rem">
            <Select
              placeholder="Informe o Estado"
              onChange={handleSelectState}
              w="12rem"
            >
              {nations.map((nation) => {
                return (
                  <option key={nation.id} value={JSON.stringify(nation)}>
                    {nation.name}
                  </option>
                );
              })}
            </Select>

            <Select
              placeholder="Cidade"
              onChange={handleGetCity}
              w="13rem"
              disabled={!isExistsStoreCity}
            >
              {storeCities?.map((city) => {
                return (
                  <option key={city.id} value={JSON.stringify(city)}>
                    {city.name}
                  </option>
                );
              })}
            </Select>

            <Button
              onClick={() => onAddCity(selectedCity)}
              bg="none"
              color="blue.500"
              fontWeight="400"
              w="4rem"
              _hover={{}}
              isDisabled={!hasCityAndState}
            >
              Adicionar
            </Button>
          </Flex>
        )}
      </FormControl>

      {cities.length > 0 && (
        <Box mt="2.5rem">
          <Text fontSize="1rem">Cidades adicionadas</Text>

          <Flex
            mt="0.75rem"
            rowGap="1rem"
            columnGap="0.25rem"
            flexWrap="wrap"
            overflowY="scroll"
            maxH="15.625rem"
            css={{
              '&::-webkit-scrollbar': {
                width: '0.3125rem',
              },
              '&::-webkit-scrollbar-track': {
                width: '0.375rem',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'var(--chakra-colors-gray-300);',
                borderRadius: '0.625rem',
              },
            }}
          >
            {cities.map((city) => {
              return (
                <Tag
                  key={city.name}
                  bg="blue.500"
                  color="white"
                  rounded="full"
                  h="1.5625rem"
                >
                  <TagLabel>{city.name}</TagLabel>
                  <TagCloseButton
                    bg="#FFFFFF"
                    opacity="1"
                    w="0.9375rem"
                    h="0.9375rem"
                    color="blue.500"
                    fontSize="0.625rem"
                    ml="0.5rem"
                    onClick={() => onRemoveCity(city.name)}
                  />
                </Tag>
              );
            })}
          </Flex>
        </Box>
      )}
    </TemplateModal>
  );
}
