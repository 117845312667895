import { create } from 'zustand';
import {
  PreviousEventState,
  PreviousEventActions,
} from '@/store/previous-event/types';
import { initialPreviousEventState } from '@/store/previous-event/initial-state';

export const usePreviousEventStore = create<
  PreviousEventState & PreviousEventActions
>((set) => ({
  ...initialPreviousEventState,
  changeSelectCompany: (company) => {
    set(() => ({
      selectCompany: company,
    }));
  },
  changeSelectSla: (sla) => {
    set(() => ({
      selectedSla: sla,
    }));
  },
  changeSelectedCategory: (category) => {
    set(() => ({
      selectedCategory: category,
    }));
  },
  changeNameEvent: (name) => {
    set(() => ({
      nameEvent: name,
    }));
  },
  currentFilterByEvent: (company, sla, category) => {
    set(() => ({
      selectCompany: company,
      selectedSla: sla,
      selectedCategory: category,
    }));
  },
  changeCurrentPage: (page) => {
    set(() => ({
      currentPage: page,
    }));
  },
}));
