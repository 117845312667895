import { Button } from '@chakra-ui/react';

interface SelectableButtonProps {
  name: string;
  comparisonData: string;
  selectedData: string;
  onSelect: () => void;
}

export function SelectableButton({
  name,
  comparisonData,
  selectedData,
  onSelect,
}: SelectableButtonProps) {
  return (
    <Button
      bg={comparisonData === selectedData ? '#CDDEFF' : '#E5E7EA'}
      color={comparisonData === selectedData ? 'blue.500' : 'gray.700'}
      h="1.687rem"
      w={{ base: '100%', sm: '7.25rem' }}
      fontWeight="500"
      fontSize="0.875rem"
      rounded="full"
      _hover={{ filter: 'brightness(0.9)' }}
      transition="all 0.4s ease"
      isDisabled={comparisonData === selectedData}
      onClick={onSelect}
      _disabled={{}}
    >
      {name}
    </Button>
  );
}
