import {
  Stepper as ChakraStepper,
  Step,
  StepIndicator,
  StepSeparator,
  StepStatus,
  Text,
  Circle,
  Flex,
  Tag,
  Box,
} from '@chakra-ui/react';

interface Story {
  id: string;
  created_at: string;
  created_in: string;
  description: string;
  elapsedTime: string;
  status: {
    name: string;
  };
}

interface StepperProps {
  stories: Story[];
}

export function Stepper({ stories }: StepperProps) {
  return (
    <ChakraStepper
      index={stories.length + 1}
      orientation="vertical"
      gap="0"
      paddingLeft={{ md: '2rem', lg: '1.25rem', xl: '6rem' }}
      paddingRight={{ md: '2rem', lg: '1.25rem', xl: '3.125rem' }}
      paddingTop={{ md: '3rem', lg: '4rem' }}
      colorScheme="whiteAlpha"
      size="xs"
    >
      {stories.map((story, index) => {
        const color =
          story.status.name === 'Investigando' ||
          story.status.name === 'Identificado' ||
          story.status.name === 'Atualizando'
            ? '#FFD0B5'
            : story.status.name === 'Monitorando'
              ? '#99CDFD'
              : '#AAF4C9';

        const text =
          story.status.name === 'Investigando' ||
          story.status.name === 'Identificado' ||
          story.status.name === 'Atualizando'
            ? '#F07F3F'
            : story.status.name === 'Monitorando'
              ? '#3F7EF8'
              : '#44835E';

        return (
          <Flex gap="1.5rem" key={index} w="100%">
            <Flex display={{ base: 'none', md: 'flex' }} alignItems="start">
              <Flex
                direction={{ base: 'row', md: 'column' }}
                minW="6.25rem"
                align={{ base: 'start', md: 'end' }}
                gap={{ base: '0.25rem', lg: 0 }}
                mt={{ base: '0.75rem', md: 0 }}
              >
                <Text
                  textTransform="capitalize"
                  color="gray.900"
                  fontWeight="500"
                  fontSize={{
                    base: '0.875rem',
                    '2xl': '1rem',
                  }}
                >
                  {story.created_in}
                </Text>
                <Text
                  color="gray.700"
                  fontWeight="400"
                  fontSize={{
                    base: '0.875rem',
                    '2xl': '1rem',
                  }}
                >
                  {story.elapsedTime}
                </Text>
              </Flex>
            </Flex>
            <Step>
              <StepIndicator>
                <StepStatus
                  complete={<Circle background={color} size="100%" />}
                  incomplete={<Circle background={color} size="100%" />}
                  active={<Circle background={color} size="100%" />}
                />
              </StepIndicator>

              <Flex
                flexDir="column"
                ml={{
                  sm: '.1875rem',
                  md: '.6875rem',
                  '2xl': '1rem',
                }}
                _notLast={{ marginBottom: '4.0625rem' }}
              >
                <Flex gap="1rem" align="center">
                  <Tag
                    bg={color}
                    borderRadius="0.875rem"
                    padding="0.25rem 1.25rem"
                    w="max-content"
                  >
                    <Text
                      fontWeight={500}
                      fontSize={{ base: '.875rem', md: '1rem' }}
                      color={text}
                    >
                      {story.status.name}
                    </Text>
                  </Tag>
                </Flex>

                <Flex display={{ base: 'flex', md: 'none' }}>
                  <Flex
                    direction={{ base: 'row', md: 'column' }}
                    minW="6.25rem"
                    align={{ base: 'start', md: 'end' }}
                    gap={{ base: '0.25rem', lg: 0 }}
                    mt={{ base: '0.75rem', md: 0 }}
                  >
                    <Text
                      textTransform="capitalize"
                      color="gray.900"
                      fontWeight="500"
                      fontSize={{
                        base: '0.875rem',
                        '2xl': '1rem',
                      }}
                    >
                      {story.created_in}
                    </Text>
                    <Text
                      color="gray.700"
                      fontWeight="400"
                      fontSize={{
                        base: '0.875rem',
                        '2xl': '1rem',
                      }}
                    >
                      {story.elapsedTime}
                    </Text>
                  </Flex>
                </Flex>
                <Box mt="1rem" mb="2rem" maxW="41.875rem">
                  <Text
                    lineHeight="1.25rem"
                    color="gray.400"
                    w="100%"
                    fontSize={{ base: '.875rem', md: '1rem' }}
                  >
                    {story.description}
                  </Text>
                </Box>
              </Flex>

              <StepSeparator style={{ background: '#E5E7EA' }} />
            </Step>
          </Flex>
        );
      })}
    </ChakraStepper>
  );
}
