import { InputGroup, InputLeftElement, Select } from '@chakra-ui/react';
import { BiBuilding } from 'react-icons/bi';

import { usePortalEventStore } from '@store/portal-event';

export function SelectCompany() {
  const { changeCompany, selectedCompany } = usePortalEventStore((state) => {
    const { selectedCompany, changeCompany } = state;

    return { selectedCompany, changeCompany };
  });

  return (
    <InputGroup w="8.75rem" border="transparent">
      <InputLeftElement pointerEvents="none">
        <BiBuilding color="gray.300" />
      </InputLeftElement>
      <Select
        value={selectedCompany}
        onChange={(e) => changeCompany(e.target.value)}
        placeholder="Todas"
        style={{ paddingLeft: '2rem', marginTop: '0.0625rem' }}
        iconColor="blue.500"
      >
        <option value="Wire">Wire</option>
        <option value="Fiber Network">Fiber Network</option>
        <option value="Intlink">Intlink</option>
        <option value="Icom">Icom</option>
      </Select>
    </InputGroup>
  );
}
