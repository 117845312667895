import { usePreviousEventStore } from '@store/previous-event';
import { InputGroup, InputLeftElement, Select } from '@chakra-ui/react';
import { BiBuilding } from 'react-icons/bi';
import { RiArrowDownSLine } from 'react-icons/ri';

export function CompanySelect() {
  const { selectCompany, changeSelectCompany } = usePreviousEventStore(
    (state) => state,
  );

  return (
    <InputGroup w="7rem" h="100%" border="transparent" color="gray.400">
      <InputLeftElement h="100%" pointerEvents="none">
        <BiBuilding color="gray.400" />
      </InputLeftElement>

      <Select
        value={selectCompany}
        _hover={{
          cursor: 'pointer',
        }}
        w={selectCompany === '' ? '7rem' : '100%'}
        icon={<RiArrowDownSLine color="blue.500" />}
        onChange={(e) => changeSelectCompany(e.target.value)}
        placeholder="Todas"
        style={{ paddingLeft: '2rem', marginTop: '0.0625rem' }}
        iconColor="blue.500"
        h="2.3rem"
      >
        <option value="Wire">Wire</option>
        <option value="Fiber Network">Fiber Network</option>
        <option value="Intlink">Intlink</option>
        <option value="Icom">Icom</option>
      </Select>
    </InputGroup>
  );
}
