import { Outlet } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';

import { AsideNavigation } from '@/components/AsideNavigation';

export function DashboardLayout() {
  return (
    <Flex h="100%">
      <AsideNavigation device="desktop" />
      <Flex w="100%" flexDir="column">
        <Outlet />
      </Flex>
    </Flex>
  );
}
