import {
  Box,
  Flex,
  RangeSlider,
  RangeSliderThumb,
  RangeSliderTrack,
  Skeleton as ChakraSkeleton,
  useBreakpointValue,
} from '@chakra-ui/react';

export function Skeleton() {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  return (
    <Flex
      flexDirection="column"
      gap={{ base: '1.2rem', lg: '0.5rem' }}
      my={{ base: '2rem', lg: '0' }}
      mb={{ base: '0', lg: '2rem' }}
      data-testid="skeleton-latest-opened-event"
    >
      <Box bg="white" py="4" px="6" rounded="lg">
        <Flex justify="space-between" w="100%">
          <Box>
            <ChakraSkeleton w="8rem" h="1rem" />

            <ChakraSkeleton w="5rem" h="1rem" mt="0.5rem" />
          </Box>

          <ChakraSkeleton w="4rem" h="1rem" />
        </Flex>

        {isMobile ? (
          <Flex mt="3rem" flexDirection="column" align="start" w="0">
            <RangeSlider
              defaultValue={[0]}
              min={0}
              max={0}
              m="0 auto"
              orientation="vertical"
              isDisabled
              minH="10rem"
            >
              <RangeSliderTrack bg="orange.100" />
              <RangeSliderThumb boxSize="1.5rem" index={0}>
                <ChakraSkeleton
                  h="1rem"
                  pos="absolute"
                  w="4rem"
                  left="1.8rem"
                />
              </RangeSliderThumb>
            </RangeSlider>

            <RangeSlider
              defaultValue={[0]}
              min={0}
              max={0}
              orientation="vertical"
              minH="10rem"
              m="0 auto"
              isDisabled
            >
              <RangeSliderTrack bg="orange.100" />
              <RangeSliderThumb boxSize="1.5rem" index={0}>
                <ChakraSkeleton
                  h="1rem"
                  pos="absolute"
                  w="4rem"
                  left="1.8rem"
                />
              </RangeSliderThumb>
            </RangeSlider>

            <RangeSlider
              defaultValue={[0]}
              min={0}
              max={0}
              m="0 auto"
              orientation="vertical"
              minH="5rem"
              isDisabled
            >
              <RangeSliderThumb boxSize="1.5rem" index={0} bg="orange.400">
                <ChakraSkeleton
                  h="1rem"
                  pos="absolute"
                  w="4rem"
                  left="1.8rem"
                />
              </RangeSliderThumb>
            </RangeSlider>
          </Flex>
        ) : (
          <Flex w="100%" my="7rem" pl="2.5rem" pr="3rem">
            <RangeSlider
              defaultValue={[0]}
              min={0}
              max={0}
              width="100%"
              m="0 auto"
              isDisabled
            >
              <RangeSliderTrack bg="orange.100" />
              <RangeSliderThumb boxSize="1.5rem" index={0}>
                <ChakraSkeleton h="1rem" pos="absolute" mt="4rem" w="4rem" />
              </RangeSliderThumb>
            </RangeSlider>

            <RangeSlider
              defaultValue={[0]}
              min={0}
              max={0}
              width="100%"
              m="0 auto"
              isDisabled
            >
              <RangeSliderTrack bg="orange.100" />
              <RangeSliderThumb boxSize="1.5rem" index={0}>
                <ChakraSkeleton h="1rem" pos="absolute" mt="4rem" w="4rem" />
              </RangeSliderThumb>
            </RangeSlider>

            <RangeSlider
              defaultValue={[0]}
              min={0}
              max={0}
              width="auto"
              m="0 auto"
              isDisabled
            >
              <RangeSliderThumb boxSize="1.5rem" index={0} bg="orange.400">
                <ChakraSkeleton h="1rem" pos="absolute" mt="4rem" w="4rem" />
              </RangeSliderThumb>
            </RangeSlider>

            <RangeSlider
              defaultValue={[0]}
              min={0}
              max={0}
              width="auto"
              m="0 auto"
              isDisabled
            >
              <RangeSliderThumb boxSize="1.5rem" index={0} bg="orange.400">
                <ChakraSkeleton h="1rem" pos="absolute" mt="4rem" w="4rem" />
              </RangeSliderThumb>
            </RangeSlider>
          </Flex>
        )}
      </Box>
    </Flex>
  );
}
