import {
  Event,
  ExtendedCity,
  ExtendedStateEvent,
  ExtendedNeighborhood,
  ExtendedDeviceCnt,
} from './types';

export function addIsSelected(event: Event) {
  const cities: ExtendedCity[] = event.cities.map((city) => ({
    ...city,
    isSelected: false,
  }));

  const states: ExtendedStateEvent[] = event.states.map((state) => ({
    ...state,
    isSelected: false,
  }));

  const neighborhoods: ExtendedNeighborhood[] = event.neighborhoods.map(
    (neighborhood) => ({
      ...neighborhood,
      isSelected: false,
    }),
  );

  const device_cnt: ExtendedDeviceCnt[] = event.device_cnt.map((device) => ({
    ...device,
    isSelected: false,
  }));

  return {
    cities,
    states,
    neighborhoods,
    device_cnt,
  };
}
