import { Box, Flex, Heading, Text } from '@chakra-ui/react';

import { Pagination } from './Pagination';
import { ErrorPage } from './ErrorPage';
import { Logo } from './Logo';
import { Filter } from './Filter';

import { Skeleton } from './Skeleton';
import { Events } from './Events';
import { useGetPortalEvents } from '@/hooks/queries/get-portal-events';

export function Portal() {
  const { isLoading, isError } = useGetPortalEvents();

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Flex my="3.562rem">
      <Flex px={{ base: '1rem', lg: '4.875rem' }} w="100%" direction="column">
        <Logo />

        <Box mt="1.937rem">
          <Flex align="center" justify="space-between">
            <Heading
              color="gray.700"
              fontSize={{ base: '1rem', md: '1.375rem' }}
              fontWeight="600"
            >
              Todos os eventos
            </Heading>

            <Text color="blue.500" fontSize={{ base: '0.875rem', md: '1rem' }}>
              Últimos 90 dias
            </Text>
          </Flex>

          <Filter />
        </Box>

        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <Events />

            <Pagination />
          </>
        )}
      </Flex>
    </Flex>
  );
}
