import {
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Icon,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { Link as ReachLink } from 'react-router-dom';

import { Status } from './Status';
import { useGetPortalEvents } from '@/hooks/queries/get-portal-events';

const ThPortal = ({ children }: { children: ReactNode }) => {
  return (
    <Th
      color="blue.500"
      textTransform="capitalize"
      fontSize="1rem"
      fontWeight="600"
      py="1rem"
    >
      {children}
    </Th>
  );
};

export function TableEvent() {
  const { queryData } = useGetPortalEvents();

  return (
    <TableContainer>
      <Table bg="white" mt="0.75rem" rounded="0.5rem" variant="unstyled">
        <Thead>
          <Tr>
            <ThPortal>Nome do Evento</ThPortal>
            <ThPortal>Empresa</ThPortal>
            <ThPortal>Criado em</ThPortal>
            <ThPortal>Status</ThPortal>
            <Th />
          </Tr>
        </Thead>

        <Tbody>
          {queryData.events.map((event) => {
            return (
              <Tr
                key={event.id}
                _notFirst={{
                  borderTop: '0.0625rem solid #DFE7F2',
                }}
              >
                <Td w="50%" py="1.687rem" color="gray.700" fontWeight="500">
                  {event.name}
                </Td>
                <Td py="1.687rem">{event.companies.name}</Td>
                <Td py="1.687rem">{event.createdIn}</Td>
                <Td py="1.687rem">
                  <Status status={event.status} />
                </Td>
                <Td py="1.687rem">
                  <Link
                    as={ReachLink}
                    to={`/portal/details/${event.id}`}
                    state={{ idEvent: event.id }}
                    alignItems="center"
                    display="flex"
                    w="1.3rem"
                  >
                    <Icon as={AiOutlineEye} fontSize="1.375rem" />
                  </Link>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
