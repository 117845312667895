import { KeyboardEvent } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai';
import { IoOptionsOutline } from 'react-icons/io5';

import { useTicketEventStore } from '@store/ticket-event';

export function MobileFilter() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    selectCompany,
    inputSearch,
    intervalDates,
    saveIntervalDates,
    changeCompany,
    changeInputSearch,
    saveFilterParameters,
  } = useTicketEventStore((state) => state);

  function onHandleFetchingTickets() {
    saveFilterParameters();
    onClose();
  }

  function onSubmitByKeyPressed(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      saveFilterParameters();
    }
  }

  return (
    <Flex justify="space-between" gap="0.56rem" mt="0.625rem">
      <InputGroup w="18.75rem" border="transparent" bg="white" rounded="0.5rem">
        <InputLeftElement pointerEvents="none" w="1.875rem">
          <AiOutlineSearch color="gray.300" />
        </InputLeftElement>
        <Input
          enterKeyHint="search"
          pl="1.875rem"
          pr="0"
          type="text"
          name="search"
          placeholder="Pesquisar por nome do cliente ou código do cliente"
          _placeholder={{ color: 'gray.400' }}
          value={inputSearch}
          onChange={(e) => changeInputSearch(e.target.value)}
          onKeyDown={(e) => onSubmitByKeyPressed(e)}
        />
      </InputGroup>

      <Button bg="white" onClick={onOpen}>
        <Icon as={IoOptionsOutline} color="blue.500" fontSize="1.5rem" />
      </Button>

      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} size="xl">
        <DrawerOverlay />
        <DrawerContent roundedTop="2rem">
          <DrawerHeader color="gray.700">Empresa</DrawerHeader>
          <DrawerBody>
            <RadioGroup value={selectCompany} onChange={changeCompany}>
              <Stack spacing={3}>
                <Radio value="" defaultChecked>
                  Todas
                </Radio>

                <Radio value="Intlink">Intlink</Radio>
                <Radio value="Icom">Icom Telecom</Radio>
                <Radio value="Fiber Network">Fiber Network</Radio>
                <Radio value="Wire">Wire</Radio>
              </Stack>
            </RadioGroup>
          </DrawerBody>

          <DrawerHeader color="gray.700">Período</DrawerHeader>

          <DrawerBody>
            <Stack direction="column" spacing={2}>
              <Flex align="center" gap="0.625rem">
                <Text>de</Text>

                <Input
                  type="datetime-local"
                  name="date"
                  value={intervalDates.initialDate}
                  onChange={(e) => saveIntervalDates(e.target.value, 'initial')}
                />
              </Flex>

              <Flex align="center" gap="0.625rem">
                <Text>até</Text>
                <Input
                  type="datetime-local"
                  value={intervalDates.endDate}
                  onChange={(e) => saveIntervalDates(e.target.value, 'end')}
                />
              </Flex>
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            <Button
              type="submit"
              onClick={() => onHandleFetchingTickets()}
              bg="blue.500"
              color="white"
            >
              Aplicar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
