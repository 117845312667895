import { Pagination as PaginationComponent } from '@/components/Pagination';
import { usePagination } from '@/hooks/usePagination';
import { Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';

interface PaginationProps {
  pagination: {
    from: number;
    to: number;
    total: number;
  };
  currentPage: number;
  changePage: React.Dispatch<React.SetStateAction<number>>;
}

export function Pagination({
  pagination,
  currentPage,
  changePage,
}: PaginationProps) {
  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      ssr: false,
    },
  );

  const { limit } = usePagination();

  const isShowPagination = pagination.total > 10;

  if (isShowPagination) {
    return (
      <Flex
        mt="0.75rem"
        px="1.375rem"
        bg="white"
        rounded="0.5rem"
        py="1.25rem"
        justify="space-between"
        align="center"
      >
        {!isMobile && (
          <Text>
            Visualizando {pagination.from}-{pagination.to} de {pagination.total}{' '}
            eventos.
          </Text>
        )}

        <PaginationComponent
          totalCountOfRegisters={pagination.total}
          currentPage={currentPage}
          setCurrentPage={changePage}
          registerPerPage={limit}
        />
      </Flex>
    );
  }
}
