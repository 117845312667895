import { Box, Flex, Skeleton } from '@chakra-ui/react';

export function SkeletonBoxPortal() {
  return (
    <Box
      bg="white"
      rounded="0.5rem"
      w="100%"
      overflow="hidden"
      boxShadow="0rem 0.125rem 0.625rem rgba(0, 0, 0, 0.08)"
    >
      <Flex
        flexDirection="column"
        p="1.06rem 1.18rem 1.375rem"
        color="gray.400"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Skeleton height="1.25rem" w="9.375rem" />

          <Skeleton height="1.25rem" w="2.5rem" />
        </Flex>

        <Skeleton mt="1.125rem" height="1.25rem" w="12.5rem" />

        <Skeleton mt="0.5rem" height="1.25rem" w="6.25rem" />
      </Flex>

      <Box bg="#F3F7F9" p="1rem 1.18rem">
        <Skeleton height="1.25rem" w="12.5rem" />
      </Box>
    </Box>
  );
}
