import { createIcon } from '@chakra-ui/icons';

export const Plus = createIcon({
  displayName: 'Plus',
  viewBox: '0 0 20 19',
  path: (
    <>
      <path
        d="M8.66663 18.7151V10.7151H0.666626V8.0484H8.66663V0.0484009H11.3333V8.0484H19.3333V10.7151H11.3333V18.7151H8.66663Z"
        fill="#3F7EF8"
      />
    </>
  ),
});
